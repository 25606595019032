import { cn } from 'helpers/cn';
import { Button } from '../button';

export const NextButton = ({ children, typographyText, className = '', ...props }: any) => {
    typographyText = typographyText ?? 'Next';

    return (
        <Button
            className={cn(
                `bg-btnSecondaryPrimary text-white my-5 md:my-6 hover:!opacity-100 hover:text-white hover:bg-btnSecondaryHover dark:hover:bg-btnSecondaryHover disabled:bg-[#C5C5D1] disabled:hover:bg-[#C5C5D1] disabled:pointer-events-none`,
                className
            )}
            typographyText={typographyText}
            {...props}
        >
            {children}
        </Button>
    );
};
