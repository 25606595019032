// Graphics
import weightLossGraphic from 'assets/images/svg/weight-loss/weight-loss-graphic.svg';
import weightFactorsIllustration from 'assets/images/svg/weight-factors/weight-factors-illustration.svg';
import weightLossWithAbleChart from 'assets/images/svg/analyze-responses/weight-loss-with-able-chart.svg';

// Body gender images
import maleGender from 'assets/images/svg/body-gender/male-gender.svg';
import femaleGender from 'assets/images/svg/body-gender/female-gender.svg';

// Icons
import partyIcon from 'assets/images/svg/party-icon.svg';
import checkIcon from 'assets/images/svg/check-icon.svg';

// Reinforcement icons
import familyTree from 'assets/images/svg/reinforcement-icons/family-tree.svg';
import medications from 'assets/images/svg/reinforcement-icons/medications.svg';

// Decline user
import noMedication from 'assets/images/svg/decline-user/no-medication.svg';

// Checkout
import shippingIcon from 'assets/images/svg/checkout/order-details/shipping-icon.svg';
import cancelIcon from 'assets/images/svg/checkout/order-details/cancel-icon.svg';
import medicalAdvisorIcon from 'assets/images/svg/checkout/order-details/medical-advisor-icon.svg';
import cartIcon from 'assets/images/svg/checkout/cart/cart-icon.svg';
import cartMedications from 'assets/images/webp/checkout/cart/medications.webp';
import navigationIcon from 'assets/images/svg/checkout/breadcrumbs-navigation/navigation-icon.svg';
import navigationIconDown from 'assets/images/svg/checkout/breadcrumbs-navigation/navigation-icon-down.svg';
import navBarCheckIcon from 'assets/images/svg/checkout/breadcrumbs-navigation/check-icon.svg';

// Medication
import calendar from 'assets/images/svg/medication/calendar.svg';
import refund from 'assets/images/svg/medication/refund.svg';
import medicationBottle from 'assets/images/svg/medication/medication-bottle.svg';
import delivery from 'assets/images/svg/medication/delivery.svg';
import app from 'assets/images/svg/medication/rating/app.svg';
import google from 'assets/images/svg/medication/rating/google.svg';
import rating from 'assets/images/svg/medication/rating/rating.svg';
import forbes from 'assets/images/svg/medication/rating/forbes.svg';
import yahoo from 'assets/images/svg/medication/rating/yahoo.svg';
import womenHealth from 'assets/images/svg/medication/rating/women-health.svg';
import book from 'assets/images/svg/medication/medication-description/book.svg';
import tryImage from 'assets/images/svg/medication/medication-description/try.svg';
import person from 'assets/images/svg/medication/medication-description/person.svg';
import clock from 'assets/images/svg/medication/medication-description/clock.svg';
// import phone from 'assets/images/svg/medication/phone.svg';
import medicationsPack from 'assets/images/webp/medication/medications-pack.webp';
import medication from 'assets/images/webp/medication/medication-description/medication.webp';

import doctorPhoto from 'assets/images/svg/medication/doctor.webp';
import coachMessage from 'assets/images/svg/medication/coach-message.webp';

// Scientific Proof
import scientificProofGraphic from 'assets/images/svg/scientific-proof/scientific-proof-graphic.svg';
import columbiaUniversityIcon from 'assets/images/svg/scientific-proof/columbia-university.svg';
import mayoClinicIcon from 'assets/images/svg/scientific-proof/mayo-clinic.svg';

// User Research
import check from 'assets/images/svg/user-research/check.svg';
import cross from 'assets/images/svg/user-research/cross.svg';

// Before after medication
import beforeAfterMedicationFemale from 'assets/images/webp/before-after-medication/before-after-medication-female.webp';
import beforeAfterMedicationMale from 'assets/images/webp/before-after-medication/before-after-medication-male.webp';

// Important date calendar
import nextArrow from 'assets/images/svg/important-date-calendar/next-arrow.svg';
import prevArrow from 'assets/images/svg/important-date-calendar/prev-arrow.svg';
import calendarIcon from 'assets/images/svg/important-date-calendar/calendar-icon.svg';
import crossIcon from 'assets/images/svg/important-date-calendar/cross-icon.svg';

// How Sema works (Variation 1)
import manBody from 'assets/images/webp/how-sema-works/man-body.webp';
import manBodyMobile from 'assets/images/webp/how-sema-works/man-body-mobile.webp';
import womanBody from 'assets/images/webp/how-sema-works/woman-body.webp';
import womanBodyMobile from 'assets/images/webp/how-sema-works/woman-body-mobile.webp';
import arrow from 'assets/images/svg/how-sema-works/arrow.svg';

// How Sema works (Variation 2)
import brain from 'assets/images/png/how-sema-works/brain.png';
import heart from 'assets/images/png/how-sema-works/heart.png';
import liver from 'assets/images/png/how-sema-works/liver.png';
import pancreas from 'assets/images/png/how-sema-works/pancreas.png';
import stomach from 'assets/images/png/how-sema-works/stomach.png';

import rate from 'assets/images/svg/weight-metabolic-rate/weight-metabolic-rate-graphic.svg';

// Customer Video Review
import play from 'assets/images/svg/medication/video-player/play.svg';
import prev from 'assets/images/svg/medication/video-customer-reviews/prev-button.svg';
import next from 'assets/images/svg/medication/video-customer-reviews/next-button.svg';
import close from 'assets/images/svg/medication/video-player/cross.svg';

export const BackgroundLoadingImages = () => {
    return {
        images: [
            { key: 'svg/medication/video-player/cross.svg', pathUrl: close },
            { key: 'svg/medication/video-customer-reviews/prev-button.svg', pathUrl: prev },
            {
                key: 'svg/medication/video-customer-reviews/next-button.svg',
                pathUrl: next,
            },
            { key: 'svg/medication/video-player/play.svg', pathUrl: play },
            { key: 'svg/weight-metabolic-rate/weight-metabolic-rate-graphic.svg', pathUrl: rate },
            { key: 'svg/body-gender/male-gender.svg', pathUrl: maleGender },
            { key: 'svg/body-gender/female-gender.svg', pathUrl: femaleGender },
            { key: 'svg/weight-loss/weight-loss-graphic.svg', pathUrl: weightLossGraphic },
            {
                key: 'svg/weight-factors/weight-factors-illustration.svg',
                pathUrl: weightFactorsIllustration,
            },
            { key: 'svg/reinforcement-icons/family-tree.svg', pathUrl: familyTree },
            { key: 'svg/reinforcement-icons/medications.svg', pathUrl: medications },
            { key: 'svg/decline-user/no-medication.svg', pathUrl: noMedication },
            { key: 'svg/party-icon.svg', pathUrl: partyIcon },
            { key: 'svg/check-icon.svg', pathUrl: checkIcon },
            {
                key: 'svg/analyze-responses/weight-loss-with-able-chart.svg',
                pathUrl: weightLossWithAbleChart,
            },
            { key: 'svg/checkout/order-details/shipping-icon.svg', pathUrl: shippingIcon },
            { key: 'svg/checkout/order-details/cancel-icon.svg', pathUrl: cancelIcon },
            {
                key: 'svg/checkout/order-details/medical-advisor-icon.svg',
                pathUrl: medicalAdvisorIcon,
            },
            { key: 'svg/checkout/cart/cart-icon.svg', pathUrl: cartIcon },
            { key: 'webp/checkout/cart/medications.webp', pathUrl: cartMedications },
            {
                key: 'svg/checkout/breadcrumbs-navigation/navigation-icon.svg',
                pathUrl: navigationIcon,
            },
            {
                key: 'svg/checkout/breadcrumbs-navigation/navigation-icon-down.svg',
                pathUrl: navigationIconDown,
            },
            { key: 'svg/checkout/breadcrumbs-navigation/check-icon.svg', pathUrl: navBarCheckIcon },
            { key: 'svg/medication/calendar.svg', pathUrl: calendar },
            { key: 'svg/medication/refund.svg', pathUrl: refund },
            { key: 'svg/medication/medication-bottle.svg', pathUrl: medicationBottle },
            { key: 'svg/medication/delivery.svg', pathUrl: delivery },
            { key: 'svg/medication/rating/app.svg', pathUrl: app },
            { key: 'svg/medication/rating/google.svg', pathUrl: google },
            { key: 'svg/medication/rating/rating.svg', pathUrl: rating },
            { key: 'svg/medication/rating/forbes.svg', pathUrl: forbes },
            { key: 'svg/medication/rating/yahoo.svg', pathUrl: yahoo },
            { key: 'svg/medication/rating/women-health.svg', pathUrl: womenHealth },
            { key: 'svg/medication/medication-description/medication.svg', pathUrl: medication },
            { key: 'svg/medication/medication-description/book.svg', pathUrl: book },
            { key: 'svg/medication/medication-description/try.svg', pathUrl: tryImage },
            { key: 'svg/medication/medication-description/person.svg', pathUrl: person },
            { key: 'svg/medication/medication-description/clock.svg', pathUrl: clock },
            // { key:'svg/medication/medication-description/phone.svg', pathUrl: phone },
            { key: 'webp/medication/medications-pack.webp', pathUrl: medicationsPack },
            { key: 'webp/medication/medication-description/medication.webp', pathUrl: medication },
            { key: 'svg/medication/doctor.webp', pathUrl: doctorPhoto },
            { key: 'svg/medication/coach-message.webp', pathUrl: coachMessage },
            {
                key: 'svg/scientific-proof/scientific-proof-graphic.svg',
                pathUrl: scientificProofGraphic,
            },
            {
                key: 'svg/scientific-proof/columbia-university.svg',
                pathUrl: columbiaUniversityIcon,
            },
            { key: 'svg/scientific-proof/mayo-clinic.svg', pathUrl: mayoClinicIcon },
            { key: 'svg/user-research/check.svg', pathUrl: check },
            { key: 'svg/user-research/cross.svg', pathUrl: cross },

            {
                key: 'webp/before-after-medication/before-after-medication-female.webp',
                pathUrl: beforeAfterMedicationFemale,
            },
            {
                key: 'webp/before-after-medication/before-after-medication-male.webp',
                pathUrl: beforeAfterMedicationMale,
            },

            { key: 'svg/important-date-calendar/next-arrow.svg', pathUrl: nextArrow },
            { key: 'svg/important-date-calendar/prev-arrow.svg', pathUrl: prevArrow },
            { key: 'svg/important-date-calendar/calendar-icon.svg', pathUrl: calendarIcon },
            { key: 'svg/important-date-calendar/cross-icon.svg', pathUrl: crossIcon },

            { key: 'webp/how-sema-works/man-body.webp', pathUrl: manBody },
            { key: 'webp/how-sema-works/man-body-mobile.webp', pathUrl: manBodyMobile },
            { key: 'webp/how-sema-works/woman-body.webp', pathUrl: womanBody },
            { key: 'webp/how-sema-works/woman-body-mobile.webp', pathUrl: womanBodyMobile },
            { key: 'svg/how-sema-works/arrow.svg', pathUrl: arrow },

            { key: 'png/how-sema-works/brain.png', pathUrl: brain },
            { key: 'png/how-sema-works/heart.png', pathUrl: heart },
            { key: 'png/how-sema-works/liver.png', pathUrl: liver },
            { key: 'png/how-sema-works/pancreas.png', pathUrl: pancreas },
            { key: 'png/how-sema-works/stomach.png', pathUrl: stomach },
        ],
    };
};
