import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@nextui-org/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { cn } from 'helpers/cn';

interface ShippingStatesProps {
    statesNames: string;
}

export const ShippingStates: FC<ShippingStatesProps> = ({ statesNames }) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    const maxLines = expanded ? 'none' : 2;

    return (
        <div
            className={cn(
                `flex justify-between gap-3 bg-[#E8F5FD] rounded-lg p-4 mb-3 md:mt-2 md:mb-4 dark:bg-blue-900/50`
            )}
        >
            <h3
                style={{
                    WebkitLineClamp: maxLines,
                    display: !expanded ? '-webkit-box' : 'flex',
                    overflow: !expanded ? 'hidden' : '',
                    textOverflow: !expanded ? 'ellipsis' : 'auto',
                    WebkitBoxOrient: !expanded ? 'vertical' : 'unset',
                }}
                className={cn('text-left m-0 basis-[95%]')}
            >
                {t('checkout-shipping-address-states-info', { statesNames })}
            </h3>

            <Button
                isIconOnly
                disableRipple
                radius="full"
                size="sm"
                variant="light"
                onPress={toggleExpansion}
            >
                <ChevronDownIcon
                    className={cn('h-4 w-4 transition-transform', {
                        'rotate-180': expanded,
                    })}
                />
            </Button>
        </div>
    );
};
