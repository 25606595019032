import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

export const OrderDetails = () => {
    const items = [
        'svg/checkout/order-details/shipping-icon.svg',
        'svg/checkout/order-details/cancel-icon.svg',
        'svg/checkout/order-details/medical-advisor-icon.svg',
    ];
    const pageImages = useBackgroundImageService(items);

    return (
        <div className={'flex flex-col mt-3 md:mt-4'}>
            <div className={'flex items-center mb-3 [&_last-child]:mb-0'}>
                <img src={pageImages[0]} alt="Shipping Icon" className="mr-2 self-center" />
                <h3
                    className={
                        '!text-[15px] !leading-5 font-semibold text-[#4D4D4D] !m-0 text-left dark:text-foreground-500'
                    }
                >
                    <Trans i18nKey="checkout-order-details-free-shipping" />
                </h3>
            </div>
            <div className={'flex items-center mb-3 [&_last-child]:mb-0 '}>
                <img src={pageImages[1]} alt="Cancel Icon" className="mr-2 self-center" />
                <h3
                    className={
                        '!text-[15px] !leading-5 font-semibold text-[#4D4D4D] !m-0 text-left dark:text-foreground-500'
                    }
                >
                    <Trans i18nKey="checkout-order-details-cancel-anytime" />
                </h3>
            </div>
            <div className={'flex items-center mb-3 [&_last-child]:mb-0'}>
                <img src={pageImages[2]} alt="Medical Advisor Icon" className="mr-2 self-center" />
                <h3
                    className={
                        '!text-[15px] !leading-5 font-semibold text-[#4D4D4D] !m-0 text-left dark:text-foreground-500'
                    }
                >
                    <Trans i18nKey="checkout-order-details-medical-advisor" />
                </h3>
            </div>
        </div>
    );
};
