import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ftValidationQuery, inchesValidationQuery } from './ft-inches-validation-query';

import { ValidatedInput } from 'components/validated-input';

type FtAndInputsProps = {
    ft: string;
    inches: string;
    autoFocus?: boolean;
    onError: (error: boolean, key: string) => void;
    onChangeFt: (e: any) => void;
    onChangeInches: (e: any) => void;
};

export const FtInchesInputs: FC<FtAndInputsProps> = ({
    ft,
    inches,
    autoFocus,
    onError,
    onChangeFt,
    onChangeInches,
}) => {
    const { t } = useTranslation();

    const propsFt = useMemo(() => ({ label: t('height-ft'), maxLength: 1 }), []);
    const propsIn = useMemo(() => ({ label: t('height-inches'), maxLength: 2 }), []);

    const handleError = (key: string) => (error: boolean) => onError(error, key);

    useEffect(
        () => () => {
            onError(false, t('ft'));
            onError(false, t('inches'));
        },
        []
    );

    return (
        <div className={'flex mb-3 w-full justify-between md:mb-4'}>
            <ValidatedInput
                type="number"
                value={ft}
                autoFocus={autoFocus}
                onError={handleError(t('ft'))}
                onChange={onChangeFt}
                validationQuery={ftValidationQuery}
                wrapperClass={'w-[48%]'}
                name="field-1"
                {...propsFt}
            />

            <ValidatedInput
                type="number"
                value={inches}
                onError={handleError(t('in'))}
                onChange={onChangeInches}
                validationQuery={inchesValidationQuery}
                wrapperClass={'w-[48%]'}
                name="field-2"
                {...propsIn}
            />
        </div>
    );
};
