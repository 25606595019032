import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo2 } from '../../../assets/images/svg/logo-black2.svg';

import { isBoolean } from 'helpers';
import { BackArrowButton } from 'components/back-arrow-button';

import { Button, Navbar, Tooltip } from '@nextui-org/react';
import { useTheme } from 'next-themes';
import { SunIcon, MoonIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';

import { useAppState } from 'context/AppContext';

export const LogoHeader = React.memo(({ classNames, backButtonAvailable }: any) => {
    const navigate = useNavigate();
    const handleBack = () => navigate(-1);

    const isBackButtonAvailable = isBoolean(backButtonAvailable)
        ? backButtonAvailable
        : backButtonAvailable || true;

    const { user, setUser } = useAppState();
    const { theme, setTheme } = useTheme();

    return (
        <Navbar
            className="h-[52px] bg-content2 dark:bg-content1"
            classNames={{
                wrapper: 'max-w-full p-0',
            }}
        >
            <div className="flex-1">
                {isBackButtonAvailable && <BackArrowButton handleBack={handleBack} />}
            </div>

            <Logo2 className="text-foreground ml-4" />

            <div className="flex gap-4 bg-transparent mr-4 flex-1 justify-end" data-theme={theme}>
                <Tooltip content="Control Panel" className="text-foreground">
                    <Button
                        isIconOnly
                        variant="light"
                        size="sm"
                        radius="full"
                        className="text-foreground/70"
                        onPress={() => {
                            setUser((prevUser) => ({
                                ...prevUser,
                                isControlPanelOpen: !prevUser.isControlPanelOpen,
                            }));
                        }}
                    >
                        <AdjustmentsVerticalIcon className="h-6 w-6" />
                    </Button>
                </Tooltip>
                <Button
                    isIconOnly
                    className="data-[hover]:bg-foreground/10 bg-transparent"
                    radius="full"
                    variant="light"
                    size="sm"
                    onPress={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                    aria-label={
                        theme === 'light' ? 'Switch to dark theme' : 'Switch to light theme'
                    }
                >
                    <div className="h-6 w-6 text-foreground/80 bg-transparent">
                        {theme === 'light' ? <MoonIcon /> : <SunIcon />}
                    </div>
                </Button>
            </div>
        </Navbar>
    );
});
