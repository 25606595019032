import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { FloatedButton } from 'components/floated-button';
import { NextButton } from 'components/next-button';
import { Heading } from 'ncomponents/Heading';
import { SingleChoice, ISingleChoiceButtonOption } from 'ncomponents/SingleChoiceButton';

export const ImportantEvent = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.ImportantEvent],
        defaultValue: '',
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.ImportantEvent]}-other-userAnswer`,
        defaultValue: '',
    });

    const options: ISingleChoiceButtonOption[] = [
        { value: 'vacation', label: t('important-event-option-vacation') },
        { value: 'wedding', label: t('important-event-option-wedding') },
        { value: 'holiday', label: t('important-event-option-holiday') },
        { value: 'summer', label: t('important-event-option-summer') },
        { value: 'reunion', label: t('important-event-option-reunion') },
        { value: 'birthday', label: t('important-event-option-birthday') },
        { value: 'other', label: t('important-event-option-other'), userAnswer, setUserAnswer },
        { value: 'none', label: t('important-event-option-none') },
    ];

    const handleChoiceButtonClick = (option: ISingleChoiceButtonOption) => {
        setPageValue(option);
        // setPageValue(option.value);

        if (option.value !== 'other') {
            handleClick(option);
        } else {
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
        }
    };

    const handleClick = (option: ISingleChoiceButtonOption) => {
        const showUserAnswer = option.value === 'other' && userAnswer;
        const trimmedUserAnswer = userAnswer.trim();

        // const intakeFormValue = showUserAnswer
        //     ? `${option.label}: ${trimmedUserAnswer}`
        //     : option.label;
        const intakeFormValue = showUserAnswer
            ? `${option.value}: ${trimmedUserAnswer}`
            : option.value;

        saveAnswers({
            key: LocalStorageKeys[RoutePath.ImportantEvent],
            itemQuestion: { question: t('important-event-title'), value: intakeFormValue },
        });

        pageConfiguration.handleOnPageComplete({
            key: option.value,
            value: intakeFormValue,
        });
    };

    return (
        <>
            <Heading title={t('important-event-title')} subtitle={t('important-event-subtitle')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleChoiceButtonClick}
            />

            {pageValue?.value === 'other' && (
                <FloatedButton floated={true} withGradient>
                    <NextButton onClick={() => handleClick(pageValue)} disabled={false} />
                </FloatedButton>
            )}
        </>
    );
};
