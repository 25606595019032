import { memo, useState } from 'react';
import { Trans } from 'react-i18next';

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import {
    CustomerCard,
    CustomerCardType,
} from 'pages/medication/video-customer-reviews/customer-card';
import { VideoPlayer } from 'pages/medication/video-player';
import { reviewsConfig } from 'pages/medication/video-customer-reviews/customer-card/config';

import { ScrollShadow } from '@nextui-org/react';

// todo design note: added memo to prevent rerender when have timer block on /medication page
export const VideoCustomerReviews = memo(() => {
    const [currentVideoName, setCurrentVideoName] = useState<string | null>(null);

    const items = [
        'svg/medication/video-customer-reviews/prev-button.svg',
        'svg/medication/video-customer-reviews/next-button.svg',
    ];
    const pageImages = useBackgroundImageService(items);
    const isDesktop = useDesktopMediaWatcher();

    return (
        <div
            className={
                'flex flex-col justify-center items-start relative py-8 px-0 bg-[#C1D0F6] dark:bg-blue-900/30 md:items-center md:py-20'
            }
        >
            {currentVideoName && (
                <VideoPlayer
                    videoId={
                        reviewsConfig.find((customerCard) => customerCard.name === currentVideoName)
                            ?.videoId as string
                    }
                    onClose={() => setCurrentVideoName(null)}
                />
            )}

            <h1
                className={
                    'text-2xl leading-[34px] text-left mt-0 mr-0 mb-8 ml-6 md:text-[34px] md:leading-[44px] md:text-center md:mt-0 md:mx-0 md:mb-14'
                }
            >
                <Trans i18nKey="medication-video-customer-reviews-title" />
            </h1>

            <Splide
                className={'relative w-dvw !visible md:w-[90%] md:max-w-[1512px]'}
                hasTrack={false}
                options={{
                    perPage: 4.5,
                    perMove: 1,
                    gap: 24,
                    pagination: false,
                    type: 'loop',
                    infinite: true,
                    center: !isDesktop,
                    fixedWidth: isDesktop ? undefined : '60%',
                    focus: isDesktop ? undefined : 'center',
                    breakpoints: {
                        280: {
                            perPage: 1.5,
                        },
                        479: {
                            perPage: 2.5,
                            gap: 16,
                        },
                        768: {
                            perPage: 2,
                        },
                        850: {
                            perPage: 3,
                        },
                        1200: {
                            perPage: 3.5,
                        },
                    },
                }}
            >
                <div className="splide__arrows splide__arrows--ltr">
                    <div className="splide__arrow splide__arrow--prev !w-auto absolute !top-[55%] pointer !opacity-100 min-[1200px]:!left-9">
                        <img
                            src={pageImages[0]}
                            alt="Prev Button"
                            className="w-8 h-8 md:w-10 md:h-10 "
                        />
                    </div>
                    <div className="splide__arrow splide__arrow--next !w-auto absolute !top-[55%] pointer !opacity-100 min-[1200px]:!right-9">
                        <img
                            src={pageImages[1]}
                            alt="Next Button"
                            className="w-8 h-8 md:w-10 md:h-10 "
                        />
                    </div>
                </div>
                <ScrollShadow
                    orientation="horizontal"
                    className="md:max-w-[85%] md:my-0 md:mx-auto"
                    hideScrollBar
                    offset={-10}
                    size={100}
                    visibility={isDesktop ? 'right' : 'auto'}
                >
                    <SplideTrack className="">
                        {reviewsConfig.map((customerCard: CustomerCardType) => (
                            <SplideSlide
                                key={customerCard.name}
                                className={
                                    'h-full cursor-pointer min-h-[340px] min-w-[236px] flex flex-col justify-end items-start rounded-xl bg-center bg-cover bg-no-repeat pt-0 px-4 pb-4 '
                                }
                                // todo, add if needed
                                // '-webkit-backface-visibility': 'hidden',
                                // '-webkit-transform': 'translate3d(0, 0, 0)',
                                style={{ backgroundImage: customerCard.backgroundPhoto }}
                            >
                                <CustomerCard
                                    customerCard={customerCard}
                                    setCurrentVideoName={setCurrentVideoName}
                                />
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                </ScrollShadow>
            </Splide>
        </div>
    );
});
