import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { ISingleChoiceButtonOption, SingleChoice } from 'ncomponents/SingleChoiceButton';
import { Heading } from 'ncomponents/Heading';

export const PrescriptionMedications = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PrescriptionMedications];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        setPageValue(option);
        // setPageValue(option.value); // todo check value if should store object or string with new value & label object
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('prescription-medications-title'), value: t(option.label) },
        });

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const options: ISingleChoiceButtonOption[] = [
        {
            value: 'never-heard',
            label: t('prescription-medications-option-never-heard'),
        },
        {
            value: 'heard',
            label: t('prescription-medications-option-heard'),
        },
        {
            value: 'expert',
            label: t('prescription-medications-option-expert'),
        },
    ];

    return (
        <>
            <Heading title={t('prescription-medications-title')} />

            <SingleChoice options={options} selectedOption={pageValue} onSelect={handleClick} />
        </>
    );
};
