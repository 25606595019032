import React, {
    DetailedHTMLProps,
    PropsWithChildren,
    HTMLAttributes,
    ImgHTMLAttributes,
} from 'react';
import { ButtonProps as NextUIButtonProps } from '@nextui-org/react';

import { cn } from 'helpers/cn';

import { Button } from '../Button';

import { TextAreaInput } from 'ncomponents/TextAreaInput';
import { useImagePreloadingService } from '../../context/image-provider/ImageProvider';
import { MAX_CHARACTERS } from 'constants/text-area-input';

export interface ISingleChoiceButtonLabelProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    label: string;
}

// export interface ISingleChoiceButtonImgProps extends
export type ISingleChoiceButtonImgProps = DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>;

export interface ISingleChoiceButtonOption extends ISingleChoiceButtonLabelProps {
    value: string;
    image?: string;
    userAnswer?: string;
    setUserAnswer?: (text: string) => void;
    maxLength?: number;
}

export interface ISingleChoiceButtonProps extends NextUIButtonProps {
    option: ISingleChoiceButtonOption;
    selected: boolean;
    className?: string;
}

export interface ISingleChoice {
    options: ISingleChoiceButtonOption[];
    selectedOption: ISingleChoiceButtonOption;
    onSelect: (option: ISingleChoiceButtonOption) => void;
    classNames?: {
        wrapper?: string;
        button?: string;
    };
    textAreaInputLabel?: string;
}

export const SingleChoicesButtonWrapper = ({
    children,
    className,
}: PropsWithChildren<{ className?: string }>) => (
    <div className={cn('flex flex-col gap-3 md:gap-4 mb-3 md:mb-4', className)}> {children} </div>
);

export const SingleChoiceButton = ({
    children,
    option,
    selected,
    className,

    ...props
}: ISingleChoiceButtonProps) => {
    return (
        <div className={cn(className)}>
            <div className={'flex w-full justify-center'}>
                <Button
                    buttonClass={cn(
                        'max-w-[560px] min-h-[56px] h-auto flex flex-col items-center justify-center min-w-none max-h-none dark:bg-content2 dark:text-foreground',
                        {
                            'bg-btnActive dark:bg-btnActive text-white hover:bg-btnActive hover:text-white dark:hover:bg-btnActive':
                                selected,
                        },
                        className
                    )}
                    {...props}
                >
                    {children}
                </Button>
            </div>
        </div>
    );
};

export const SingleChoicesButtonImg = ({ src, alt }: ISingleChoiceButtonImgProps) => (
    <>
        <img src={src} alt={alt} className={'w-full h-[122px]'} />
    </>
);

export const SingleChoicesButtonLabel = ({ label }: ISingleChoiceButtonLabelProps) => (
    <span className={'font-normal text-lg leading-6 -tracking-[0.0024em] text-wrap'}>{label}</span>
);

export const SingleChoice = ({
    options,
    selectedOption,
    onSelect,
    textAreaInputLabel = '',
    classNames,
}: ISingleChoice) => {
    const { getImageByUrl } = useImagePreloadingService();
    const maxCharacters = MAX_CHARACTERS;

    return (
        <SingleChoicesButtonWrapper className={classNames?.wrapper}>
            {options.map((option) => {
                const image = option.image ? getImageByUrl(option.image) : null;

                const isSelected = selectedOption?.value === option.value;
                const textAreaInputShown = isSelected && option.setUserAnswer;

                return (
                    <React.Fragment key={option.value}>
                        <SingleChoiceButton
                            option={option}
                            selected={isSelected}
                            onClick={() => onSelect(option)}
                            className={classNames?.button}
                        >
                            <SingleChoicesButtonLabel label={option.label} />
                            {image && <SingleChoicesButtonImg src={image} alt={option.value} />}
                        </SingleChoiceButton>

                        {textAreaInputShown && (
                            <TextAreaInput
                                onValueChange={option?.setUserAnswer}
                                value={option.userAnswer as string}
                                maxLength={maxCharacters}
                                label={textAreaInputLabel}
                                classNames={{
                                    description: 'text-right',
                                }}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </SingleChoicesButtonWrapper>
    );
};
