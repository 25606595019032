import { useEffect, useState } from 'react';

export interface UseTimerProps {
    initialMinutes: number;
    initialSeconds: number;
    endOfTimerHandler?: () => void;
}

type RenderTime = {
    renderMinutes: string;
    renderSeconds: string;
};

export const useTimer = ({
    initialMinutes,
    initialSeconds,
    endOfTimerHandler = () => undefined,
}: UseTimerProps): RenderTime => {
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);

    const timerFired = minutes === 0 && seconds === 0;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

    useEffect(() => {
        let timerInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(timerInterval);
                    endOfTimerHandler();
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(timerInterval);
        };
    }, [minutes, seconds, setMinutes, setSeconds]);

    return {
        renderMinutes: timerFired ? '00' : minutes.toString(),
        renderSeconds: formattedSeconds,
    };
};
