export const BreadcrumbsNavigationKeys = {
    quiz: 'Quiz',
    healthPlan: 'Health Plan',
    account: 'Account',
    checkout: 'Checkout'
};

export const BreadcrumbsNavigationVariation1Keys = {
    healthPlan: 'Health Plan',
    account: 'Account',
    shipping: 'Shipping',
    payment: 'Payment'
};
