import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { ExperimentsKeys } from 'constants/experiments';
import { LinksKeys } from 'constants/links';

import { CheckPointsList } from 'components/check-points-list';
import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';
import { useAppState } from 'context/AppContext';

export const Consent = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const itemsTextKeys = [
        'consent-list-item-sole-user',
        'consent-list-item-truthful-answers',
        'consent-list-item-one-medication',
        'consent-list-item-terms-privacy-policy',
    ];

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const termsEvent = () => pageConfiguration.event('RxWeightTermsClicked');
    const privacyEvent = () => pageConfiguration.event('RxWeightPrivacyPolicyClicked');

    const termsLink = (
        <a
            className={'underline-offset-4 underline text-foreground'}
            href={LinksKeys.TermsAndCondition}
            target="_blank"
            rel="noreferrer nofollow"
            onClick={termsEvent}
        ></a>
    );

    const privacyLink = (
        <a
            className={'underline-offset-4 underline text-foreground'}
            href={LinksKeys.PrivacyPolicy}
            target="_blank"
            rel="noreferrer nofollow"
            onClick={privacyEvent}
        ></a>
    );

    // const mw_plan_loader_screen = true; // ExperimentsKeys.mw_plan_loader_screen
    // const mw_important_date = true; // ExperimentsKeys.mw_important_date
    const { user } = useAppState();
    const mw_plan_loader_screen = user.experiments.includes(ExperimentsKeys.mw_plan_loader_screen);
    const mw_important_date = user.experiments.includes(ExperimentsKeys.mw_important_date);

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.mw_plan_loader_screen,
            mw_plan_loader_screen ? '1' : '0'
        );
        localStorage.setItem(ExperimentsKeys.mw_important_date, mw_important_date ? '1' : '0');
    }, [mw_plan_loader_screen, mw_important_date]);

    return (
        <>
            <Heading title={t('consent-title')} subtitle={t('consent-subtitle')} />

            <div className={'mb-3 md:mb-14'}>
                <CheckPointsList
                    items={itemsTextKeys}
                    listItemClassName={'flex mb-4 last-child:mb-3 items-start'}
                    listItemTextClassName={'text-left ml-3 text-lg leading-[26px]'}
                    listItemTextComponents={[termsLink, privacyLink]}
                />
            </div>

            <NextButton typographyText={t('consent-button-next')} onClick={handleClick} />
        </>
    );
};
