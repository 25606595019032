import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { ReactComponent as RatingSvg } from 'assets/images/svg/medication/rating/rating.svg';

export const Rating = () => {
    // todo coding design note: array size is changed, since took one svg outside of array to work with currentColor for dark theme
    const items = [
        'svg/medication/rating/app.svg',
        'svg/medication/rating/google.svg',
        // 'svg/medication/rating/rating.svg',
        'svg/medication/rating/women-health.svg',
        'svg/medication/rating/forbes.svg',
        'svg/medication/rating/yahoo.svg',
    ];
    const pageImages = useBackgroundImageService(items);

    return (
        <div
            className={`flex flex-col justify-center items-center relative w-full pt-12 px-4 pb-8 bg-[#F6F6F6] dark:bg-content1 md:flex-row md:pt-20 md:px-[18px] md:pb-12`}
        >
            <div className={`relative flex justify-between gap-6 shrink-0`}>
                <img className={'w-8 md:w-9'} src={pageImages[0]} alt={'Able App Rating'} />
                <img className={'-mr-2 md:mr-0'} src={pageImages[1]} alt={'Play Rating'} />
                {/* <img className={rating} src={pageImages[2]} alt={'Rating'} /> */}
                <RatingSvg className="text-foreground h-9" />
            </div>

            {/* Divider */}
            <div
                className={`w-[144px] border border-solid border-[#999999] my-6 mx-0 md:w-0 md:h-9 md:my-0 md:mx-[34px]`}
            />

            <div
                className={`w-full max-w-[620px] relative flex justify-between max-[280px]:flex-col max-[280px]:items-center max-[280px]:gap-[10px]`}
            >
                <img
                    className={'w-[120px] h-[23px] max-[360px]:w-[90px] md:w-[unset] md:h-[unset]'}
                    src={pageImages[2]}
                    alt={'Women Health Journal Logo'}
                />
                <img
                    className={'w-[101px] h-6 max-[360px]:w-[90px] md:w-[unset] md:h-[unset]'}
                    src={pageImages[3]}
                    alt={'Forbes Journal Logo'}
                />
                <img
                    className={'w-[84px] h-6 max-[360px]:w-[60px] md:w-[unset] md:h-[unset]'}
                    src={pageImages[4]}
                    alt={'Yahoo Journal Logo'}
                />
            </div>
        </div>
    );
};
