import { FC } from 'react';
import {
    Progress as NextUIProgressBar,
    ProgressProps as NextUIProgressProps,
} from '@nextui-org/react';

import { isNumber } from 'helpers';
import { cn } from 'helpers/cn';

export interface ProgressBarProps extends NextUIProgressProps {
    progress: { percents: number; label: string };
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress, ...props }) => {
    // todo ui design note: thin progress bar transitions from 0 in nav/header when go from /analyze-responses to /email which did not exist in previous custom progress bar */}
    return isNumber(progress.percents) ? (
        <NextUIProgressBar
            aria-label="progress"
            size="sm"
            value={progress.percents}
            {...props}
            className={cn('mx-auto !h-full gap-0', props.className)}
            classNames={{
                base: 'relative !gap-0',
                track: cn('drop-shadow-md border border-default', props.classNames?.track),
                indicator: cn('!bg-btnSecondaryPrimary', props.classNames?.indicator),
                label: cn('tracking-wider font-medium text-default-600', props.classNames?.label),
                value: cn(
                    'text-foreground text-center mx-auto absolute z-[2] top-1/2 left-0 right-0 -translate-y-1/2 !text-base',
                    props.classNames?.value
                ),
            }}
        />
    ) : null;
};
