import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { RoutePath } from 'routes/route-path.constant';

import { useImportantDateCalculation } from 'hooks/use-important-date-calc.hook';
import { useBodyPlanData } from 'hooks/use-body-plan-data.rx.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import rightArrow from 'assets/images/svg/important-event-popover/right-arrow.svg';

import { useStyles } from './styles';
import { useGetAnswers } from 'hooks/use-save-answers.hook';

// import styles from './styles.module.css';

export const ImportantEventPopover = () => {
    const { t } = useTranslation();

    // JSS
    const { l_p_3_0, l_p_3_1, l_p_6_1, l_p_6_3, l_p_6_4 } = useStyles();

    // styles module
    // const { l_p_3_0, l_p_3_1, l_p_6_1, l_p_6_3, l_p_6_4 } = styles;

    // tailwind (half) // todo design note: better use regular css for these l_p_ styles?
    // const l_p_3_0 =
    //     'top-[6%] left-[10%] max-[440px]:top-[5%] max-[393px]:top-[4.5%] max-[375px]:top-[4.5%] max-[360px]:top-[3.5%] max-[320px]:top-[3.5%] max-[280px]:top-[1.5%] max-[280px]:[&_span[class^="text"]]:mt-[-5px] max-[280px]:[&_span[class^="text"]]:ml-[-5px]';
    // const l_p_3_1 =
    //     'top-[69%] left-[26%] w-[29%] flex-row-reverse max-[540px]:top-[68.5%] max-[440px]:top-[65.5%] max-[428px]:left-[16%] max-[428px]:w-[39%] max-[414px]:left-[15%] max-[414px]:w-[41%] max-[393px]:left-[14%] max-[393px]:w-[42%] max-[375px]:left-[12%] max-[375px]:w-[45%] max-[360px]:left-[9%] max-[360px]:w-[48%] max-[360px]:top-[67%] max-[320px]:left-[10%] max-[320px]:[&_span[class^="text"]]:text-xs max-[280px]:top-[64%] max-[280px]:left-[12%] max-[280px]:w-[45%] max-[280px]:[&_span[class^="text"]]:text-[9px]';
    // const l_p_6_1 =
    //     'top-[35%] left-[29%] max-[540px]:left-[30%] max-[428px]:left-[30.5%] max-[393px]:left-[31%] max-[375px]:left-[32%] max-[320px]:left-[33%] max-[320px]:[&_span[class^="text"]]:text-xs max-[320px]:[&_span[class^="text"]]:mt-[-10px] max-[320px]:[&_span[class^="text"]]:ml-[-10px] max-[280px]:[&_span[class^="text"]]:mt-[-20px] max-[280px]:[&_span[class^="text"]]:ml-[-20px] max-[280px]:left-[34%]';

    // const eventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]); // todo note get object instead of string
    const eventValue = useGetAnswers(LocalStorageKeys[RoutePath.ImportantEvent]);

    const event =
        eventValue === 'other' ? t('important-event-date-other-option-event-name') : eventValue;
    const eventDate = localStorage.getItem(
        LocalStorageKeys[RoutePath.ImportantEventDate]
    ) as string;

    let { unit, finalDate, weightLbs, weightsPlan, monthsPlan, monthsPlanForDisplay } =
        useBodyPlanData();

    const { isFutureDate, weightAtEventDate, isToday, isWithinWeek, positionForDisplay } =
        useImportantDateCalculation(eventDate, monthsPlan, weightsPlan, monthsPlanForDisplay);

    const [sectionClass, setSectionClass] = useState('');

    useEffect(() => {
        // setSectionClass([l_p_3_0, l_p_6_1, l_p_3_1, l_p_6_3, l_p_6_4, l_p_6_4][positionForDisplay]);
        setSectionClass([l_p_3_0, l_p_6_1, l_p_3_1, l_p_6_3, l_p_6_4, l_p_6_4][positionForDisplay]);
    }, [positionForDisplay]);

    const isEventInFuture = isFutureDate && dayjs(new Date(eventDate)).isAfter(finalDate);

    const getWeightToShow = () => {
        const weightToSubtract = 1;
        const adjustedWeight = weightAtEventDate - weightToSubtract;

        let weightToShow;
        if (isToday) {
            weightToShow = weightLbs;
        } else if (isWithinWeek) {
            weightToShow = weightLbs - weightToSubtract;
        } else if (weightAtEventDate === weightLbs) {
            weightToShow = adjustedWeight;
        } else {
            weightToShow = weightAtEventDate;
        }

        return weightToShow;
    };

    return (
        <div
            className={`animate-opacityAnimationEventPopover absolute flex gap-[10px] opacity-0 ${sectionClass} ${
                isEventInFuture && 'w-auto top-[0%] right-[0%] [&_span[class^="text"]]:text-xs'
            }`}
        >
            {!isEventInFuture && (
                <span
                    className={
                        'rounded-full w-[13px] min-w-[13px] h-[13px] bg-[#36B992] border-solid border-3 border-white'
                    }
                ></span>
            )}
            <span
                className={
                    'text-[#36B992] font-semibold text-sm leading-[14px] uppercase text-center'
                }
            >
                {event} ({getWeightToShow()}&nbsp;{unit}){' '}
                {isEventInFuture && <img src={rightArrow} alt="Arrow" />}
            </span>
        </div>
    );
};
