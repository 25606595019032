import React from 'react';

import { Button } from '../Button';

import { cn } from 'helpers/cn';

interface Props {
    rating: RatingType;
    handleClick: (rating: RatingType) => void;
    selected: boolean;
    buttonClass?: string;
    buttonTextClass?: string;
}

export type RatingType = { value: string; label: string };

export const RatingButton = (props: Props) => {
    const { rating, handleClick, selected, buttonClass, buttonTextClass } = props;

    return (
        <div className={'w-auto'}>
            <Button
                onClick={() => handleClick(rating)}
                className={cn(
                    'bg-btnPrimary dark:bg-content2 dark:text-foreground w-[64px] min-w-[42px] max-[375px]:w-[52px] max-[320px]:w-[42px] h-14 text-black px-4 py-4 rounded-lg cursor-pointer hover:bg-btnHover dark:hover:bg-content3',
                    buttonClass,
                    selected && 'bg-btnActive dark:bg-btnActive text-white hover:!opacity-100 hover:!bg-btnActive dark:hover:!bg-btnActive',
                )}
            >
                <span className={cn(
                    'text-base font-normal leading-6',
                    buttonTextClass,
                )}>
                    {rating.label}
                </span>
            </Button>
        </div>
    );
};
