import {
    Textarea as NextUITextarea,
    TextAreaProps as NextUITextAreaProps,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

export type ITextAreaInputProps = NextUITextAreaProps;

export const TextAreaInput = ({ value, maxLength, label, ...props }: ITextAreaInputProps) => {
    const { t } = useTranslation();

    return (
        <NextUITextarea
            value={value}
            label={label ? label : t('multichoice-button-other-option-input-label')} // fallback default label
            description={
                maxLength ? (typeof value === 'string' ? maxLength - value.length : 0) : ''
            }
            {...props}
        />
    );
};
