import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Banner } from 'ncomponents/Banner';

interface TipBannerProps {
    textKey: string;
}

export const TipBanner: FC<TipBannerProps> = ({ textKey }): JSX.Element => {
    return (
        <Banner>
            <h3 className={'leading-4 md:leading-[18px] md:text-base text-black'}>
                <Trans
                    i18nKey={textKey}
                    components={[
                        <span className="font-semibold text-sm leading-4 md:text-base"></span>,
                    ]}
                />
            </h3>
        </Banner>
    );
};
