import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';

export const WeightLoss = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = ['svg/weight-loss/weight-loss-graphic.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <Heading
                title={t('weight-loss-title')}
                subtitle={<Trans i18nKey="weight-loss-subtitle" components={[<strong></strong>]} />}
            />

            <div>
                <h2 className={'font-semibold text-left mt-0 mx-0 mb-4 md:mb-4 self-start'}>
                    {t('weight-loss-image-chart-title')}
                </h2>
                <img
                    src={pageImages[0]}
                    className={'w-full max-w-[560px] h-auto'}
                    alt="Weight Loss Chart"
                />
                <div
                    className={
                        'flex justify-between items-center h-[18px] w-full mt-2 mx-0 mb-3 md:mt-3 md:mx-0 md:mb-4'
                    }
                >
                    <h3 className={'text-xs text-[#6B6B6B] dark:text-foreground-500 m-0'}>
                        {t('weight-loss-month-one')}
                    </h3>
                    <h3 className={'text-xs text-[#6B6B6B] dark:text-foreground-500 m-0'}>
                        {t('weight-loss-month-six')}
                    </h3>
                </div>
            </div>

            <NextButton typographyText={t('weight-loss-button-next')} onClick={handleClick} />
        </>
    );
};
