import { FC } from 'react';

export interface TitleProps {
    number: number;
    text: string;
}

export const Title: FC<TitleProps> = ({ number, text }) => {
    return (
        <div className={'flex items-center gap-3 md:gap-4'}>
            <div
                className={
                    'rounded-full w-6 h-6 bg-[#1375D6] text-white text-center flex items-center justify-center text-base leading-6 font-semibold shrink-0 md:w-10 md:h-10 md:text-xl'
                }
            >
                {number}
            </div>
            <h1 className="text-left text-lg leading-[22px] m-0 md:text-2xl md:leading-[34px]">
                {text}
            </h1>
        </div>
    );
};
