import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useEnchargeSearch } from 'hooks/use-encharge-search.hook';

import { ThinProgressBar } from 'components/progress-bar-thin';
import { ProgressBar } from 'ncomponents/ProgressBar/ProgressBar';

import { LogoHeader } from '../header/logo';
import { EmailHeader } from '../header/email';
import { Footer } from '../footer';

import { setGtagClientId } from 'helpers/gtag-client-id';

import { useAppState } from 'context/AppContext';
import { UserJourneyControlPanel } from 'components/user-journey-control-panel';
import { cn } from 'helpers/cn';
import { LocalStorageKeys } from 'constants/local-storage';

export const Content = ({ children }: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useAppState();

    const { routePath, UTMSearch, isRequiredKeysExists } = useEnchargeSearch();

    const { pageConfiguration } = useContext(BaseContext);

    const page = pageConfiguration
        .setNavigate(navigate)
        .setConfigurationByLocation(location)
        .getPageConfiguration();

    const backButtonNotAvailableLocations = [RoutePath.BodyGender];

    const [backButtonAvailable, setBackButtonAvailable] = useState(
        !backButtonNotAvailableLocations.includes(location.pathname)
    );

    useEffect(() => {
        pageConfiguration.handleOnPageEnter();
    }, [page]);
    // }, [page, user.experiments]);
    // todo design note on load plan loader not update header layout

    localStorage.setItem(LocalStorageKeys[RoutePath.Email], 'test@test.co'); // todo temp design

    useEffect(() => {
        if (routePath) {
            navigate(`..${routePath}${UTMSearch}`);

            // @description: GA set a new client_id after redirect
            setTimeout(() => {
                setGtagClientId();
            }, 100);
        }
    }, [routePath]);

    useEffect(() => {
        setBackButtonAvailable(!backButtonNotAvailableLocations.includes(location.pathname));
    }, [location.pathname]);

    const footerVisible = [RoutePath.Medication, RoutePath.Checkout].includes(location.pathname);

    if (routePath !== null && routePath !== location.pathname) {
        return null;
    }

    if (page?.requiredKeys && !isRequiredKeysExists(page?.requiredKeys)) {
        // @description: GA set a new client_id after redirect
        setTimeout(() => {
            setGtagClientId();
        }, 100);
    }

    return (
        <>
            {page?.requiredKeys && !isRequiredKeysExists(page?.requiredKeys) ? (
                <Navigate to={`${RoutePath.BodyGender}${UTMSearch}`} />
            ) : (
                <>
                    {user.isControlPanelOpen && <UserJourneyControlPanel />}

                    {page.header.email ? (
                        <EmailHeader backButtonAvailable={backButtonAvailable} />
                    ) : null}
                    {page.header.logo ? (
                        <LogoHeader backButtonAvailable={location.pathname !== '/'} />
                    ) : null}
                    {
                        <>
                            {page.header.progress && (
                                // <ThinProgressBar progress={page.header.progress} />
                                <ProgressBar progress={page.header.progress} />
                            )}
                            {page.customWrapper ? (
                                children
                            ) : (
                                <div
                                    className={cn(
                                        'max-w-[560px] mx-auto relative pt-0 px-4 pb-10 md:p-0',
                                        {
                                            'pt-0 px-4 pb-10 md:pt-0 md:px-0 md:pb-5':
                                                page.scrollableContent,
                                        }
                                    )}
                                >
                                    {children}
                                </div>
                            )}
                        </>
                    }
                    {footerVisible && <Footer />}
                </>
            )}
        </>
    );
};
