import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { ExperimentsKeys } from 'constants/experiments';
import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';

const IMAGES_COUNT = 5;

export const UserResearch = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const items = ['svg/user-research/check.svg', 'svg/user-research/cross.svg'];
    const pageImages = useBackgroundImageService(items);
    const isDesktop = useDesktopMediaWatcher();

    const mobileTextVersionAdditionToKey = !isDesktop ? '-mobile' : '';

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_comparison_screen, '1');
    }, []);

    return (
        <>
            <Heading title={t('user-research-title')} subtitle={t('user-research-subtitle')} />

            {/* Table */}
            <div
                className={`flex justify-center items-center mt-0 mx-auto mb-2 md:max-w-[453px] md:mb-4 dark:bg-content1 text-foreground
                [&>div:nth-child(2)>div:nth-child(5)]:min-h-16
                [&>div:nth-child(3)>div:nth-child(5)]:min-h-16
                md:[&>div:nth-child(2)>div:nth-child(5)]:min-h-[74px]
                md:[&>div:nth-child(3)>div:nth-child(5)]:min-h-[74px]
                md:[&>div:nth-child(2)>div:nth-child(4)]:min-h-[110px]
                md:[&>div:nth-child(3)>div:nth-child(4)]:min-h-[110px]
                `}
            >
                {/* column */}
                <div
                    className={`w-full min-w-[136px] md:min-w-[173px] h-full flex flex-col justify-center items-center self-stretch
                    [&>div]:w-full
                    [&>div]:h-full
                    [&>div]:flex
                    [&>div]:justify-center
                    [&>div]:items-center
                    [&>div]:py-2
                    [&>div]:px-4
                    [&>div]:min-h-12
                    md:[&>div]:min-h-[74px]
                    md:[&>div]:py-[10px]
                    md:[&>div]:px-5
                    [&_h3]:!m-0
                    md:[&_h3]:!text-base
                    [&_p]:!text-xs
                    [&_p]:!font-normal
                    [&_p]:!text-left
                    [&_p]:!m-0
                    md:[&_p]:!text-sm
                    md:[&_p]:!leading-[18px]
                    `}
                >
                    {/* For displaying an empty column cell */}
                    <div className="bg-background !min-h-[52px] md:!min-h-[68px]"></div>
                    <div className="even:bg-[#E8F5FD] dark:even:bg-blue-900/40">
                        <p>
                            <Trans
                                i18nKey={`user-research-table-item-1${mobileTextVersionAdditionToKey}`}
                            />
                        </p>
                    </div>
                    <div className="even:bg-[#E8F5FD] dark:even:bg-blue-900/40">
                        <p>
                            <Trans
                                i18nKey={`user-research-table-item-2${mobileTextVersionAdditionToKey}`}
                            />
                        </p>
                    </div>
                    <div className="even:bg-[#E8F5FD] dark:even:bg-blue-900/40">
                        <p>
                            <Trans
                                i18nKey={`user-research-table-item-3${mobileTextVersionAdditionToKey}`}
                            />
                        </p>
                    </div>
                    <div className="even:bg-[#E8F5FD] dark:even:bg-blue-900/40">
                        <p>
                            <Trans
                                i18nKey={`user-research-table-item-4${mobileTextVersionAdditionToKey}`}
                            />
                        </p>
                    </div>
                    <div className="even:bg-[#E8F5FD] dark:even:bg-blue-900/40">
                        <p>
                            <Trans
                                i18nKey={`user-research-table-item-5${mobileTextVersionAdditionToKey}`}
                            />
                        </p>
                    </div>
                </div>
                {/* column */}
                <div
                    className={`w-full h-full flex flex-col justify-center items-center self-stretch dark:bg-content2
                    [&>div]:w-full
                    [&>div]:h-full
                    [&>div]:min-h-12
                    [&>div]:flex
                    [&>div]:justify-center
                    [&>div]:items-center
                    [&>div]:py-2
                    [&>div]:px-4
                    md:[&>div]:min-h-[74px]
                    md:[&>div]:py-[10px]
                    md:[&>div]:px-5
                    [&_h3]:!m-0
                    md:[&_h3]:!text-base
                    [&_p]:!text-xs
                    [&_p]:!font-normal
                    [&_p]:!text-left
                    [&_p]:!m-0
                    md:[&_p]:!text-sm
                    md:[&_p]:!leading-[18px]
                    `}
                >
                    <div className="!min-h-[52px] md:!min-h-[68px]">
                        <h3>
                            <Trans i18nKey="user-research-able-column-title" />
                        </h3>
                    </div>
                    {[...Array(IMAGES_COUNT)].map((_, index) => (
                        <div
                            key={index}
                            className="last:shadow-[4px_4px_6px_0px_rgba(182,182,182,0.25)]
                            dark:last:shadow-[4px_4px_6px_0px_rgba(16,16,16,0.25)]"
                        >
                            <img src={pageImages[0]} alt="Check Icon" />
                        </div>
                    ))}
                </div>

                {/* column */}
                <div
                    className={`w-full h-full flex flex-col justify-center items-center self-stretch
                    [&>div]:w-full
                    [&>div]:h-full
                    [&>div]:min-h-12
                    [&>div]:flex
                    [&>div]:justify-center
                    [&>div]:items-center
                    [&>div]:py-2
                    [&>div]:px-4
                    md:[&>div]:min-h-[74px]
                    md:[&>div]:py-[10px]
                    md:[&>div]:px-5
                    [&_h3]:!m-0
                    md:[&_h3]:!text-base
                    [&_p]:!text-xs
                    [&_p]:!font-normal
                    [&_p]:!text-left
                    [&_p]:!m-0
                    md:[&_p]:!text-sm
                    md:[&_p]:!leading-[18px]
                    `}
                >
                    <div className="!min-h-[52px] md:!min-h-[68px]">
                        <h3>
                            <Trans
                                i18nKey="user-research-other-programs-column-title"
                                components={[<br />]}
                            />
                        </h3>
                    </div>
                    {[...Array(IMAGES_COUNT)].map((_, index) => (
                        <div key={index} className="even:bg-[#E8F5FD] dark:even:bg-blue-900/40">
                            <img src={pageImages[1]} alt="Cross Icon" />
                        </div>
                    ))}
                </div>
            </div>

            <NextButton typographyText={t('user-research-button-next')} onClick={handleClick} />
        </>
    );
};
