import closeIcon from './close_icon.svg';

import { cn } from 'helpers/cn';

import {
    Modal as NextUIModal,
    ModalContent as NextUIModalContent,
    ModalProps as NextUIModalProps,
} from '@nextui-org/react';

interface ModalWindowProps extends NextUIModalProps {
    handleClose?: () => void;
    wrapperClassName?: string;
    modalWindowFormClassName?: string;
    hasCloseIcon?: boolean;
    children: any;
}

export const ModalWindow = ({
    handleClose,
    wrapperClassName,
    modalWindowFormClassName,
    hasCloseIcon = true, // todo check logic when needed
    backdrop = 'transparent',
    isOpen,
    children,
}: ModalWindowProps) => {
    const handleOnClose = () => {
        if (handleClose) {
            handleClose();
        }
    };

    return (
        // <div
        //     style={{ height: '100%' }}
        //     className={cn(
        //         `flex justify-center flex-col items-center fixed top-0 left-0 right-0 bottom-0 bg-black/80 z-[201] w-full`,
        //         wrapperClassName
        //     )}
        // >
        //     <div
        //         className={`mx-2 max-w-[359px] max-h-[90%] overflow-y-auto relative bg-white rounded-2xl pt-12 px-6 pb-8 overflow-x-hidden max-[280px]:max-w-[250px] [&_iframe]:!h-12 ${
        //             modalWindowFormClassName || ''
        //         }`}
        //     >
        //         {handleClose && hasCloseIcon && (
        //             <img
        //                 className={'absolute top-4 right-[26px] cursor-pointer'}
        //                 src={closeIcon}
        //                 onClick={handleOnClose}
        //                 alt=""
        //             />
        //         )}
        //         {children}
        //     </div>
        // </div>

        <NextUIModal
            backdrop={backdrop}
            isOpen={isOpen}
            hideCloseButton={!handleClose && !hasCloseIcon}
            onClose={handleOnClose}
            className={`mx-2 max-w-[359px] max-h-[90%] overflow-y-auto relative rounded-2xl pt-12 px-6 pb-8 overflow-x-hidden max-[280px]:max-w-[250px] [&_iframe]:!h-12 ${
                modalWindowFormClassName || ''
            }`}
        >
            <NextUIModalContent>{children}</NextUIModalContent>
        </NextUIModal>
    );
};
