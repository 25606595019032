import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { RoutePath } from './route-path.constant';

import { BodyGender } from 'pages/body-gender';
import { LoseWeight } from 'pages/lose-weight';
import { FamilyWeight } from 'pages/family-weight';
import { FamilyWeightTip } from 'pages/family-weight-tip';
import { PastAttempts } from 'pages/past-attempts';
import { WeightLoss } from 'pages/weight-loss';
import { LoseWeightReason } from 'pages/lose-weight-reason';
import { WeightFactors } from 'pages/weight-factors';
import { PrescriptionMedications } from 'pages/prescription-medications';
import { PrescriptionMedicationsTip } from 'pages/prescription-medications-tip';
import { BodyHeight } from 'pages/body-height';
import { BodyWeight } from 'pages/body-weight';
import { BodyIdealWeight } from 'pages/body-ideal-weight';
import { Email } from 'pages/email';
import { DeclineUser } from 'pages/decline-user';
import { MedicalCondition } from 'pages/medical-condition';
import { HealthCondition } from 'pages/health-condition';
import { MedicationIntake } from 'pages/medication-intake';
import { Consent } from 'pages/consent';
import { AnalyzeResponses } from 'pages/analyze-responses';
import { PrePaywall } from 'pages/pre-paywall';
import { Checkout } from 'pages/checkout';
import { Medication } from 'pages/medication';
import { ShippingAddressEmbedded } from 'pages/shipping-address-embedded';
import { Congrats } from 'pages/congrats';
import { Welcome } from 'pages/welcome';
import { NotFound } from 'pages/not-found';
import { PlanLoader } from 'pages/plan-loader';
import { ScientificProof } from 'pages/scientific-proof';
import { UserResearch } from 'pages/user-research';
import { BeforeAfterMedication } from 'pages/before-after-medication';
import { WeightMetabolicRate } from 'pages/weight-metabolic-rate';
import { ImportantEvent } from 'pages/important-event';
import { ImportantEventDate } from 'pages/important-event-date';
import { HowSemaWorks } from 'pages/how-sema-works';
import { WeightLossGoal } from 'pages/weight-loss-goal';
import { MainPage } from 'pages/main';
import { Example } from 'pages/example';

export const RouterConfig = () => {
    return (
        <Routes>
            {/* List all public routes here */}
            <Route path={'/example'} element={<Example />} />
            <Route path={RoutePath.Main} element={<MainPage />} />
            <Route path={RoutePath.Goal} element={<WeightLossGoal />} />
            <Route path={RoutePath.BodyGender} element={<BodyGender />} />
            <Route path={RoutePath.LoseWeight} element={<LoseWeight />} />
            <Route path={RoutePath.FamilyWeight} element={<FamilyWeight />} />
            <Route path={RoutePath.FamilyWeightTip} element={<FamilyWeightTip />} />
            <Route path={RoutePath.PastAttempts} element={<PastAttempts />} />
            <Route path={RoutePath.WeightLoss} element={<WeightLoss />} />
            <Route path={RoutePath.LoseWeightReason} element={<LoseWeightReason />} />
            <Route path={RoutePath.WeightFactors} element={<WeightFactors />} />
            <Route path={RoutePath.PrescriptionMedications} element={<PrescriptionMedications />} />
            <Route path={RoutePath.PrescriptionMedicationsTip} element={<PrescriptionMedicationsTip />} />
            <Route path={RoutePath.BodyHeight} element={<BodyHeight />} />
            <Route path={RoutePath.BodyWeight} element={<BodyWeight />} />
            <Route path={RoutePath.WeightMetabolicRate} element={<WeightMetabolicRate />} />
            <Route path={RoutePath.BodyIdealWeight} element={<BodyIdealWeight />} />
            <Route path={RoutePath.BeforeAfterMedication} element={<BeforeAfterMedication />} />
            <Route path={RoutePath.HealthCondition} element={<HealthCondition />} />
            <Route path={RoutePath.MedicalCondition} element={<MedicalCondition />} />
            <Route path={RoutePath.MedicationIntake} element={<MedicationIntake />} />
            <Route path={RoutePath.HowSemaWorks} element={<HowSemaWorks />} />
            <Route path={RoutePath.UserResearch} element={<UserResearch />} />
            <Route path={RoutePath.Consent} element={<Consent />} />
            <Route path={RoutePath.ImportantEvent} element={<ImportantEvent />} />
            <Route path={RoutePath.ImportantEventDate} element={<ImportantEventDate />} />
            <Route path={RoutePath.ScientificProof} element={<ScientificProof />} />
            <Route path={RoutePath.Email} element={<Email />} />
            <Route path={RoutePath.PlanLoader} element={<PlanLoader />} />
            <Route path={RoutePath.AnalyzeResponses} element={<AnalyzeResponses />} />
            <Route path={RoutePath.PrePaywall} element={<PrePaywall />} />
            <Route path={RoutePath.Medication} element={<Medication />} />
            <Route path={RoutePath.Checkout} element={<Checkout />} />
            <Route path={RoutePath.Congrats} element={<Congrats />} />
            <Route path={RoutePath.Welcome} element={<Welcome />} />

            <Route path={RoutePath.DeclineUser} element={<DeclineUser />} />

            {/* // Page for opening in WebView */}
            <Route path={RoutePath.ShippingAddressEmbedded} element={<ShippingAddressEmbedded />} />

            {/* List a generic 404-Not Found route here */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
