// medications.svg was updated for supporting theme
import { ReactComponent as Medications } from 'assets/images/svg/reinforcement-icons/medications.svg';
import { t } from 'i18next';
import { TipPage } from 'ncomponents/Pages/TipPage';
import { Trans } from 'react-i18next';

export const PrescriptionMedicationsTip = () => {
    return (
        <TipPage
            imgSrc={<Medications />}
            text={{
                description: (
                    <Trans
                        i18nKey="prescription-medications-tip-description"
                        components={[
                            <strong className={'inline-block -mt-6 mx-0 mb-3 md:mb-6'}></strong>,
                            <strong></strong>,
                            <ul
                                className={
                                    'list-disc list-outside mt-2 mr-0 mb-0 ml-[25px] md:mt-6 md:ml-[25px]'
                                }
                            >
                                {[''].map((item) => (
                                    <li
                                        key={item}
                                        className="mb-2 marker:text-[15px] align-top md:last-of-type:mb-14"
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>,
                        ]}
                    />
                ),
                next: t('prescription-medications-tip-button-next'),
            }}
        />
    );
};
