import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReviewsIOWidget, SwitchingTitle } from '@ruby-labs/ui-core-kit';
import { LinearProgressWithTitleAndLabel } from 'ncomponents/LinearProgressWithTitleAndLabel';

import { pageConfiguration } from 'containers/index';

import { ModalContent, PopupData } from 'pages/plan-loader/modal-content';
import { planLoaderData } from 'pages/plan-loader/plan-loader-data';

import { ABLE_STORE } from 'constants/able-store';
import { MAX_PERCENTS } from 'constants/plan-loader';

// import logoBlack from 'assets/images/svg/logo-black.svg';
import { Modal as NextUIModal, ModalContent as NextUIModalContent } from '@nextui-org/react';
import { ReactComponent as AbleLogo } from 'assets/images/svg/logo-black2.svg';
import { ExperimentsKeys } from 'constants/experiments';
import { useAppState } from 'context/AppContext';

export const PlanLoader = () => {
    const { t } = useTranslation();

    // todo ui demo note to enable correct header layout if navigate directly
    const { setUser } = useAppState();
    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_plan_loader_screen, '1');
        setUser((prevUser) => ({
            ...prevUser,
            experiments: [...prevUser.experiments, ExperimentsKeys.mw_plan_loader_screen],
        }));
    }, []);

    const [activeBar, setActiveBar] = useState(0);
    const [percents, setPercents] = useState(planLoaderData.map(() => 0));
    const [step, setStep] = useState<number>(0);
    const [activeProgress, toggleActiveProgress] = useState(true);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | undefined = undefined;

        if (activeProgress) {
            clearInterval(interval);

            const { titles } = planLoaderData[activeBar];

            const { time } = titles[step];

            interval = setInterval(() => {
                if (percents[activeBar] === MAX_PERCENTS) {
                    if (activeBar === planLoaderData.length - 1) {
                        clearInterval(interval);

                        return;
                    }

                    setActiveBar(activeBar + 1);

                    return;
                }

                percents[activeBar]++;

                if (
                    percents[activeBar] === titles[step].range[1] &&
                    [2, 3].includes(titles.length)
                ) {
                    setStep((step + 1) % titles.length);
                }

                setPercents([...percents]);

                if (percents[activeBar] === planLoaderData[activeBar].popUpTime) {
                    toggleActiveProgress(false);
                }

                if (percents[planLoaderData.length - 1] === MAX_PERCENTS) {
                    pageConfiguration.handleOnPageComplete();
                }
            }, time / (titles[step].range[1] - titles[step].range[0]));
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [activeBar, activeProgress, percents]);

    return (
        <div
            className={
                'flex flex-col items-center justify-between gap-10 absolute w-screen h-full p-6 bg-[#F6F6F6] dark:bg-content1 text-foreground overflow-x-hidden'
            }
        >
            <div className={'flex items-center justify-center flex-col w-full max-w-[560px]'}>
                {/* <img src={logoBlack} alt="Medication Lose Weight Logo" /> */}
                <AbleLogo />
                <h1 className={'!text-xl md:!mt-8 mb-4'}>
                    <Trans i18nKey="plan-loader-title" />
                </h1>
                <div className={'w-full flex gap-10 flex-col items-center'}>
                    <SwitchingTitle
                        titles={planLoaderData[activeBar].titles.map((title) => ({
                            ...title,
                            value: t(title.value),
                        }))}
                        percents={percents[activeBar]}
                    />
                    <LinearProgressWithTitleAndLabel
                        value={percents[0]}
                        label={t(
                            'plan-loader-thin-loading-bar-with-title-and-checkbox-body-parameters-title'
                        )}
                        // theme.extend.colors
                        // indicatorThemeColor="progressBody"
                        // labelBgThemeColor="progressBodySecondary"
                        checkmarkBgColor="bg-progressBodySecondary"
                        checkmarkColor="text-progressBody"
                        indicatorBgColor="bg-progressBody"
                    />
                    <LinearProgressWithTitleAndLabel
                        value={percents[1]}
                        label={t(
                            'plan-loader-thin-loading-bar-with-title-and-checkbox-motivation-and-psychology-title'
                        )}
                        // theme.extend.colors
                        // indicatorThemeColor="progressHabit"
                        // labelBgThemeColor="progressHabitSecondary"
                        checkmarkBgColor="bg-progressHabitSecondary"
                        checkmarkColor="text-progressHabit"
                        indicatorBgColor="bg-progressHabit"
                    />
                    <LinearProgressWithTitleAndLabel
                        value={percents[2]}
                        label={t(
                            'plan-loader-thin-loading-bar-with-title-and-checkbox-medical-conditions-title'
                        )}
                        // theme.extend.colors
                        // indicatorThemeColor="progressNutrition"
                        // labelBgThemeColor="progressNutritionSecondary"
                        checkmarkBgColor="bg-progressNutritionSecondary"
                        checkmarkColor="text-progressNutrition"
                        indicatorBgColor="bg-progressNutrition"
                    />
                </div>
            </div>

            {/* {!activeProgress && planLoaderData[activeBar].popUpData && (
                <ModalWindow
                    modalWindowFormClassName={'!p-6 my-0 mx-8 dark:bg-content1'}
                    hasCloseIcon={false}
                >
                    <ModalContent
                        onChosen={() => toggleActiveProgress(true)}
                        data={planLoaderData[activeBar].popUpData as PopupData}
                    />
                </ModalWindow>
            )} */}

            <NextUIModal
                isOpen={Boolean(!activeProgress && planLoaderData[activeBar].popUpData)}
                hideCloseButton={true}
            >
                <NextUIModalContent className="p-6 mx-8 max-w-[359px]">
                    <ModalContent
                        onChosen={() => toggleActiveProgress(true)}
                        data={planLoaderData[activeBar].popUpData as PopupData}
                    />
                </NextUIModalContent>
            </NextUIModal>

            <div className="dark:bg-[#F6F6F6]/40 dark:pt-4 flex justify-center">
                <ReviewsIOWidget store={ABLE_STORE} />
            </div>
        </div>
    );
};
