import { FC } from 'react';

import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { cn } from 'helpers/cn';
import { BreadcrumbItem, Breadcrumbs } from '@nextui-org/react';

interface BreadcrumbsNavigationProps {
    activeSection: string;
}

export const BreadcrumbsNavigation: FC<BreadcrumbsNavigationProps> = ({ activeSection }) => {
    const items = ['svg/checkout/breadcrumbs-navigation/navigation-icon.svg'];
    const pageImages = useBackgroundImageService(items);

    const sections = [
        BreadcrumbsNavigationKeys.quiz,
        BreadcrumbsNavigationKeys.healthPlan,
        BreadcrumbsNavigationKeys.account,
        BreadcrumbsNavigationKeys.checkout,
    ];

    return (
        <nav>
            <ol className={'flex items-center mb-6 md:mb-10'}>
                {sections.map((section) => (
                    <li className={'flex [&:last-of-type_img]:hidden'} key={section}>
                        <h3
                            className={cn('text-[#808080]', {
                                'text-foreground': activeSection === section,
                            })}
                        >
                            {section}
                        </h3>
                        <img src={pageImages[0]} alt="Arrow Icon" className="mx-[6px]" />
                    </li>
                ))}
            </ol>
            {/* <Breadcrumbs size="md" className="mb-6 md:mb-10">
                {sections.map((section) => (
                    <BreadcrumbItem key={section} isCurrent={activeSection === section}>
                        {section}
                    </BreadcrumbItem>
                ))}
            </Breadcrumbs> */}
        </nav>
    );
};
