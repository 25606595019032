import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import fish_boxes from 'assets/images/webp/fish_boxes.webp';
import fish_boxes_mobile from 'assets/images/webp/fish_boxes_mobile.webp';
import logo from 'assets/images/svg/logo-black.svg';
import { ISingleChoiceButtonOption, SingleChoice } from 'ncomponents/SingleChoiceButton';
import { Heading } from 'ncomponents/Heading';

export const WeightLossGoal = () => {
    const { t } = useTranslation();
    const isDesktop = useDesktopMediaWatcher();
    const saveAnswers = useSaveAnswers();
    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.Goal];

    const { pageValue: weightLossGoal, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    // todo recheck logic for label and value object between single vs multichoice buttons
    const handleClick = (goal: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('weight-loss-goal-title'), value: t(goal.value) },
        });
        // setPageValue(goal.key);
        // pageConfiguration.handleOnPageComplete(goal.key);
        setPageValue(goal.value); // todo check value if should store object or string with new value & label object
        pageConfiguration.handleOnPageComplete(goal.value);
    };

    const goals: ISingleChoiceButtonOption[] = [
        {
            value: 'lose-under-twenty-lbs',
            label: t('weight-loss-goal-option-lose-under-twenty-lbs'),
        },
        { value: 'lose-under-fifty-lbs', label: t('weight-loss-goal-option-lose-under-fifty-lbs') },
        { value: 'lose-over-fifty-lbs', label: t('weight-loss-goal-option-lose-over-fifty-lbs') },
        { value: 'maintain-weight', label: t('weight-loss-goal-option-maintain-weight') },
        { value: 'not-sure', label: t('weight-loss-goal-option-not-sure') },
    ];

    return (
        <>
            <img className={'w-full'} src={isDesktop ? fish_boxes : fish_boxes_mobile} alt="Fish" />
            <div className={'flex flex-col justify-center items-center py-0 px-4 md:p-0'}>
                <img className={'mt-8 -mb-4 md:mt-20 md:-mb-10'} src={logo} alt="Logo" />

                <div className={'w-full max-w-[560px]'}>
                    <Heading title={t('weight-loss-goal-title')} />

                    <SingleChoice
                        options={goals}
                        selectedOption={weightLossGoal}
                        onSelect={handleClick}
                    />
                </div>
            </div>
        </>
    );
};
