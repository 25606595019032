import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { Divider } from '@nextui-org/react';
import { CheckPointsList } from 'components/check-points-list';
import { useTimer } from 'hooks/use-timer.hook';
import Accordion from 'ncomponents/Accordion';
import { DiscountBlockWithTimer } from 'ncomponents/DiscountBlockWithTimer';
import { Input } from 'ncomponents/Input';
import { RatingType } from 'ncomponents/RatingButton';
import { RatingScale } from 'ncomponents/RatingScale';
import { Button } from '../../ncomponents/Button';
import { Heading } from '../../ncomponents/Heading';
import { IMultiChoiceButtonOption, MultiChoice } from '../../ncomponents/MultiChoiceButton';
import { NextButton } from '../../ncomponents/NextButton';
import { ISingleChoiceButtonOption, SingleChoice } from '../../ncomponents/SingleChoiceButton';
import { TextAreaInput } from '../../ncomponents/TextAreaInput';

export const Example = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue: age, setPageValue: setAge }: any = useLocalStorage({
        key: 'age',
        defaultValue: '',
    });

    // const handleClick = (gender: ChoiceType) => {
    //     saveAnswers({
    //         key: pageKey,
    //         itemQuestion: { question: t('body-gender-title'), value: t(gender.value) },
    //     });
    //     setPageValue(gender.key);

    //     pageConfiguration.handleOnPageComplete(gender.key);
    // };

    // const genders: ChoiceType[] = [
    //     {
    //         key: 'female',
    //         value: t('body-gender-option-female'),
    //         image: 'svg/body-gender/female-gender.svg',
    //     },
    //     {
    //         key: 'male',
    //         value: t('body-gender-option-male'),
    //         image: 'svg/body-gender/male-gender.svg',
    //     },
    // ];

    const handleAgeClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: 'age',
            itemQuestion: {
                question: t('body-age-title'),
                value: option.value,
            },
        });
        setAge(option);
    };

    const [multiBodyAge, setMultiBodyAge] = useState<IMultiChoiceButtonOption[]>([]);

    const [area, setArea] = useState('');
    const [userAnswer, setUserAnswer] = useState('');

    const AGES = [
        { value: 'underTwenty', label: 'body-age-option-underTwenty' },
        { value: 'twentys', label: 'body-age-option-twenties' },
        { value: 'thirtys', label: 'body-age-option-thirties' },
        { value: 'fortys', label: 'body-age-option-forties' },
        { value: 'fiftys', label: 'body-age-option-fifties' },
        { value: 'sixtyPlus', label: 'body-age-option-sixtyPlus', image: '' },
        {
            value: 'other',
            label: 'body-age-option-other',
            placeholder: 'Enter answer',
            userAnswer,
            setUserAnswer,
        },
    ];

    const [inputValue, setInputValue] = useState('');

    const items1 = [
        {
            id: 1,
            title: t('medication-faq-item-1-title'),
            description: t('medication-faq-item-1-description'),
        },
        {
            id: 2,
            title: t('medication-faq-item-2-title'),
            description: t('medication-faq-item-2-description'),
        },
        {
            id: 3,
            title: t('medication-faq-item-3-title'),
            description: t('medication-faq-item-3-description'),
        },
        {
            id: 4,
            title: t('medication-faq-item-4-title'),
            description: t('medication-faq-item-4-description'),
        },
    ];

    const items2 = [
        {
            id: 1,
            title: t('medication-expectations-item-1-title'),
            subtitle: t('medication-expectations-item-1-subtitle'),
            items: [
                t('medication-expectations-item-1-option-1'),
                t('medication-expectations-item-1-option-2'),
                t('medication-expectations-item-1-option-3'),
            ],
        },
        {
            id: 2,
            title: t('medication-expectations-item-2-title'),
            subtitle: t('medication-expectations-item-2-subtitle'),
            items: [
                t('medication-expectations-item-2-option-1'),
                t('medication-expectations-item-2-option-2'),
                t('medication-expectations-item-2-option-3'),
            ],
        },
        {
            id: 3,
            title: t('medication-expectations-item-3-title'),
            subtitle: t('medication-expectations-item-3-subtitle'),
            items: [
                t('medication-expectations-item-3-option-1'),
                t('medication-expectations-item-3-option-2'),
                t('medication-expectations-item-3-option-3'),
            ],
        },
        {
            id: 4,
            title: t('medication-expectations-item-4-title'),
            subtitle: t('medication-expectations-item-4-subtitle'),
            items: [
                t('medication-expectations-item-4-option-1'),
                t('medication-expectations-item-4-option-2'),
                t('medication-expectations-item-4-option-3'),
                t('medication-expectations-item-4-option-4'),
            ],
        },
    ];

    const DetailComponent = ({ subtitle, items, description }: any) => {
        return (
            <div className="">
                {subtitle && (
                    <h2
                        className={`font-semibold leading-[22px] text-[#1375D6] text-left mt-0 md:text-2xl md:leading-[34px] md:mb-6`}
                    >
                        {subtitle}
                    </h2>
                )}

                {items && (
                    <CheckPointsList
                        items={items}
                        listItemClassName={'flex gap-3 items-start mb-4 md:mb-6 last-of-type:mb-0'}
                        listItemTextClassName={
                            '!text-base !m-0 text-start md:!text-xl md:!leading-[30px]'
                        }
                    />
                )}

                {description && (
                    <h3
                        className={
                            'text-base text-left mt-4 whitespace-pre-line md:text-xl md:leading-[30px] md:mt-6'
                        }
                    >
                        {description}
                    </h3>
                )}
            </div>
        );
    };

    // Rating scale
    const [scaleValue, setScaleValue] = useState('');
    const handleRatingBtnClick = (rating: RatingType) => {
        setScaleValue(rating.value);
    };

    // Timer example
    const { renderMinutes, renderSeconds } = useTimer({
        initialMinutes: 14,
        initialSeconds: 59,
    });

    return (
        <>
            <Heading title={'Test title'} subtitle={'Test subtitle'} />

            <Button>Test button</Button>

            <DiscountBlockWithTimer
                title="Applied limited discount"
                discountValue="77% off"
                discountCoupon="Newcomer"
                discountTimerTitle="Applicable for"
                timerMinutes={renderMinutes}
                timerSeconds={renderSeconds}
            />

            <Divider className="my-8" />

            <SingleChoice options={AGES} selectedOption={age} onSelect={handleAgeClick} />

            <Divider className="my-8" />

            <MultiChoice
                options={AGES}
                deselectWhenValues={['sixtyPlus']}
                disabledWhenValues={['sixtyPlus']}
                selectedOptions={multiBodyAge}
                onSelect={setMultiBodyAge}
            />

            <Divider className="my-8" />

            <TextAreaInput
                label={'Please share'}
                value={area}
                maxLength={1000}
                onValueChange={setArea}
                classNames={{
                    description: 'text-right',
                }}
            />

            <div className="flex flex-col gap-3 md:gap-4 my-4">
                <Input
                    label="Input"
                    value={inputValue}
                    // onValueChange={setInputValue}
                />
                {/* <Input
                    label="Input"
                    value={inputValue}
                    isInvalid={true}
                    errorMessage="Error message"
                /> */}
            </div>

            <Divider className="my-8" />

            <Accordion items={items2} DetailComponent={DetailComponent} />

            <NextButton />

            <RatingScale
                value={scaleValue}
                handleClick={handleRatingBtnClick}
                ratingGradationInfo={['Strongly Disagree', 'Strongly Agree']}
            />
        </>
    );
};
