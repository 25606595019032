import { ExperimentsKeys } from 'constants/experiments';
import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from 'react';

export const experiments = Object.values(ExperimentsKeys);
type ExperimentsKeys = typeof ExperimentsKeys;
export type Experiment = ExperimentsKeys[keyof ExperimentsKeys];
type ExperimentKey = keyof typeof ExperimentsKeys;

export const enabledExperiments = Object.keys(ExperimentsKeys).filter(
    (key) =>
        localStorage.getItem(ExperimentsKeys[key as ExperimentKey]) !== '0' &&
        localStorage.getItem(ExperimentsKeys[key as ExperimentKey]) !== null
);

type User = {
    experiments: Experiment[];
    isControlPanelOpen: boolean;
    mw_how_sema_works: string;
};

type AppContextProps = {
    user: User;
    setUser: Dispatch<SetStateAction<User>>;
};

const AppContext = createContext<AppContextProps | undefined>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppProvider: FC<AppStateProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User>({
        experiments: enabledExperiments,
        isControlPanelOpen: false,
        mw_how_sema_works: '1',
    });

    return <AppContext.Provider value={{ user, setUser }}>{children}</AppContext.Provider>;
};

export const useAppState = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};
