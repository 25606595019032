import { DEFAULT_PAGES_CONFIGURATION } from 'services/default-pages-configuration.service';
import { RoutePath } from 'routes/route-path.constant';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

export const ABPagesConfiguration = () => {
    let finalConfiguration = Object.assign({}, DEFAULT_PAGES_CONFIGURATION);
    const mw_plan_loader_screen = localStorage.getItem(ExperimentsKeys.mw_plan_loader_screen);
    const mw_comparison_screen = localStorage.getItem(ExperimentsKeys.mw_comparison_screen);
    const mw_weight_metabolic_rate = localStorage.getItem(ExperimentsKeys.mw_weight_metabolic_rate);
    const mw_important_date = localStorage.getItem(ExperimentsKeys.mw_important_date);
    const mw_email_screen_removal = localStorage.getItem(ExperimentsKeys.mw_email_screen_removal);
    const mw_how_sema_works = localStorage.getItem(ExperimentsKeys.mw_how_sema_works);

    if (mw_plan_loader_screen === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.Email]: {
                ...finalConfiguration[RoutePath.Email],
                route: {
                    next: (data: any) => {
                        if (data?.isUserExists) {
                            handleRedirectToPwa();
                        }

                        return RoutePath.PlanLoader;
                    },
                },
            },
            // todo ui design note routes & layout based on experiment
            [RoutePath.PlanLoader]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.PrePaywall,
                },
                events: {
                    onLeave: () => 'RxWeightAnalyzeResponsesCompleted',
                },
                // requiredKeys: [
                // LocalStorageKeys[RoutePath.BodyGender],
                // LocalStorageKeys[RoutePath.LoseWeightReason],
                // MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                // MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                // MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                // LocalStorageKeys[RoutePath.BodyIdealWeight],
                // LocalStorageKeys[RoutePath.Email],
                // ],
            },
        });
    }

    if (mw_comparison_screen === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.MedicationIntake]: {
                ...finalConfiguration[RoutePath.MedicationIntake],
                route: {
                    next: () => RoutePath.UserResearch,
                },
            },
            [RoutePath.UserResearch]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.Consent,
                },
                events: {
                    onEnter: () => 'RxWeightComparisonViewed',
                    onLeave: () => 'RxWeightComparisonClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyGender],
                    LocalStorageKeys[RoutePath.LoseWeightReason],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                    LocalStorageKeys[RoutePath.BodyIdealWeight],
                ],
            },
        });
    }

    if (mw_weight_metabolic_rate === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.BodyWeight]: {
                ...finalConfiguration[RoutePath.BodyWeight],
                route: {
                    next: () => RoutePath.WeightMetabolicRate,
                },
            },
            [RoutePath.WeightMetabolicRate]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.BodyIdealWeight,
                },
                events: {
                    onEnter: () => 'RxWeightMetabolicRateViewed',
                    onLeave: () => 'RxWeightMetabolicRateClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyGender],
                    LocalStorageKeys[RoutePath.LoseWeightReason],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                ],
            },
        });
    }

    if (mw_important_date === '1') {
        const requiredKeys = [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ];

        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.ScientificProof]: {
                ...finalConfiguration[RoutePath.ScientificProof],
                route: {
                    next: () => RoutePath.ImportantEvent,
                },
            },
            [RoutePath.ImportantEvent]: {
                customWrapper: false,
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 77,
                        label: '',
                    },
                },
                route: {
                    next: (data: any) => {
                        if (data.key !== 'none') {
                            return RoutePath.ImportantEventDate;
                        } else {
                            return RoutePath.AnalyzeResponses;
                        }
                    },
                },
                events: {
                    onLeave: () => 'RxWeightImportantEventClicked',
                },
                requiredKeys: requiredKeys,
            },
            [RoutePath.ImportantEventDate]: {
                customWrapper: false,
                header: {
                    email: false,
                    logo: true,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.AnalyzeResponses,
                },
                events: {},
                requiredKeys: requiredKeys,
            },
        });
    }

    if (mw_email_screen_removal === '1') {
        const requiredKeys = [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ];

        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.AnalyzeResponses]: {
                ...finalConfiguration[RoutePath.AnalyzeResponses],
                route: {
                    next: () => RoutePath.PrePaywall,
                },
            },
            [RoutePath.PrePaywall]: {
                ...finalConfiguration[RoutePath.PrePaywall],
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                requiredKeys: requiredKeys,
            },
            [RoutePath.Medication]: {
                ...finalConfiguration[RoutePath.Medication],
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                requiredKeys: requiredKeys,
            },
            [RoutePath.Checkout]: {
                ...finalConfiguration[RoutePath.Checkout],
                requiredKeys: requiredKeys,
            },
        });
    }

    if (mw_how_sema_works === '1' || mw_how_sema_works === '2') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.MedicationIntake]: {
                ...finalConfiguration[RoutePath.MedicationIntake],
                route: {
                    next: () => RoutePath.HowSemaWorks,
                },
            },
            [RoutePath.HowSemaWorks]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.Consent,
                },
                events: {
                    onEnter: () => 'RxWeightHowSemaWorkViewed',
                    onLeave: () => 'RxWeightHowSemaWorkClicked',
                },
                // todo design note: temp off to allow direct navigation
                // requiredKeys: [
                //     LocalStorageKeys[RoutePath.BodyGender],
                //     LocalStorageKeys[RoutePath.LoseWeightReason],
                //     MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                //     MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                //     MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                //     LocalStorageKeys[RoutePath.BodyIdealWeight],
                // ],
            },
        });
    }

    return finalConfiguration;
};
