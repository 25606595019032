import { FC } from 'react';

interface SingleTimerBlockProps {
    renderTimeUnit: string;
    title?: string;
}

const SingleTimerBlock: FC<SingleTimerBlockProps> = ({ renderTimeUnit, title }) => (
    <div className="w-full flex flex-col justify-center items-center gap-0.5 px-1 py-2 rounded-lg bg-white">
        <span className="text-[#010101] first:text-[26px] first:leading-[26px] first:font-semibold">
            {renderTimeUnit}
        </span>
        {title && <span className="text-[#010101] last:text-[11px] last:leading-3">{title}</span>}
    </div>
);

interface IDiscountBlockWithTimerProps {
    title: string;
    discountValue: string;
    discountCoupon: string;
    discountTimerTitle: string;
    timerMinutes: string;
    timerSeconds: string;
    minutesTitle?: string;
    secondsTitle?: string;
}

export const DiscountBlockWithTimer: FC<IDiscountBlockWithTimerProps> = ({
    title,
    discountValue,
    discountCoupon,
    discountTimerTitle,
    timerMinutes,
    timerSeconds,
    minutesTitle = 'minutes',
    secondsTitle = 'seconds',
}) => {
    return (
        <div className="relative flex justify-between items-center max-w-[445px] p-4 rounded-xl bg-[#FFE9D2] max-[376px]:flex-col max-[376px]:items-center max-[376px]:gap-4">
            <div className="flex flex-col items-center gap-2">
                <span className="text-sm leading-[18px] font-semibold">{title}</span>
                <div className="flex flex-col justify-start items-center gap-0 h-14 rounded-lg bg-white">
                    <div className="flex justify-center relative text-white bg-[#EB731A] w-[166px] h-[26px] mb-1.5 px-3 py-1 rounded-lg left-0 after:content-['_'] after:absolute after:border-t-[#EB731A] after:border-[10px] after:border-solid after:border-transparent after:left-[46%] after:top-[90%] md:w-[269px]">
                        <span className="text-sm leading-[14px] font-semibold text-white max-[393px]:left-[37%] max-[375px]:left-[35%] max-[360px]:left-[36%] max-[376px]:left-[38%] max-[376px]:top-[21%] max-[280px]:left-[38%] min-[540px]:left-[36%] md:left-[41%]">
                            {discountValue}
                        </span>
                    </div>
                    <span className="text-lg leading-[26px] font-semibold text-[#010101]">
                        {discountCoupon}
                    </span>
                </div>
            </div>
            <div className="flex flex-col items-center gap-2">
                <span className="text-sm leading-[18px] font-semibold">{discountTimerTitle}</span>
                <div className="flex justify-between gap-2">
                    <SingleTimerBlock renderTimeUnit={timerMinutes} title={minutesTitle} />
                    <SingleTimerBlock renderTimeUnit={timerSeconds} title={secondsTitle} />
                </div>
            </div>
        </div>
    );
};
