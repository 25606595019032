import { FC } from 'react';

import { LinksKeys } from 'constants/links';

export type SecondaryFooterProps = {};

const userPolicyLinksList = (
    <>
        <a href={LinksKeys.TermsAndCondition} target="_blank" rel="noreferrer">
            Terms and Conditions
        </a>
        <a href={LinksKeys.PrivacyPolicy} target="_blank" rel="noreferrer">
            Privacy Policy
        </a>
        <a href={LinksKeys.RefundPolicy} target="_blank" rel="noreferrer">
            Refund Policy
        </a>
    </>
);
const companyAddress = '353 Kearny St, San Francisco, CA 94108';
const companyPhoneNumber = '(646) 810-5749';

export const Footer: FC<SecondaryFooterProps> = (): JSX.Element => {
    return (
        <footer
            className={`relative w-full left-0 right-0 bottom-0 bg-[#F6F6F6] border-t border-solid border-[#C5C5D1] dark:bg-content1 dark:border-t-divider `}
        >
            <div
                className={
                    'w-[85%] max-w-[406px] flex flex-col items-center text-xs leading-[18px] tracking-[-0.2px] p-4 mx-auto text-[#010101] max-[280px]:text-[10px] max-[375px]:w-[95%] max-[320px]:w-full md:w-[80%] md:mx-auto md:py-8 md:px-0 md:text-sm md:leading-[21px]'
                }
            >
                <div
                    className={`w-full text-center whitespace-nowrap md:w-auto dark:text-foreground`}
                >
                    <div className={'flex flex-col justify-center md:justify-between md:flex-row'}>
                        <span>{companyAddress}</span>
                        {','}&nbsp;
                        <span>{companyPhoneNumber}</span>
                    </div>
                    <div
                        className={`w-full flex justify-between mt-2 md:mt-3 dark:[&>a]:text-foreground [&_a]:underline [&_a]:underline-offset-4`}
                    >
                        {userPolicyLinksList}
                    </div>
                </div>
            </div>
        </footer>
    );
};
