import React from 'react';

export interface IHeadingProps {
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    subtitle2?: string | React.ReactNode;
}

export const Heading = ({ title, subtitle, subtitle2 }: IHeadingProps) => {
    return (
        <div className="flex flex-col items-center text-center gap-4 my-8 md:mt-20 md:mb-10 text-foreground">
            {title && <h1 className="my-0">{title}</h1>}
            {subtitle && <h2 className="my-0">{subtitle}</h2>}
            {subtitle2 && <h2>{subtitle2}</h2>}
        </div>
    );
};
