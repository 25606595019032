export const ExperimentsKeys = {
    mw_plan_loader_screen: 'mw_plan_loader_screen',
    mw_comparison_screen: 'mw_comparison_screen',
    mw_timer_on_paywall: 'mw_timer_on_paywall',
    mw_medical_director_changes: 'mw_medical_director_changes',
    mw_subscription_price_variations: 'mw_subscription_price_variations',
    mw_weight_metabolic_rate: 'mw_weight_metabolic_rate',
    mw_important_date: 'mw_important_date',
    mw_email_screen_removal: 'mw_email_screen_removal',
    mw_how_sema_works: 'mw_how_sema_works',
    mw_first_screen: 'mw_first_screen',
    mw_progress_bar_on_checkout_screen: 'mw_progress_bar_on_checkout_screen',
};
