import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@nextui-org/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { MultipleLocalStorageKeys } from 'constants/local-storage';
import { UNIT_SYSTEM } from 'constants/body-height-weight';

import { ExperimentsKeys } from 'constants/experiments';
import { Button } from 'ncomponents/Button';
import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';
import { useAppState } from 'context/AppContext';
import { convertToCm, convertToFtInches } from '../../helpers/unit-converter';

export const BodyHeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const { pageValue: bodyHeightWeightUnitSystem, setPageValue: setBodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: UNIT_SYSTEM.imperial,
        });

    const { pageValue: bodyHeightFt } = useLocalStorage({
        key: bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });

    const formSchema = useMemo(
        () =>
            yup.object().shape({
                unitSystem: yup.string().oneOf([UNIT_SYSTEM.imperial, UNIT_SYSTEM.metric]),
                heightCm: yup.number().when('unitSystem', {
                    is: () => UNIT_SYSTEM.metric,
                    then: () => yup.number().min(60).max(300).required(),
                    otherwise: () => yup.number().notRequired(),
                }),
                ft: yup.number().when('unitSystem', {
                    is: () => UNIT_SYSTEM.imperial,
                    then: () => yup.number().min(1).max(9).required(),
                    otherwise: () => yup.number().notRequired(),
                }),
                inches: yup.number().when('unitSystem', {
                    is: () => UNIT_SYSTEM.imperial,
                    then: () => yup.number().min(0).max(12).notRequired(),
                    otherwise: () => yup.number().notRequired(),
                }),
            }),
        []
    );

    const handleSubmit  = ({ heightCm, ft, inches }: yup.InferType<typeof formSchema>) => {
        const data = {
            unit: bodyHeightWeightUnitSystem,
            height: `${ft}.${inches}`,
            heightCm: `${heightCm}`,
        };

        saveAnswers({
            key: 'body-height',
            itemQuestion: {
                question: t('body-height-title'),
                value: `Height: ${data.height} ft/in or cm ${data.heightCm}`,
            },
        });
        pageConfiguration.handleOnPageComplete(data);
    };

    // const mw_weight_metabolic_rate = true; // ExperimentsKeys.mw_weight_metabolic_rate
    const { user } = useAppState();
    const mw_weight_metabolic_rate = user.experiments.includes(
        ExperimentsKeys.mw_weight_metabolic_rate
    );

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.mw_weight_metabolic_rate,
            mw_weight_metabolic_rate ? '1' : '0'
        );
    }, [mw_weight_metabolic_rate]);

    const methods = useForm<any>({
        resolver: yupResolver(formSchema),
    });

    useEffect(() => {
        const defaultValues = {
            unitSystem: bodyHeightWeightUnitSystem,
            heightCm: convertToCm(bodyHeightFt, bodyHeightInches),
            ft: bodyHeightFt,
            inches: bodyHeightInches
        };

        methods.reset(defaultValues);
    }, []);

    useEffect(() => {
        const subscription = methods.watch((value) =>
            Object
                .keys(value)
                .forEach((k) => localStorage.setItem(`body-height-${k}`, value[k])));
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    return (
        <>
            <Heading title={t('body-height-title')} />

            <FormProvider {...methods}>
                <form>
                    {bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial && (
                        <div className="flex w-full row space-between gap-2">
                            <Controller
                                name="ft"
                                control={methods.control}
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            {...methods.register('ft')}
                                            type="number"
                                            inputMode="numeric"
                                            label="Height - ft"
                                            min={0}
                                            isInvalid={!!methods.formState.errors?.ft}
                                            errorMessage={
                                                methods.formState.errors?.ft?.message?.toString() ||
                                                ''
                                            }
                                        />
                                    </>
                                )}
                            />
                            <Controller
                                name="inches"
                                control={methods.control}
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            {...methods.register('inches')}
                                            type="number"
                                            inputMode="numeric"
                                            label="Height - in"
                                            min={0}
                                            max={12}

                                            isInvalid={!!methods.formState.errors?.inches}
                                            errorMessage={
                                                methods.formState.errors?.inches?.message?.toString() ||
                                                ''
                                            }
                                        />
                                    </>
                                )}
                            />
                        </div>
                    )}

                    {bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric && (
                        <Controller
                            name="heightCm"
                            control={methods.control}
                            render={({ field }) => (
                                <>
                                    <Input
                                        {...field}
                                        {...methods.register('heightCm')}
                                        type="number"
                                        inputMode="numeric"
                                        label="Height - cm"
                                        min={0}
                                        isInvalid={!!methods.formState.errors?.heightCm}
                                        errorMessage={
                                            methods.formState.errors?.heightCm?.message?.toString() ||
                                            ''
                                        }
                                        // todo update error msg & submit logic
                                    />
                                </>
                            )}
                        />
                    )}

                    <Button
                        type="button"
                        variant="light"
                        buttonClass="bg-transparent hover:!bg-transparent dark:bg-transparent dark:hover:bg-transparent text-btnActive hover:text-btnActive hover:!opacity-80 dark:hover:!opacity-80 dark:text-btnActive max-w-fit !py-2 !px-3 min-w-0 min-h-0 h-auto text-lg font-semibold ml-auto block mt-1"
                        onClick={() => {
                            if (bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial) {
                                methods.setValue("unitSystem", UNIT_SYSTEM.metric);
                                methods.setValue("heightCm", convertToCm(+methods.getValues("ft"), +methods.getValues("inches")));
                                methods.trigger("heightCm");
                                setBodyHeightWeightUnitSystem(UNIT_SYSTEM.metric);
                            } else {
                                const { ft, inches } = convertToFtInches(+methods.getValues("heightCm"));
                                methods.setValue("unitSystem", UNIT_SYSTEM.imperial);
                                methods.setValue("ft", ft);
                                methods.setValue("inches", inches);
                                methods.trigger("ft");
                                methods.trigger("inches");
                                setBodyHeightWeightUnitSystem(UNIT_SYSTEM.imperial);
                            }
                            methods.trigger("unitSystem");
                        }}
                    >
                        {/* Switch */}
                        Switch to&nbsp;
                        {bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? 'cm' : 'ft'}
                    </Button>

                    <NextButton
                        type="submit"
                        onClick={methods.handleSubmit(handleSubmit)}
                        disabled={!methods.formState.isValid}
                    />
                </form>
            </FormProvider>

            {/*<BodyHeightWeightInputs*/}
            {/*    heightInput*/}
            {/*    idealWeightInput={false}*/}
            {/*    setInputError={setError}*/}
            {/*    pageError={pageError}*/}
            {/*/>*/}
        </>
    );
};
