import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';

import { useUTMSearch } from 'hooks/use-encharge-search.hook';
import { Button } from '@nextui-org/react';
import { useAppState } from 'context/AppContext';
import { ExperimentsKeys } from 'constants/experiments';

export const MainPage = () => {
    const navigate = useNavigate();
    const UTMSearch = useUTMSearch();

    // useEffect(() => {
    //     navigate(`../${RoutePath.BodyGender}${UTMSearch}`);
    // }, []);

    // return null;

    const { user } = useAppState();

    const mw_first_screen = user.experiments.includes(ExperimentsKeys.mw_first_screen);

    return (
        <section className="flex flex-col py-10 gap-10 items-center">
            <h1 className="text-center text-foreground">Select Funnel</h1>
            <div className="max-w-md w-full">
                <Button
                    // color="primary"
                    className="bg-blue-500 text-white"
                    size="lg"
                    fullWidth
                    onPress={() => {
                        // navigate(`../${RoutePath.BodyGender}${UTMSearch}`);
                        // if (value === '1') {
                        if (mw_first_screen) {
                            navigate(`../${RoutePath.Goal}${UTMSearch}`);
                        } else {
                            navigate(`../${RoutePath.BodyGender}${UTMSearch}`);
                        }
                    }}
                >
                    RX Funnel
                </Button>
            </div>
        </section>
    );
};
