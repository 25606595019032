import { RoutePath } from 'routes/route-path.constant';

import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';
import { ImportantEventDateKeys } from 'constants/important-event-date';

import { GoalChartPopover } from '../chart-popover';

import { ImportantEventPopover } from 'components/charts/important-event-popover';

export type GoalChartLoseWeightType = {
    unit: string;
    weightPlan: number[];
    monthsPlan: string[];
    goalWeight: number;
};

export const GoalChartLoseWeight = ({
    unit,
    weightPlan,
    monthsPlan,
    goalWeight,
}: GoalChartLoseWeightType) => {
    const monthsPlanDisplay = [...monthsPlan];

    let indicesToBeSpliced: number[] = [];
    const weightPlanDisplay = weightPlan?.map((weight, i) => {
        if (!(weight % 2)) {
            return weight;
        }

        // @description: For ODD numbers. Latest element less
        if (weightPlan.length === i + 1) {
            return weight - 1;
        }

        weight = weight + 1;

        if (weight === weightPlan[i - 1]) {
            indicesToBeSpliced.push(i);
            return weight;
        }

        return weight;
    });

    for (let i = indicesToBeSpliced.length - 1; i >= 0; i--) {
        weightPlanDisplay.splice(indicesToBeSpliced[i], 1);
    }

    // @description: weightPlan with odd numbers, we increase the display items and lost the medium item
    if (weightPlanDisplay.length === 2) {
        const first = weightPlanDisplay[0];
        const second = weightPlanDisplay[1];

        const diff = (first - second) / 2;

        if (!(diff % 2)) {
            weightPlanDisplay.splice(1, 0, first - diff);
        }
    }

    const mw_important_date = localStorage.getItem(ExperimentsKeys.mw_important_date) === '1';
    const importantEventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]);
    const importantEventDateSkippedValue = localStorage.getItem(
        ImportantEventDateKeys.importantEventDateSkipped
    );
    const showImportantEventPopover =
        mw_important_date &&
        importantEventValue !== 'none' &&
        importantEventDateSkippedValue === 'false';

    return (
        <>
            <div className={'flex justify-center relative [&_svg]:block'}>
                {/* Number */}
                <div
                    className={
                        'flex flex-col justify-between mr-2 mt-[5px] text-xs leading-[18px] text-[#6B6B6B] dark:text-foreground-500'
                    }
                >
                    {weightPlanDisplay.map((weight) => (
                        <span key={weight}>{weight}</span>
                    ))}
                </div>

                {showImportantEventPopover && <ImportantEventPopover />}

                <GoalChartPopover
                    weight={goalWeight}
                    unit={unit}
                    popoverWrapperClass={
                        'top-[74%] left-[81%] max-[540px]:top-[73%] max-[530px]:top-[73%] max-[530px]:left-[80.5%] max-[500px]:top-[71%] max-[500px]:left-[80.5%] max-[480px]:top-[71%] max-[480px]:left-[78.5%] max-[460px]:top-[69%] max-[460px]:left-[75.5%] max-[440px]:top-[67%] max-[440px]:left-[75.5%] max-[414px]:top-[65%] max-[414px]:left-[75.5%] max-[393px]:top-[65%] max-[393px]:left-[75.5%] max-[390px]:top-[64%] max-[393px]:left-[75.5%] max-[375px]:top-[63%] max-[375px]:left-[75.5%] max-[360px]:top-[61%] max-[360px]:left-[75.5%] max-[320px]:top-[58%] max-[320px]:left-[73%] max-[280px]:top-[51%]'
                    }
                />

                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 360 215"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <g>
                        <path strokeWidth="2" stroke="#E0E3EB" d="m1.90317,212.92857" />
                        <path
                            stroke="null"
                            fill="#E0E3EB"
                            d="m360,191.06765c-109.96311,5.54168 -173.55631,-31.70359 -250.38226,-96.50748c-34.44129,-33.67016 -72.24027,-79.82148 -109.50695,-79.82148l0,200.2613l359.88921,0l0,-23.93233l0,-0.00001z"
                            opacity="0.3"
                        />
                        <path
                            stroke="#36B992"
                            className="weightGraphicPath"
                            strokeWidth="3"
                            d="M0.7142999999999802,13.857020000000006C39.37223999999998,13.857020000000006 75.29193999999998,63.15854000000001 109.64096999999998,94.58097000000001C179.17647999999997,158.19135 249.31622999999996,199.24315 358.69744,193.5603"
                        />
                        <path stroke="#E0E3EB" d="m0.07794,213.82583l360.066,0" strokeWidth="2" />
                    </g>
                </svg>
            </div>

            {/* Month */}
            <div
                className={
                    'flex justify-between pl-[7%] pt-2 text-xs leading-[18px] text-[#6B6B6B] dark:text-foreground-500 h-[25px]'
                }
            >
                {monthsPlanDisplay.map((month, i) => (
                    <span key={`${month}-${i}`}>{month}</span>
                ))}
            </div>
        </>
    );
};
