import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { isEmpty } from 'helpers';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { RoutePath } from 'routes/route-path.constant';
import { pageConfiguration } from 'containers/index';
import { UsersApiProvider } from 'api/users.api';

import { USA_COUNTRY_CODE } from 'constants/customer';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserGeolocationKeys } from 'constants/user-geolocation';
import { ExperimentsKeys } from 'constants/experiments';
import { UserVerificationKeys } from 'constants/user-verification';

import { ValidatedInput } from 'components/validated-input';
import { NextButton } from 'ncomponents/NextButton';
import { useAppState } from 'context/AppContext';
// import { NextButton } from 'components/next-button';

interface AccountFormProps {
    handleSubmit: (email: string) => void;
    setShowUserExistsErrorMessageExp: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AccountForm: FC<AccountFormProps> = ({
    handleSubmit,
    setShowUserExistsErrorMessageExp,
}) => {
    const { t } = useTranslation();

    const key = MultipleLocalStorageKeys[RoutePath.Checkout];

    const { pageValue: firstName, setPageValue: setFirstName } = useLocalStorage({
        key: key.firstName as string,
        defaultValue: '',
    });

    const { pageValue: lastName, setPageValue: setLastName } = useLocalStorage({
        key: key.lastName as string,
        defaultValue: '',
    });

    const { pageValue: email, setPageValue: setEmail } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const { pageValue: password, setPageValue: setPassword } = useLocalStorage({
        key: key.password as string,
        defaultValue: '',
    });

    const { pageValue: dateOfBirth, setPageValue: setDateOfBirth } = useLocalStorage({
        key: key.dateOfBirth as string,
        defaultValue: '',
    });

    const [phoneStartAdornment, setPhoneStartAdornment] = useState('');

    const { pageValue: phone, setPageValue: setPhone } = useLocalStorage({
        key: key.phone as string,
        defaultValue: '',
    });

    const onFirstNameChange = (e: any) => {
        setFirstName(e.target.value);
    };

    const onLastNameChange = (e: any) => {
        setLastName(e.target.value);
    };

    const onEmailChange = (e: any) => {
        setEmail(e.target.value);
    };

    const onPasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    const onDateOfBirthChange = (e: any) => {
        let value = e.target.value.trim();

        const dateValue = value.replace(/[^\d]/g, '');

        const dateNumberRegex = /^(\d{0,2})?(\d{0,2})?(\d{0,4})?(\d+)?/;

        const matches = dateValue.match(dateNumberRegex);

        if (!dateValue) {
            setDateOfBirth('');
        } else {
            let phoneInnerValue = `${matches[1]}`;

            if (matches[2]) {
                phoneInnerValue += '/' + matches[2];
            }

            if (matches[3]) {
                phoneInnerValue += '/' + matches[3];
            }
            if (matches[4]) {
                phoneInnerValue += '' + matches[4];
            }
            setDateOfBirth(phoneInnerValue);
        }
    };

    const onPhoneChange = (e: any) => {
        setPhone(e.target.value);
    };

    const [validationListener, setValidationListener] = useState(false);

    const [inputErrors, setInputErrors] = useState<{ [index: string]: any }>({});

    const handleError = (error: boolean, key: string) => {
        setInputErrors((prevState) => ({
            ...prevState,
            [key]: error,
        }));
    };

    const onFirstNameError = (error: boolean) => {
        handleError(error, 'firstName');
    };

    const onLastNameError = (error: boolean) => {
        handleError(error, 'lastName');
    };

    const onEmailError = (error: boolean) => {
        handleError(error, 'email');
    };

    const onPasswordError = (error: boolean) => {
        handleError(error, 'password');
    };

    const onDateOfBirthError = (error: boolean) => {
        handleError(error, 'bday');
    };

    const onPhoneError = (error: boolean) => {
        handleError(error, 'phone');
    };

    const onPhoneFocus = () => {
        setPhoneStartAdornment(USA_COUNTRY_CODE);
    };

    const onPhoneBlur = () => {
        if (!phone.toString().length) {
            setPhoneStartAdornment('');
        }
    };

    useEffect(() => {
        // if (phone.toString().length) {
        setPhoneStartAdornment(USA_COUNTRY_CODE);
        // }
    }, []);

    // const mw_email_screen_removal =
    //     localStorage.getItem(ExperimentsKeys.mw_email_screen_removal) === '1';
    const { user } = useAppState();
    const mw_email_screen_removal = user.experiments.includes(
        ExperimentsKeys.mw_email_screen_removal
    );

    const handleFormSubmit = () => {
        setValidationListener(true);

        if (mw_email_screen_removal) {
            UsersApiProvider.isUserExists(email).then(({ isUserExists }) => {
                localStorage.setItem(UserVerificationKeys.isUserExists, String(isUserExists));
                setShowUserExistsErrorMessageExp(isUserExists);
            });
        }

        if (
            !isEmpty(firstName) &&
            !isEmpty(lastName) &&
            !isEmpty(email) &&
            !isEmpty(password) &&
            !isEmpty(dateOfBirth) &&
            !isEmpty(phone) &&
            !Object.keys(inputErrors).some((key: string) => inputErrors[key])
        ) {
            handleSubmit(email);
        }

        pageConfiguration.event('RxWeightContinueToCheckoutClicked');
    };

    return (
        <div
            className={
                'flex flex-col gap-4 max-w-[560px] [&>h3:last-of-type]:text-left md:[&>h3:last-of-type]:text-lg md:[&>h3:last-of-type]:leading-[26px] md:[&>h3:last-of-type]:mt-2 md:[&>h3:last-of-type]:mb-0'
            }
        >
            <h1 className={'!m-0 !leading-6 text-left md:mb-2'}>
                <Trans i18nKey="checkout-account-form-title" />
            </h1>

            <div className={'flex w-full gap-4 max-w-[560px] my-0 mx-auto [&_div]:w-full'}>
                <div>
                    <ValidatedInput
                        autoComplete="given-name"
                        value={firstName}
                        onChange={onFirstNameChange}
                        onError={onFirstNameError}
                        validationQuery={{
                            type: {
                                value: 'emptyString',
                                errorMessage: t('checkout-input-error-empty-field'),
                            },
                        }}
                        toggleValidationListener={validationListener}
                        label={t('checkout-account-form-input-first-name')}
                    />
                </div>

                <div>
                    <ValidatedInput
                        autoComplete="family-name"
                        value={lastName}
                        onChange={onLastNameChange}
                        onError={onLastNameError}
                        validationQuery={{
                            type: {
                                value: 'emptyString',
                                errorMessage: t('checkout-input-error-empty-field'),
                            },
                        }}
                        toggleValidationListener={validationListener}
                        label={t('checkout-account-form-input-last-name')}
                    />
                </div>
            </div>

            <div>
                <ValidatedInput
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={onEmailChange}
                    onError={onEmailError}
                    validationQuery={{
                        type: {
                            value: 'email',
                            errorMessage: t('email-input-error-message'),
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-email')}
                />
            </div>

            <div>
                <ValidatedInput
                    type="text"
                    value={password}
                    onChange={onPasswordChange}
                    onError={onPasswordError}
                    validationQuery={{
                        type: {
                            value: 'emptyString',
                            errorMessage: t('checkout-input-error-empty-field'),
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-password')}
                />
            </div>

            <div>
                <ValidatedInput
                    value={dateOfBirth}
                    autoComplete="bday"
                    onChange={onDateOfBirthChange}
                    onError={onDateOfBirthError}
                    maxLength={10}
                    validationQuery={{
                        type: {
                            value: 'date',
                            format: 'MM/DD/YYYY',
                            errorMessage: 'Invalid date',
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-date-of-birth')}
                />
            </div>

            <div>
                <ValidatedInput
                    type="tel"
                    value={phone}
                    onChange={onPhoneChange}
                    onError={onPhoneError}
                    onFocus={onPhoneFocus}
                    onBlur={onPhoneBlur}
                    startAdornment={phoneStartAdornment}
                    maxLength={10}
                    validationQuery={{
                        type: {
                            value: 'phone',
                            pattern: /^[0-9]{10}$/,
                            errorMessage: 'Invalid phone number',
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-phone')}
                />
            </div>

            {mw_email_screen_removal && (
                <h3>
                    <Trans i18nKey="checkout-account-form-confidentiality-info" />
                </h3>
            )}

            <NextButton
                className={'!mt-4 mx-0 !mb-5'}
                onClick={handleFormSubmit}
                typographyText={t('checkout-account-form-button-next')}
            />
        </div>
    );
};

export const getCustomerDetails = () => {
    const key = MultipleLocalStorageKeys[RoutePath.Checkout];

    const firstName = localStorage.getItem(key.firstName as string) || '';
    const lastName = localStorage.getItem(key.lastName as string) || '';
    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email] as string) || '';
    const password = localStorage.getItem(key.password as string) || '';
    const dateOfBirth = localStorage.getItem(key.dateOfBirth as string) || '';
    const phone = localStorage.getItem(key.phone as string) || '';
    const ip = localStorage.getItem(UserGeolocationKeys.userIP) || '';

    return { firstName, lastName, email, password, dateOfBirth, phone, ip };
};

export const isValidAccountForm = () => {
    const { firstName, lastName, email, password, dateOfBirth, phone } = getCustomerDetails();

    return (
        !isEmpty(firstName) &&
        !isEmpty(lastName) &&
        !isEmpty(email) &&
        !isEmpty(password) &&
        !isEmpty(dateOfBirth) &&
        !isEmpty(phone)
    );
};
