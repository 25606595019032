import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { ExperimentsKeys } from 'constants/experiments';

// TODO ui design note different svg import for dark theme currentColor
import { ReactComponent as ScientificProofGraphic } from 'assets/images/svg/scientific-proof/scientific-proof-graphic.svg';
import { ReactComponent as ColumbiaUni } from 'assets/images/svg/scientific-proof/columbia-university.svg';
import { ReactComponent as MayoClinic } from 'assets/images/svg/scientific-proof/mayo-clinic.svg';
import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';
import { useAppState } from 'context/AppContext';

export const ScientificProof = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    // const mw_email_screen_removal = true; // ExperimentsKeys.mw_email_screen_removal
    const { user } = useAppState();
    const mw_email_screen_removal = user.experiments.includes(
        ExperimentsKeys.mw_email_screen_removal
    );

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.mw_email_screen_removal,
            mw_email_screen_removal ? '1' : '0'
        );
    }, [mw_email_screen_removal]);

    return (
        <>
            {/* <Typography
                variant={TypographyVariants.h1}
                className={
                    'text-xl font-semibold leading-6 md:!leading-[33px] md:text-[28px] md:mx-auto md:mt-20 md:mb-10'
                }
            >
                <Trans i18nKey="scientific-proof-title" />
            </Typography> */}

            <Heading title={t('scientific-proof-title')} />
            {/* // todo fix small text compared to Typography */}

            {/* // todo ui design note: changed removed bg color of graph slopes in svg otherwise not match dark mode */}
            <ScientificProofGraphic className="w-full max-w-[560px] h-auto text-foreground" />

            <div
                className={
                    'flex flex-col items-center rounded-lg p-4 mt-10 mx-auto pb-2.5 bg-content2 md:mt-[47px] md:mb-5'
                }
            >
                <div className={'flex flex-row items-center mb-8 last:mb-0'}>
                    <ColumbiaUni className="shrink-0" />
                    <h3 className={'text-left ml-4 md:text-base md:leading-6'}>
                        <Trans
                            i18nKey="scientific-proof-columbia-university-description"
                            components={[<strong></strong>]}
                        />
                    </h3>
                </div>
                <div className={'flex flex-row items-center mb-8 last:mb-0'}>
                    <MayoClinic className="shrink-0 text-foreground dark:text-foreground-400" />
                    <h3 className={'text-left ml-4 md:text-base md:leading-6'}>
                        <Trans
                            i18nKey="scientific-proof-mayo-clinic-description"
                            components={[<strong></strong>]}
                        />
                    </h3>
                </div>
            </div>

            <NextButton typographyText={t('scientific-proof-button-next')} onClick={handleClick} />
        </>
    );
};
