import { BaseContext } from 'context/base.context';
import React, { useContext } from 'react';
import { NextButton } from '../../NextButton';

export interface TipPageProps {
    imgSrc: React.ReactNode;
    text: {
        description: string | React.ReactNode;
        next: string;
    };
}

export const TipPage = ({ imgSrc, text }: TipPageProps) => {
    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <div
            className={`flex items-center flex-col bg-[#E8F5FD] dark:bg-blue-900/30 absolute h-[calc(100vh_-_52px)] w-full py-0 px-4 overflow-y-scroll`}
        >
            <div className={`max-w-[560px] flex flex-col justify-between h-4/5`}>
                <div>
                    <div className="min-h-16 my-12 md:mt-20 mb-10 text-foreground">{imgSrc}</div>

                    <p className=" inline-block font-normal text-foreground text-left mx-auto whitespace-pre-line md:-mt-2 leading-7 text-xl">
                        {text.description}
                    </p>
                </div>

                <NextButton className="!mb-[5vh]" onClick={handleClick} text={text.next} />
            </div>
        </div>
    );
};
