import { FC, ReactNode } from 'react';

export interface BannerProps {
    children: string | ReactNode;
}

export const Banner: FC<BannerProps> = ({ children }) => {
    return (
        <div
            className={
                'w-auto h-10 flex items-center justify-center bg-[#C6EFE2] sticky top-0 z-[101] md:h-[50px]'
            }
        >
            {children}
        </div>
    );
};
