import React from 'react';

import {
    Accordion as NextUIAccordion,
    AccordionItem as NextUIAccordionItem,
} from '@nextui-org/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface AccordionItem {
    [name: string | number | symbol]: any;
    id: string | number;
}

interface AccorionProps {
    items: AccordionItem[];
    multiExpand?: boolean;
    DetailComponent: React.ElementType;
    [rest: string | number | symbol]: unknown;
}

const Accordion = ({ items, multiExpand = true, DetailComponent, ...rest }: AccorionProps) => {
    return (
        <div className="mb-3 border-b border-solid border-divider">
            <NextUIAccordion
                keepContentMounted
                selectionMode={multiExpand ? 'multiple' : 'single'}
                className="!px-0"
            >
                {items.map(({ id, ...data }) => (
                    <NextUIAccordionItem
                        key={data.title}
                        aria-label={data.title}
                        title={data.title}
                        indicator={
                            // chevron 1
                            // <div className="h-4 w-4 border-r-2 border-b-2 border-solid border-t-0 border-l-0 md:border-r-3 md:border-b-3 border-foreground rotate-45 origin-center" />
                            // chevron 2
                            // <svg
                            //     xmlns="http://www.w3.org/2000/svg"
                            //     viewBox="0 0 24 24"
                            //     fill="currentColor"
                            //     className="w-7 h-7 text-foreground"
                            // >
                            //     <path
                            //         fillRule="evenodd"
                            //         d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                            //         clipRule="evenodd"
                            //     />
                            // </svg>
                            // chevron 3
                            <ChevronDownIcon className="h-8 w-8 text-foreground" />
                        }
                        className="my-3"
                        classNames={{
                            trigger: 'pr-4',
                            title: 'font-semibold text-lg md:text-2xl',
                            indicator: 'data-[open=true]:rotate-180 !origin-center',
                            heading: 'my-0',
                        }}
                    >
                        <DetailComponent {...data} {...rest} />
                    </NextUIAccordionItem>
                ))}
            </NextUIAccordion>
        </div>
    );
};

export default Accordion;
