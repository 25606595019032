import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { UserEligibilityKeys } from 'constants/user-eligibility';
import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

import { IMultiChoiceButtonOption, MultiChoice } from 'ncomponents/MultiChoiceButton';

import { isNextButtonDisabled } from 'helpers/get-multichoice-button-select-options';
import { Heading } from 'ncomponents/Heading';
import { FloatedButton } from 'ncomponents/FloatedButton';
import { NextButton } from 'ncomponents/NextButton';
import { useAppState } from 'context/AppContext';

export const MedicalCondition = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicalCondition];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const {
        pageValue: cardiovascularDiseaseUserAnswer,
        setPageValue: setCardiovascularDiseaseUserAnswer,
    } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-cardiovascular-disease-userAnswer`,
        defaultValue: '',
    });

    const {
        pageValue: neurologicalDiseaseUserAnswer,
        setPageValue: setNeurologicalDiseaseUserAnswer,
    } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-neurological-disease-userAnswer`,
        defaultValue: '',
    });

    const {
        pageValue: psychiatricDiseaseUserAnswer,
        setPageValue: setPsychiatricDiseaseUserAnswer,
    } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-psychiatric-disease-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: autoimmuneDiseaseUserAnswer, setPageValue: setAutoimmuneDiseaseUserAnswer } =
        useLocalStorage({
            key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-autoimmune-disease-userAnswer`,
            defaultValue: '',
        });

    const { pageValue: eatingDisorderUserAnswer, setPageValue: setEatingDisorderUserAnswer } =
        useLocalStorage({
            key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-eating-disorder-userAnswer`,
            defaultValue: '',
        });

    const { pageValue: otherGIDiseasesUserAnswer, setPageValue: setOtherGIDiseasesUserAnswer } =
        useLocalStorage({
            key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-other-gi-diseases-userAnswer`,
            defaultValue: '',
        });

    const { pageValue: otherUserAnswer, setPageValue: setOtherUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoicesControl: IMultiChoiceButtonOption[] = [
        {
            value: 'diabetes',
            label: t('medical-condition-option-diabetes'),
        },
        {
            value: 'high-blood-pressure',
            label: t('medical-condition-option-high-blood-pressure'),
        },
        {
            value: 'high-cholesterol',
            label: t('medical-condition-option-high-cholesterol'),
        },
        {
            value: 'gallbladder-disease',
            label: t('medical-condition-option-gallbladder-disease'),
        },
        {
            value: 'liver-disease',
            label: t('medical-condition-option-fatty-liver-disease'),
        },
        {
            value: 'malabsorption-syndrome',
            label: t('medical-condition-option-malabsorption-syndrome'),
        },
        {
            value: 'none',
            label: t('medical-condition-option-none'),
        },
    ];

    const multiChoicesExp: IMultiChoiceButtonOption[] = [
        {
            value: 'insulin-dependent-diabetes',
            label: t('medical-condition-option-insulin-dependent-diabetes'),
        },
        {
            value: 'non-insulin-dependent-diabetes',
            label: t('medical-condition-option-non-insulin-dependent-diabetes'),
        },
        {
            value: 'high-blood-pressure',
            label: t('medical-condition-option-high-blood-pressure'),
        },
        {
            value: 'high-cholesterol',
            label: t('medical-condition-option-high-cholesterol'),
        },
        {
            value: 'gallbladder-disease',
            label: t('medical-condition-option-gallbladder-disease'),
        },
        {
            value: 'liver-disease',
            label: t('medical-condition-option-liver-disease'),
        },
        {
            value: 'cardiovascular-disease',
            label: t('medical-condition-option-cardiovascular-disease'),
            userAnswer: cardiovascularDiseaseUserAnswer,
            setUserAnswer: setCardiovascularDiseaseUserAnswer,
        },
        {
            value: 'neurological-disease',
            label: t('medical-condition-option-neurological-disease'),
            userAnswer: neurologicalDiseaseUserAnswer,
            setUserAnswer: setNeurologicalDiseaseUserAnswer,
        },
        {
            value: 'psychiatric-disease',
            label: t('medical-condition-option-psychiatric-disease'),
            userAnswer: psychiatricDiseaseUserAnswer,
            setUserAnswer: setPsychiatricDiseaseUserAnswer,
        },
        {
            value: 'autoimmune-disease',
            label: t('medical-condition-option-autoimmune-disease'),
            userAnswer: autoimmuneDiseaseUserAnswer,
            setUserAnswer: setAutoimmuneDiseaseUserAnswer,
        },
        {
            value: 'kidney-disease',
            label: t('medical-condition-option-kidney-disease'),
        },
        {
            value: 'eating-disorder',
            label: t('medical-condition-option-eating-disorder'),
            userAnswer: eatingDisorderUserAnswer,
            setUserAnswer: setEatingDisorderUserAnswer,
        },
        {
            value: 'inflammatory-bowel-disease',
            label: t('medical-condition-option-inflammatory-bowel-disease'),
        },
        {
            value: 'other-gi-diseases',
            label: t('medical-condition-option-other-gi-diseases'),
            userAnswer: otherGIDiseasesUserAnswer,
            setUserAnswer: setOtherGIDiseasesUserAnswer,
        },
        {
            value: 'other',
            label: t('medical-condition-option-other'),
            userAnswer: otherUserAnswer,
            setUserAnswer: setOtherUserAnswer,
        },
        {
            value: 'none',
            label: t('medical-condition-option-none'),
        },
    ];

    // const mw_medical_director_changes =
    //     localStorage.getItem(ExperimentsKeys.mw_medical_director_changes) === '1';
    const { user: userCtx } = useAppState();
    const mw_medical_director_changes = userCtx.experiments.includes(
        ExperimentsKeys.mw_medical_director_changes
    );

    const multiChoices = mw_medical_director_changes ? multiChoicesExp : multiChoicesControl;

    const handleClick = () => {
        // todo check value and update logic for label and value object array?
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return currentOption?.value === 'none' || currentOption?.userAnswer === undefined
                ? currentOption?.value
                : `${currentOption?.value}: ${currentOption?.userAnswer?.trim()}`;
        });

        // todo update check value and logic for label and value object array?
        let userStatus =
            pageValue[0] === 'none'
                ? UserEligibilityKeys.userStatus.approved
                : UserEligibilityKeys.userStatus.needsReview;

        const medicationIntakePageValue = JSON.parse(
            localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntake]) as string
        );
        if (
            medicationIntakePageValue?.length > 0 &&
            medicationIntakePageValue[0] !== 'none' &&
            userStatus === UserEligibilityKeys.userStatus.approved
        ) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        // todo check value and update logic for label and value object array?
        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('medical-condition-title'),
                value:
                    pageValue[0] === 'none'
                        ? multiChoices
                              .filter((multiChoice) => multiChoice.key !== 'none')
                              .map((multiChoice) => `"${multiChoice.value}" - NO`)
                        : selectOption,
            },
            userStatus: {
                question: t('medication-user-eligibility-status-question'),
                value: userStatus,
            },
        });

        pageConfiguration.handleOnPageComplete(pageValue);
    };

    // const mw_comparison_screen = true; // ExperimentsKeys.mw_comparison_screen
    // const mw_how_sema_works = '0'; // ExperimentsKeys.mw_how_sema_works
    const mw_comparison_screen = userCtx.experiments.includes(ExperimentsKeys.mw_comparison_screen);
    const mw_how_sema_works = userCtx.experiments.includes(ExperimentsKeys.mw_how_sema_works)
        ? '1'
        : '0';

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.mw_comparison_screen,
            mw_comparison_screen ? '1' : '0'
        );
        localStorage.setItem(ExperimentsKeys.mw_how_sema_works, mw_how_sema_works);
    }, [mw_comparison_screen]);

    return (
        <>
            <Heading title={t('medical-condition-title')} />

            <MultiChoice
                options={multiChoices}
                selectedOptions={pageValue}
                onSelect={setPageValue}
                textAreaInputLabel={t('medical-condition-textarea-label')}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton
                    onClick={handleClick}
                    disabled={isNextButtonDisabled(pageValue, multiChoices)}
                />
            </FloatedButton>
        </>
    );
};
