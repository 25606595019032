import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

import { IMultiChoiceButtonOption, MultiChoice } from 'ncomponents/MultiChoiceButton';
import { Heading } from 'ncomponents/Heading';
import { FloatedButton } from 'ncomponents/FloatedButton';
import { NextButton } from 'ncomponents/NextButton';
import { useAppState } from 'context/AppContext';

export const HealthCondition = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.HealthCondition];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);

    // const mw_medical_director_changes = true; // ExperimentsKeys.mw_medical_director_changes
    const { user } = useAppState();
    const mw_medical_director_changes = user.experiments.includes(
        ExperimentsKeys.mw_medical_director_changes
    );

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.mw_medical_director_changes,
            mw_medical_director_changes ? '1' : '0'
        );
    }, [mw_medical_director_changes]);

    const multiChoicesControl: IMultiChoiceButtonOption[] = [
        {
            value: 'pregnant',
            label: t('health-condition-option-pregnant'),
        },
        {
            value: 'breastfeeding',
            label: t('health-condition-option-breastfeeding'),
        },
        {
            value: 'cancer',
            label: t('health-condition-option-cancer'),
        },
        {
            value: 'eating-disorder',
            label: t('health-condition-option-eating-disorder'),
        },
        {
            value: 'substance-dependency',
            label: t('health-condition-option-substance-dependency'),
        },
        {
            value: 'diabetes',
            label: t('health-condition-option-diabetes'),
        },
        {
            value: 'bariatric-surgery',
            label: t('health-condition-option-bariatric-surgery'),
        },
        {
            value: 'pancreatitis',
            label: t('health-condition-option-pancreatitis'),
        },
        {
            value: 'thyroid-cancer',
            label: t('health-condition-option-thyroid-cancer'),
        },
        {
            value: 'none',
            label: t('health-condition-option-none'),
        },
    ];

    const multiChoicesExp: IMultiChoiceButtonOption[] = [
        {
            value: 'pregnant',
            label: t('health-condition-option-pregnant'),
        },
        {
            value: 'breastfeeding',
            label: t('health-condition-option-breastfeeding'),
        },
        {
            value: 'cancer',
            label: t('health-condition-option-cancer-history'),
        },
        {
            value: 'hiv-aids',
            label: t('health-condition-option-hiv-aids'),
        },
        {
            value: 'substance-dependency',
            label: t('health-condition-option-substance-dependency'),
        },
        {
            value: 'bariatric-surgery',
            label: t('health-condition-option-bariatric-surgery'),
        },
        {
            value: 'pancreatitis',
            label: t('health-condition-option-pancreatitis'),
        },
        {
            value: 'thyroid-cancer',
            label: t('health-condition-option-thyroid-cancer'),
        },
        {
            value: 'none',
            label: t('health-condition-option-none'),
        },
    ];

    const multiChoices = mw_medical_director_changes ? multiChoicesExp : multiChoicesControl;
    const multiChoicesByGender: IMultiChoiceButtonOption[] =
        userGender === 'male' ? multiChoices.slice(2) : multiChoices;

    // todo update logic for label and value object
    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoicesByGender.find((option) => option.key === item);

            return currentOption?.value;
        });

        // todo update logic for label and value object
        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('health-condition-title'),
                value:
                    pageValue[0] === 'none'
                        ? multiChoicesByGender
                              .filter((multiChoice) => multiChoice.key !== 'none')
                              .map((multiChoice) => `"${multiChoice.value}" - NO`)
                        : selectOption,
            },
        });

        // todo check updated logic for label and value object
        // const redirectUserToDeclineScreen = mw_medical_director_changes
        //     ? pageValue[0] !== 'none'
        //     : pageValue.includes('pregnant') || pageValue.includes('breastfeeding');

        const pageValues = pageValue.map((val: IMultiChoiceButtonOption) => val.value);
        const redirectUserToDeclineScreen = mw_medical_director_changes
            ? pageValue[0].value !== 'none'
            : pageValues.includes('pregnant') || pageValues.includes('breastfeeding');

        pageConfiguration.handleOnPageComplete({
            pageValue,
            declineUser: redirectUserToDeclineScreen,
        });
    };

    return (
        <>
            <Heading title={t('health-condition-title')} />

            <MultiChoice
                options={multiChoices}
                selectedOptions={pageValue}
                onSelect={setPageValue}
                disabledWhenValues={['none']}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
