import React from 'react';
import { Button as NextUIButton } from '@nextui-org/react';

import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from 'components/typography';

import { cn } from 'helpers/cn';

export const Button = ({
    buttonWrapperClassName,
    className,
    onClick,
    disabled,
    bottomMargin,
    typographyVariant,
    typographyText,
    children,
    ...props
}: any) => {
    let tVariant = TypographyVariants.button;

    if (typographyVariant) {
        // @ts-ignore // Remove Typography for passing from up, because of typographyVariant.choiceButton -> typographyVariant.choiceButtonTypography
        tVariant = TypographyVariants[typographyVariant.replace('Typography', '')]
            ? // @ts-ignore // Remove Typography for passing from up, because of typographyVariant.choiceButton -> typographyVariant.choiceButtonTypography
              TypographyVariants[typographyVariant.replace('Typography', '')]
            : tVariant;
    }

    const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <div className={cn(`flex w-full justify-center`, buttonWrapperClassName, {})}>
            <NextUIButton
                onPress={clickHandler}
                className={cn(
                    `bg-[#E8F5FD] relative px-4 py-3 max-w-[400px] min-w-[120px] w-full h-12 border-none text-black rounded-lg cursor-pointer gap-0 min-h-auto p-4 text-large hover:bg-[#B7E3FF] dark:hover:bg-content3 hover:text-black hover:!opacity-100 mb-3 md:mb-4 text-wrap disabled:opacity-100`,
                    className,
                    { 'bg-[#C5C5D1] text-[#FFFFFF] cursor-not-allowed': disabled, '': bottomMargin }
                )}
                isDisabled={disabled}
                {...props}
            >
                {children}
                {typographyText ? (
                    <Typography variant={tVariant}>{typographyText}</Typography>
                ) : null}
            </NextUIButton>
        </div>
    );
};
