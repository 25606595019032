import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';

export const WeightMetabolicRate = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = ['svg/weight-metabolic-rate/weight-metabolic-rate-graphic.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            {/* <Typography
                variant={TypographyVariants.h1}
                className={'text-center md:text-[22px] md:leading-[30px]'}
                typographyNext
            >
                <Trans i18nKey="weight-metabolic-rate-title" />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={'text-center !my-4'}>
                <Trans i18nKey="weight-metabolic-rate-subtitle-1" />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={'text-center !my-4'}>
                <Trans i18nKey="weight-metabolic-rate-subtitle-2" />
            </Typography> */}

            <Heading
                title={t('weight-metabolic-rate-title')}
                subtitle={t('weight-metabolic-rate-subtitle-1')}
                subtitle2={t('weight-metabolic-rate-subtitle-2')}
            />
            {/* // todo note slightly different spacings with Header component */}

            <img
                src={pageImages[0]}
                className={'mb-5 w-full max-w-[560px] h-auto md:mb-4'}
                alt="Weight Metabolic Rate"
            />

            <NextButton
                typographyText={t('weight-metabolic-rate-button-text')}
                onClick={handleClick}
            />
        </>
    );
};
