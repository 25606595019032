import { MouseEvent, forwardRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useGetAnswers, useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { ImportantEventDateKeys } from 'constants/important-event-date';

import 'react-datepicker/dist/react-datepicker.css';

import { useStyles } from './styles';
import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';
import { cn } from 'helpers/cn';

export const ImportantEventDate = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();
    const {
        // header,
        // buttonStyles,
        // nextArrowIcon,
        // buttonsWrapper,
        // monthDateWrapper,
        // monthDateStyles,
        calendarStyles,
        // inputWrapper,
        // inputStyles,
        // inputLabel,
        // inputLabelActive,
        // inputImage,
        // skipNextButton,
    } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const [importantDate, setImportantDate] = useState();

    const items = [
        'svg/important-date-calendar/next-arrow.svg',
        'svg/important-date-calendar/prev-arrow.svg',
        'svg/important-date-calendar/calendar-icon.svg',
        'svg/important-date-calendar/cross-icon.svg',
    ];
    const pageImages = useBackgroundImageService(items);

    const handleClick = () => {
        const importantEventDateValue = localStorage.getItem(
            LocalStorageKeys[RoutePath.ImportantEventDate]
        );

        saveAnswers({
            key: LocalStorageKeys[RoutePath.ImportantEventDate],
            itemQuestion: {
                question: t('important-event-date-subtitle'),
                value: importantEventDateValue as string,
            },
        });

        setImportantDate(importantDate);

        localStorage.setItem(ImportantEventDateKeys.importantEventDateSkipped, 'false');
        pageConfiguration.event('RxWeightImportantEventDateClicked', importantEventDateValue);

        pageConfiguration.handleOnPageComplete();
    };

    const handleSkipButtonClick = () => {
        localStorage.setItem(ImportantEventDateKeys.importantEventDateSkipped, 'true');
        pageConfiguration.event('RxWeightImportantEventDateSkipClicked');
        pageConfiguration.handleOnPageComplete();
    };

    // const importantEventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]);
    const importantEventValue = useGetAnswers(LocalStorageKeys[RoutePath.ImportantEvent]); // todo note: stored object instead of string

    // @ts-ignore
    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        const { pageValue: importantEventDate, setPageValue }: any = useLocalStorage({
            key: LocalStorageKeys[RoutePath.ImportantEventDate],
            defaultValue: value,
        });

        useEffect(() => {
            if (value) {
                setPageValue(value);
            }
        }, []);

        const [activeClass, setActiveClass] = useState<boolean>(false);

        const handleReset = (e: MouseEvent<HTMLImageElement>) => {
            e.stopPropagation();

            setPageValue('');
        };

        const handleLabelClick = () => {
            setActiveClass(true);
        };

        return (
            <>
                <div
                    // className={inputWrapper}
                    className={
                        'p-4 mb-3 relative bg-[#FAFAFA] rounded-lg border border-solid border-[#C5C5D1] flex  items-center h-14'
                    }
                    onClick={() => {
                        onClick();
                        setPageValue(value);
                    }}
                >
                    <label
                        onClick={handleLabelClick}
                        // className={
                        //     activeClass || importantEventDate
                        //         ? `${inputLabel} ${inputLabelActive}`
                        //         : inputLabel
                        // }
                        className={cn(
                            'text-base m-0 top-[5px] text-[#5E626B] absolute transition pointer-events-none translate-y-[calc(0.75rem_-_1px)]',
                            {
                                'top-2 text-[.75rem] translate-y-0 leading-4':
                                    activeClass || importantEventDate,
                            }
                        )}
                    >
                        {t('important-event-date-input-label')}
                    </label>
                    <input
                        title="important event date"
                        ref={ref as any}
                        // className={inputStyles}
                        className={
                            'w-[90%] caret-transparent cursor-pointer outline-none border-none bg-none bg-transparent block text-base rounded-lg text-black mt-5 box-border'
                        }
                        value={importantEventDate}
                        readOnly={true}
                    />
                    {!importantEventDate && (
                        <img
                            // className={inputImage}
                            className={'absolute right-4'}
                            src={pageImages[2]}
                            alt={'Calendar'}
                        />
                    )}
                    {importantEventDate && (
                        <img
                            // className={inputImage}
                            className={'absolute right-4'}
                            src={pageImages[3]}
                            onClick={handleReset}
                            alt={'Reset'}
                        />
                    )}
                </div>

                <NextButton onClick={handleClick} disabled={!importantEventDate} />
                <NextButton
                    className={`!bg-transparent !text-foreground hover:!bg-transparent hover:!text-foreground !my-0 `}
                    onClick={handleSkipButtonClick}
                    typographyText={t('important-event-date-button-next')}
                />
            </>
        );
    });

    return (
        <>
            {/* <Typography variant={TypographyVariants.h1} typographyNext>
                {t('important-event-date-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                {t('important-event-date-subtitle', {
                    importantEvent:
                        importantEventValue === 'other'
                            ? t('important-event-date-other-option-event-name')
                            : importantEventValue,
                })}
            </Typography> */}

            <Heading
                title={t('important-event-date-title')}
                subtitle={t('important-event-date-subtitle', {
                    importantEvent:
                        importantEventValue === 'other'
                            ? t('important-event-date-other-option-event-name')
                            : importantEventValue,
                })}
            />

            <DatePicker
                // todo use css module for calendarStyles?
                calendarClassName={calendarStyles}
                renderCustomHeader={({
                    monthDate,
                    decreaseMonth,
                    increaseMonth,
                    increaseYear,
                    // decreaseYear,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div
                        className={
                            'flex justify-between items-center w-full bg-white pt-0 px-4 pb-[14px]'
                        }
                    >
                        <div className={'flex'}>
                            <h1 className={'m-0 !eading-6 text-black'}>
                                {monthDate.toLocaleString('en-US', {
                                    month: 'short',
                                    year: 'numeric',
                                })}
                            </h1>
                            <button
                                className={
                                    'bg-transparent border-none text-black cursor-pointer ml-2'
                                }
                                onClick={increaseYear}
                                disabled={nextMonthButtonDisabled}
                            >
                                <img className={'w-2 h-3'} src={pageImages[0]} alt={'Next'} />
                            </button>
                        </div>

                        <div className={'flex justify-between items-center basis-[15%]'}>
                            <button
                                className={'bg-transparent border-none text-black cursor-pointer'}
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                            >
                                <img src={pageImages[1]} alt={'Prev'} />
                            </button>
                            <button
                                className={'bg-transparent border-none text-black cursor-pointer'}
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                            >
                                <img src={pageImages[0]} alt={'Next'} />
                            </button>
                        </div>
                    </div>
                )}
                selected={importantDate}
                onChange={(date: any) => setImportantDate(date)}
                dateFormat="MMM dd, yyyy"
                useWeekdaysShort
                withPortal
                customInput={<CustomInput />}
                minDate={new Date()}
            />
        </>
    );
};
