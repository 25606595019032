import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { IMultiChoiceButtonOption, MultiChoice } from 'ncomponents/MultiChoiceButton';

import { Heading } from 'ncomponents/Heading';
import { FloatedButton } from 'ncomponents/FloatedButton';
import { NextButton } from 'ncomponents/NextButton';

export const PastAttempts = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PastAttempts];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'fitness-trackers',
            label: t('past-attempts-option-fitness-trackers'),
        },
        {
            value: 'calorie-counting-apps',
            label: t('past-attempts-option-calorie-counting-apps'),
        },
        {
            value: 'gym-exercise',
            label: t('past-attempts-option-gym-exercise'),
        },
        {
            value: 'meal-planning',
            label: t('past-attempts-option-meal-planning'),
        },
        {
            value: 'restrictive-dieting',
            label: t('past-attempts-option-restrictive-dieting'),
        },
        {
            value: 'nutritionist-dietician',
            label: t('past-attempts-option-nutritionist-dietician'),
        },
        {
            value: 'prescription-medication',
            label: t('past-attempts-option-prescription-medication'),
        },
        {
            value: 'food-delivery',
            label: t('past-attempts-option-food-delivery'),
        },
        {
            value: 'professional-therapy',
            label: t('past-attempts-option-professional-therapy'),
        },
        {
            value: 'none',
            label: t('past-attempts-option-none'),
        },
    ];

    // todo check value and update logic for label and value object
    const handleClick = () => {
        // const selectOption = pageValue.map((item: string) => {
        //     const currentOption = multiChoices.find((option) => option.key === item);

        //     return currentOption?.value;
        // });
        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('past-attempts-title'),
                value: pageValue.map((i: IMultiChoiceButtonOption) => i.label),
            },
        });
        // todo check value and update logic for label and value object
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Heading title={t('past-attempts-title')} subtitle={t('past-attempts-subtitle')} />

            <MultiChoice
                options={multiChoices}
                selectedOptions={pageValue}
                onSelect={setPageValue}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
