import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { UserVerificationKeys } from 'constants/user-verification';
import { LocalStorageKeys } from 'constants/local-storage';
import { APP_TYPE_APP, APP_TYPE_PWA } from 'constants/app-name';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
import { detectDeviceType, detectIosDevice } from 'helpers/detect-device-type';

import { MobileContent } from 'pages/welcome/mobile-content';

import ableLogo from 'assets/images/svg/welcome/able-logo.svg';
import vibrantLogo from 'assets/images/svg/welcome/vibrant-logo.svg';
import qrCode from 'assets/images/png/welcome/qr-code.png';
import { Spacer } from '@nextui-org/react';

export const Welcome = () => {
    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const { t } = useTranslation();

    localStorage.setItem(LocalStorageKeys[RoutePath.Email], 'test@test.co'); // todo design note: temp
    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) as string;

    const isUserExists = localStorage.getItem(UserVerificationKeys.isUserExists) === 'true';
    const pwaNativeSplitVariationExp = true;
    const userApp = pwaNativeSplitVariationExp ? APP_TYPE_PWA : APP_TYPE_APP;

    const handleClick = () => {
        const linkDownload = `https://install.ableapp.com/gdEx?deep_link_value=ableapp://email_login?email=${encodeURIComponent(
            email
        )}`;

        window.open(linkDownload, '_blank');

        pageConfiguration.handleOnPageComplete({ userApp });
    };

    useEffect(() => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src =
            'https://banner.urlgeni.us/smart_banners/75f8f156e39bdf64.js?url=' +
            encodeURI(window.location.toString());
        head.appendChild(script);
    }, []);

    const deviceType = detectDeviceType();
    const isDesktop = deviceType !== 'mobile';
    const isIosDevice = detectIosDevice();

    const appStoreLinkComponent = (
        <a
            href="https://apps.apple.com/us/app/vibrantlife-connect/id6451144527"
            target="_blank"
            rel="noreferrer nofollow noopener"
        >
            AppStore
        </a>
    );
    const googlePlayLinkComponent = (
        <a
            href="https://play.google.com/store/apps/details?id=com.able.user"
            target="_blank"
            rel="noreferrer nofollow noopener"
        >
            Google Play
        </a>
    );

    const ableAppName = t('welcome-instructions-title-personalized');

    return (
        <>
            {(isIosDevice || isDesktop) && (
                <div className={`flex justify-center gap-8 my-6 mx-0 md:my-20`}>
                    <img src={ableLogo} alt="Able Logo" />
                    <img src={vibrantLogo} alt="Vibrant Logo" />
                </div>
            )}

            {isDesktop ? (
                <>
                    <h1 className={`!text-[28px] !leading-[33px] !mt-20 mb-4`}>
                        {!isUserExists && t('welcome-title')}
                        {isUserExists && t('welcome-title-if-user-exists')}
                    </h1>
                    <h2
                        className={`!text-base !leading-5 text-[#5E626B] !mt-0 !mx-auto !mb-10 max-w-[544px]`}
                    >
                        {t('welcome-desktop-info')}
                    </h2>

                    <div className={`flex justify-center items-center mt-[34px] py-0 px-4`}>
                        <div className={`max-w-[913px]`}>
                            <h2 className={`!text-xl !leading-7 text-left !mt-0 mx-0 !mb-4`}>
                                {!isUserExists && t('welcome-instructions-title', { ableAppName })}
                                {isUserExists && t('welcome-subtitle-if-user-exists')}
                            </h2>
                            <ol className={`list-decimal list-inside`}>
                                <h2
                                    className={`!text-xl !leading-7 text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4`}
                                >
                                    <li>{t('welcome-list-text-desktop-scan-qr')}</li>
                                </h2>
                                <h2
                                    className={`!text-xl !leading-7 text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4`}
                                >
                                    <li>
                                        <Trans
                                            i18nKey="welcome-list-text-desktop-store-redirect"
                                            components={[
                                                appStoreLinkComponent,
                                                googlePlayLinkComponent,
                                            ]}
                                        />
                                    </li>
                                </h2>
                                <h2
                                    className={`!text-xl !leading-7 text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4`}
                                >
                                    <li>{t('welcome-list-text-desktop-download-app')}</li>
                                </h2>
                                <h2
                                    className={`!text-xl !leading-7 text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4`}
                                >
                                    <li>
                                        {!isUserExists &&
                                            t('welcome-list-text-desktop-open-app', { email })}
                                        {isUserExists &&
                                            t('welcome-list-text-desktop-open-app-if-user-exists', {
                                                email,
                                            })}
                                    </li>
                                </h2>

                                {!isUserExists && (
                                    <h2
                                        className={`!text-xl !leading-7 text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4`}
                                    >
                                        <li>{t('welcome-list-text-desktop-create-password')}</li>
                                    </h2>
                                )}
                            </ol>
                        </div>

                        <img
                            className={`w-[200px] h-[200px] self-start ml-5`}
                            src={qrCode}
                            alt="customer"
                        />
                    </div>
                </>
            ) : (
                <MobileContent
                    isIosDevice={isIosDevice}
                    isUserExists={isUserExists}
                    email={email}
                    handleClick={handleClick}
                />
            )}
            {/* // todo design note: temp */}
            <Spacer y={16} />
            <MobileContent
                isIosDevice={isIosDevice}
                isUserExists={isUserExists}
                email={email}
                handleClick={handleClick}
            />
            <MobileContent
                isIosDevice={true}
                isUserExists={isUserExists}
                email={email}
                handleClick={handleClick}
            />
        </>
    );
};
