import { Button as NextUIButton, ButtonProps as NextUIButtonProps } from '@nextui-org/react';
import { cn } from 'helpers/cn';

export interface IButtonProps extends NextUIButtonProps {
    text?: string;
    typographyText?: string;
    buttonClass?: string;
}

export const Button = ({ children, text, typographyText, buttonClass, ...props }: IButtonProps) => {
    return (
        <NextUIButton
            className={cn(
                'bg-btnPrimary relative px-4 py-3 max-w-[400px] min-w-[120px] w-full h-12 border-none text-black rounded-lg cursor-pointer gap-0 min-h-auto p-4 text-large hover:bg-btnHover dark:bg-content2 dark:text-foreground dark:hover:bg-content3 hover:text-black hover:!opacity-100 text-wrap whitespace-normal disabled:opacity-100',
                buttonClass
            )}
            {...props}
        >
            {children}
            {text ||
                (typographyText && (
                    <span className="font-semibold text-lg leading-[26px]">
                        {typographyText || text}
                    </span>
                ))}
        </NextUIButton>
    );
};
