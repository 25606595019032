import { CheckIcon } from '@heroicons/react/20/solid';
import { Progress, ProgressProps } from '@nextui-org/react';
import { cn } from 'helpers/cn';
import { FC } from 'react';

// type LoaderColor =
//     | 'progressBody'
//     | 'progressBodySecondary'
//     | 'progressHabit'
//     | 'progressHabitSecondary'
//     | 'progressNutrition'
//     | 'progressNutritionSecondary';

interface LinearProgressWithTitleAndLabelProps extends ProgressProps {
    // indicatorThemeColor?: LoaderColor;
    // labelBgThemeColor?: LoaderColor;
    checkmarkBgColor?: string;
    checkmarkColor?: string;
    indicatorBgColor?: string;
}

export const LinearProgressWithTitleAndLabel: FC<LinearProgressWithTitleAndLabelProps> = ({
    // indicatorThemeColor = 'progressBody',
    // labelBgThemeColor = 'progressBodySecondary',
    checkmarkBgColor,
    checkmarkColor,
    indicatorBgColor,
    maxValue = 100,
    ...props
}) => {
    return (
        <Progress
            valueLabel={
                props.value === maxValue && (
                    <div
                        className={
                            // cn(`rounded-full w-6 h-6 flex items-center justify-center bg-${labelBgThemeColor})`
                            cn(
                                `rounded-full w-6 h-6 flex items-center justify-center bg-progressBodySecondary`,
                                checkmarkBgColor
                            )
                        }
                    >
                        <CheckIcon
                            fill="none"
                            stroke="currentColor"
                            className={cn(`text-progressBody stroke-[1.5]`, checkmarkColor)}
                            // className={`text-${indicatorThemeColor}`}
                            width={16}
                            height={16}
                        />
                    </div>
                )
            }
            maxValue={maxValue}
            showValueLabel
            // classNames={{ track: '!h-1.5 mt-2', indicator: `!bg-${indicatorThemeColor}` }}
            classNames={{
                track: '!h-1.5 mt-2',
                indicator: cn(`bg-progressBody`, indicatorBgColor),
            }}
            {...props}
        />
    );
};
