import { BanPrepaidCardsKeys } from 'constants/payment';

export const getPaymentGeneralErrorKey = (error?: string) => {
    const prepaidBanExperiment = localStorage.getItem(BanPrepaidCardsKeys.banPrepaidCardsEnabled) === 'true'
        && localStorage.getItem(BanPrepaidCardsKeys.banPrepaidCards) === 'true';

    if (prepaidBanExperiment) {
        return 'checkout-general-error-prepaid-card-ban-experiment';
    }

    if (error) {
        return error;
    }

    return 'checkout-general-error';
};
