import { Trans } from 'react-i18next';

import { NextButton } from 'components/next-button';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

export const UserExistsMessage = (): JSX.Element => {
    return (
        <div>
            <h3>
                <Trans i18nKey="checkout-user-exists-message" />
            </h3>

            <NextButton onClick={handleRedirectToPwa} />
        </div>
    );
};
