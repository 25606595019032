import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WeightChart } from 'components/charts/weight-chart';

export const GraphBlock: FC<{
    weightGoal: number;
    unit: string;
    weightPlan: number[];
    monthsPlan: string[];
    importantEventTitle: string;
}> = ({ weightGoal, unit, weightPlan, monthsPlan, importantEventTitle }) => {
    const { t } = useTranslation();

    return (
        <div
            className={
                'bg-[#FFFFFF] dark:bg-content2 rounded-lg flex flex-col py-4 px-1 my-4 mx-0 md:my-6 md:mx-0 md:p-6'
            }
        >
            <h2
                className={
                    'text-xs leading-4 mt-0 mx-0 mb-[10px] md:text-sm md:leading-[18px] md:mt-0 md:mx-0 md:mb-2'
                }
            >
                {t('pre-paywall-graph-block-pretitle')}
            </h2>

            <h1
                className={
                    'text-lg leading-[26px] mt-0 mx-0 mb-4 md:text-[22px] md:leading-7 md:mt-0 md:mx-0 md:mb-8'
                }
            >
                {t('pre-paywall-graph-block-title')}
                <div
                    className={
                        'bg-[#DDF3ED] dark:bg-[#36B992] rounded-md py-0 px-[6px] inline-block ml-2'
                    }
                >
                    {weightGoal} {unit}
                </div>
                <br />
                {t('pre-paywall-graph-block-title-2')} {importantEventTitle}
            </h1>

            <WeightChart
                weightPlan={weightPlan}
                monthsPlan={monthsPlan}
                unit={unit}
                goalWeight={weightGoal}
            />

            <h3 className={'py-0 px-4 md:text-lg mt-6 md:leading-[26px] md:p-0'}>
                {t('pre-paywall-graph-block-info')}
            </h3>
        </div>
    );
};
