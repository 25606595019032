// import React, { useContext, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

// import { BaseContext } from 'context/base.context';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
// import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

export const Congrats = () => {
    const { t } = useTranslation();

    // const navigate = useNavigate();

    // const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    // const pwaNativeSplitVariationExp = true;

    // todo design note: temp off
    // useEffect(() => {
    //     setTimeout(() => pwaNativeSplitVariationExp ? handleRedirectToPwa() : pageConfiguration.handleOnPageComplete(), 4000);
    // }, [navigate]);

    return (
        <div>
            <div className={'h-screen flex flex-col justify-center items-center text-center'}>
                <h1
                    className={'!text-lg md:!text-lg !leading-6 md:!leading-6 !mt-0 !mb-3 md:!mb-3'}
                >
                    {t('congrats-title')}
                </h1>
                <h2 className="!text-base mt-0 whitespace-pre-line max-[320px]:!text-[15px] max-[280px]:!text-[13px]">
                    <Trans i18nKey="congrats-description" />
                </h2>
            </div>
        </div>
    );
};
