import { RoutePath } from 'routes/route-path.constant';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { ListItem } from 'pages/how-sema-works/content-v1/list-item';

export const ContentV1 = () => {
    const isDesktop = useDesktopMediaWatcher();

    const pageImages = useBackgroundImageService([
        'webp/how-sema-works/man-body.webp',
        'webp/how-sema-works/man-body-mobile.webp',
        'webp/how-sema-works/woman-body.webp',
        'webp/how-sema-works/woman-body-mobile.webp',
    ]);

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);

    const getPageImageIndex = () => {
        if (userGender === 'female') {
            return isDesktop ? 2 : 3;
        } else {
            return isDesktop ? 0 : 1;
        }
    };

    return (
        <div className={'flex justify-center items-center mb-11 md:mb-[60px]'}>
            <img
                src={pageImages[getPageImageIndex()]}
                alt="Human Body"
                className="w-full max-w-[226px] md:max-w-[331px] max-[375px]:max-w-[180px]"
            />

            <div className="h-full flex flex-col gap-[26px] md:gap-[50px] md:mt-[60px] max-[428px]:gap-[30px] max-[428px]:min-w-[40%] max-[390px]:gap-[26px] max-[390px]:min-w-[40%] max-[375px]:gap-[26px] max-[375px]:min-w-[45%] max-[360px]:gap-4">
                <ListItem textKeys={['how-sema-works-list-item-1', 'how-sema-works-list-item-2']} />
                <ListItem textKeys={['how-sema-works-list-item-3', 'how-sema-works-list-item-4']} />
                <ListItem
                    textKeys={[
                        'how-sema-works-list-item-5',
                        'how-sema-works-list-item-6',
                        'how-sema-works-list-item-7',
                    ]}
                />
                <ListItem textKeys={['how-sema-works-list-item-8']} />
            </div>
        </div>
    );
};
