import React, { ChangeEvent, FC, FocusEvent, useState } from 'react';

// import { TypographyVariants } from 'constants/typography-variants';
// import { Typography } from 'components/typography';
// import closeIconSvg from '../../assets/images/svg/close.svg';

import { Input as NextInput, InputProps as NextUIInputProps } from '@nextui-org/react';
import { Controller, useFormContext, ControllerProps } from 'react-hook-form';

// const isValueSet = (value: string | number | undefined, type = 'text'): boolean => {
//     if (type === 'number') {
//         return Number.isInteger(parseInt(value as string));
//     }
//     return !!value;
// };

// We can't use components from common components folder,
// because these one have different design and behave bit differently
// export interface FormInputProps {
//     controller: ControllerProps,
//     input: NextUIInputProps
// }
//
// export function FormInput({ controller, input }: FormInputProps) {
//
//     const {
//         register,
//         formState: { errors },
//         getValues,
//         setValue,
//     } = useFormContext();
//
//     return (
//         <Controller
//             {...controller}
//             control={methods.control}
//             name="email"
//             render={({ field }) => (
//                 <Input
//                     {...field}
//                     {...input}
//                 />
//             )}
//         />
//     );
// }

// export interface InputProps extends NextUIInputProps {
export interface InputProps {
    value?: string | number;
    type?: string;
    label?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;

    onFocus?: (e: FocusEvent) => void;
    onBlur?: (e: FocusEvent) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement> | Event) => void;

    inputWrapperClassName?: string;
    inputLabelClassName?: string;

    inputClassName?: string;

    error?: boolean;
    errorMessage?: string;

    startAdornment?: string;
    endAdornment?: string;
    closeIcon?: boolean;

    name?: string;

    nightTheme?: boolean;
}

export const Input: FC<InputProps> = ({
    value,
    type,
    label,
    error,
    errorMessage, // todo design note: added errorMessage prop
    onFocus,
    onChange,
    startAdornment,
    endAdornment,
    onBlur,
    inputWrapperClassName,
    inputLabelClassName,
    inputClassName,
    closeIcon,
    nightTheme,
    ...props
}) => {
    // todo design note, recheck all style conditions

    // const inputEl = useRef({} as HTMLInputElement);

    // const [activeClass, setActiveClass] = useState(false);

    // const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    //     if (disabled) return;

    //     setActiveClass(true);

    //     if (onFocus) {
    //         onFocus(e);
    //     }
    // };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
    };

    // const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    //     setActiveClass(false);

    //     if (onBlur) {
    //         onBlur(e);
    //     }
    // };

    // const handleClean = () => {
    //     const evt = new Event('change');
    //     const { current } = inputEl;

    //     current.value = '';

    //     const event = {
    //         ...evt,
    //         target: current,
    //         currentTarget: current,
    //     };

    //     if (onChange) {
    //         onChange(event);
    //     }

    //     current.focus();
    // };

    const disabled = false;

    const inputType = type ? type : 'text';

    // const combinedInputClass = `${input} ${disabled ? inputDisabledClass : ''} ${
    //     nightTheme ? inputDark : ''
    // } ${inputClassName}`;

    // const combinedInputWrapperClass = `${inputWrapper} ${inputWrapperClassName} ${
    //     disabled ? inputDisabledClass : ''
    // } ${nightTheme ? inputWrapperDark : ''} ${
    //     activeClass ? (nightTheme ? inputActiveClassDark : inputActiveClass) : ''
    // } ${error ? inputErrorClass : ''}`;

    // const combinedInputLabelClass = `${inputLabelClass} ${inputLabelClassName} ${
    //     isValueSet(value, inputType) || activeClass ? inputLabelActiveClass : ''
    // } ${error ? inputLabelErrorClass : ''} ${nightTheme ? inputLabelDark : ''}`;

    // const isEndAdornmentShown = activeClass || error || value;

    return (
        <>
            {/* <div className={combinedInputWrapperClass}>
                {startAdornment && (
                    <Typography variant={TypographyVariants.inputStartAdornment}>
                        {startAdornment}
                    </Typography>
                )}

                {label && (
                    <Typography
                        variant={TypographyVariants.inputLabel}
                        className={combinedInputLabelClass}
                    >
                        {label} {endAdornment && !isEndAdornmentShown ? `- ${endAdornment}` : ''}
                    </Typography>
                )}
                <input
                    ref={inputEl}
                    type={inputType}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    className={combinedInputClass}
                    value={value}
                    onChange={handleChange}
                    name={props.name}
                    min={0}
                    {...props}
                />

                {endAdornment && isEndAdornmentShown && (
                    <Typography
                        variant={TypographyVariants.inputEndAdornment}
                        className={inputEndAdornmentClass}
                    >
                        {endAdornment}
                    </Typography>
                )}

                {closeIcon && value && (
                    <img
                        className={inputCloseIconClass}
                        onClick={handleClean}
                        src={closeIconSvg}
                        alt="Reset"
                    />
                )}
            </div> */}

            {/* NextUI input */}
            {/* // TODO ui design note: check if need adjustment for adornment & close icon, etc. support */}
            <div className="">
                <NextInput
                    // variant="bordered" // todo design note: check why not accurate bordered style
                    // size="lg" // default md, but to match font-size of onboarding can use "lg" but then input itself becomes larger than current onboarding custom input
                    classNames={
                        {
                            // input: 'text-base', // todo note: overwrites default NextUI input text size to match current onboarding input size of 1rem if keep input size="sm" and want larger font size
                        }
                    }
                    startContent={
                        startAdornment && (
                            <span className="text-sm text-foreground">{startAdornment}</span>
                        )
                    }
                    endContent={
                        endAdornment && (
                            <span className="text-sm text-foreground">{endAdornment}</span>
                        )
                    }
                    isClearable={closeIcon}
                    radius="sm"
                    isDisabled={disabled}
                    type={inputType}
                    min={0}
                    label={label}
                    value={String(value)}
                    onChange={handleChange}
                    // isInvalid={error}
                    // errorMessage={}
                    // todo design note: check error state logic, flash of red error msg when enter height as 1 feet, delete, then enter 2 and when focus moves to inches: flash of red input error msg on /body-height
                    isInvalid={Boolean(errorMessage) || error}
                    errorMessage={errorMessage}
                    {...props}
                />
            </div>
        </>
    );
};
