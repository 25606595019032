import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLastLocation } from 'hooks/use-last-location.hook';
// import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'ncomponents/NextButton';

import { ReactComponent as NoMedicationsIcon } from 'assets/images/svg/decline-user/no-medication.svg';

export const DeclineUser = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const lastLocation = useLastLocation();

    const { pageConfiguration } = useContext(BaseContext);

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);
    const userCurrentBodyHeightFt = localStorage.getItem(
        MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt
    );
    const userCurrentBodyHeightInch = localStorage.getItem(
        MultipleLocalStorageKeys.bodyHeightWeightKeys.heightInches
    );
    const userCurrentBodyWeight = localStorage.getItem(
        MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs
    );
    const userGoalWeight = localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight]);
    const unitSystem = localStorage.getItem(
        MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem
    );

    let url = `${process.env.REACT_APP_ABLE_UI_URL}/body-age?gender=${userGender}&currentbodyheight=${userCurrentBodyHeightFt}.${userCurrentBodyHeightInch}&currentbodyweight=${userCurrentBodyWeight}&goalbodyweight=${userGoalWeight}&bodyHeightMetricSystem=${unitSystem}`;

    const handleClick = () => {
        localStorage.clear();
        pageConfiguration.handleOnPageComplete();
    };

    const handleChangeAnswersClick = () => {
        pageConfiguration.event('RxWeightChangeAnswersClicked');
        // @TODO Think about how we can optimize this logic for using in GrowthBook experiments
        // In case user entered not eligible answer on body-weight page, the DeclineUser component
        // is shown after body-ideal-weight page BUT redirect should be to body-weight page
        lastLocation?.pathname === RoutePath.BodyIdealWeight
            ? navigate(RoutePath.BodyWeight)
            : navigate(-1);
    };

    // todo design note: different usage for svg to handle dark theme with currentColor
    // const items = ['svg/decline-user/no-medication.svg'];
    // const pageImages = useBackgroundImageService(items);

    return (
        <>
            <div className={'w-full flex justify-center mt-8 md:mt-20 md:mb-10'}>
                {/* <img src={pageImages[0]} alt="Without Medications Icon" /> */}
                <NoMedicationsIcon className="text-foreground" />
            </div>

            {/* <Typography
                variant={TypographyVariants.h1}
                className={'text-left mb-4 md:text-xl md:text-center md:!mb-10'}
            >
                <Trans i18nKey="decline-user-title" />
            </Typography> */}

            <h1 className={'text-left mb-4 md:text-xl md:text-center md:!mb-10'}>
                {t('decline-user-title')}
            </h1>

            {/* <Typography
                variant={TypographyVariants.h2}
                className={
                    'font-normal text-left mt-4 mb-3 md:text-xl md:text-center md:!mt-4 md:!mb-14'
                }
            >
                <Trans i18nKey="decline-user-description" components={[<strong></strong>]} />
            </Typography> */}

            <h2
                className={
                    'font-normal text-left mt-4 mb-3 md:text-xl md:text-center md:!mt-4 md:!mb-14'
                }
            >
                <Trans i18nKey="decline-user-description" components={[<strong></strong>]} />
            </h2>

            <div className={'w-full flex justify-center'}>
                <NextButton
                    as="a"
                    href={url}
                    target="_blank"
                    rel="noreferrer nofollow"
                    typographyText={t('decline-user-next-button')}
                    onClick={handleClick}
                />
            </div>

            <NextButton
                className={
                    '!text-btnSecondaryPrimary hover:text-btnSecondaryHover hover:bg-transparent dark:bg-transparent dark:hover:bg-transparent -mt-3 mx-0 mb-6 bg-transparent md:-mt-4 md:mx-0 md:mb-6'
                }
                typographyText={t('decline-user-next-change-button')}
                onClick={handleChangeAnswersClick}
            />
        </>
    );
};
