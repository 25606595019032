import { useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useLoadingProgress } from 'hooks/use-loading-progress.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { ThinProgressBar } from 'components/progress-bar-thin';
import { ProgressBar } from 'ncomponents/ProgressBar/ProgressBar';

import { Heading } from 'ncomponents/Heading';

export const AnalyzeResponses = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = useCallback(() => {
        pageConfiguration.handleOnPageComplete();
    }, []);

    const { progress } = useLoadingProgress({
        onDone: handleClick,
        // onDone: () => {},
    });

    const items = ['svg/party-icon.svg', 'svg/analyze-responses/weight-loss-with-able-chart.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            {/* <Typography variant={TypographyVariants.h1} className={title}>
                <Trans
                    i18nKey={'analyze-responses-title'}
                    components={[<span className={`dark:!text-black ${titleHighlight}`}></span>]}
                />
            </Typography> */}
            {/* <Heading title={t('analyze-responses-title')} /> */}
            {/* // todo fix font weight - even though same family and 600 weight */}
            <Heading
                title={
                    <Trans
                        i18nKey={'analyze-responses-title'}
                        components={[
                            <span
                                className={`dark:!text-black bg-[#CAF3E7] px-[2px] md:mt-3 md:inline-block`}
                            ></span>,
                        ]}
                    />
                }
            />
            <div
                className={
                    'flex flex-col justify-center border border-solid border-[#C5C5D1] dark:border-foreground-400 rounded-lg py-4 px-6 mx-auto mb-8 max-w-[358px] md:mb-10'
                }
            >
                <div
                    className={
                        'flex justify-between items-end w-4/5 mb-4 max-[500px]:w-[85%] max-[320px]:w-[90%]'
                    }
                >
                    <h2 className={'!leading-[15px] font-semibold !m-0'}>
                        <Trans i18nKey={'analyze-responses-weight-loss-with-able-chart-title'} />
                    </h2>
                    <div className={'flex items-baseline'}>
                        <h1 className={'!text-4xl !leading-[25px] text-[#2B9475] !m-0'}>
                            <Trans
                                i18nKey={
                                    'analyze-responses-weight-loss-with-able-chart-value-title'
                                }
                            />
                        </h1>
                        <img
                            src={pageImages[0]}
                            className={'h-[30px] w-[30px] ml-2'}
                            alt="Party Icon"
                        />
                    </div>
                </div>
                <img src={pageImages[1]} alt="Chart" />
                <h3 className={'leading-[22px] text-[#5E626B] text-left mt-4'}>
                    <Trans i18nKey={'analyze-responses-weight-loss-with-able-chart-info'} />
                </h3>
            </div>
            <p className={`mb-3 text-base font-normal leading-6 text-center text-foreground`}>
                {t('analyze-responses-title-analyzing')}
            </p>
            {/* <ThinProgressBar
                progress={progress}
                className={'!h-12 max-w-[358px] rounded-lg my-0 mx-auto [&>div]:rounded-lg'}
            /> */}
            <ProgressBar
                progress={progress}
                classNames={{
                    indicator: 'rounded-lg',
                    track: 'h-12 rounded-lg',
                }}
                className="max-w-[358px] mx-auto"
                showValueLabel
            />
        </>
    );
};
