import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NextButton } from 'components/next-button';

import { cn } from 'helpers/cn';

interface MobileContentProps {
    isIosDevice: boolean;
    isUserExists: boolean;
    email: string;
    handleClick: () => void;
}

export const MobileContent: FC<MobileContentProps> = ({
    isIosDevice,
    isUserExists,
    email,
    handleClick,
}) => {
    const { t } = useTranslation();

    const welcomeTitleKey = isIosDevice ? 'welcome-title' : 'welcome-title-android';
    const appName = isIosDevice
        ? t('welcome-list-text-mobile-download-app-ios-name')
        : t('welcome-instructions-able-app-name');
    const ableAppName = isIosDevice ? null : t('welcome-instructions-able-app-name');
    const downloadButtonAppName = isIosDevice
        ? t('welcome-button-next-ios-app-name')
        : t('welcome-instructions-able-app-name');

    return (
        <div className={'flex flex-col justify-center items-center py-0 px-4'}>
            <h1
                className={cn(``, {
                    '!m-0': isIosDevice,
                    '!m-4': !isIosDevice,
                })}
            >
                {!isUserExists && t(welcomeTitleKey)}
                {isUserExists && t('welcome-title-if-user-exists')}
            </h1>
            {isIosDevice && (
                <h2
                    className={
                        '!text-base text-[#5E626B] !mt-0 !mx-0 !mb-6 pt-0 px-0 pb-6 border-b border-solid border-[#F0F0FF]'
                    }
                >
                    {t('welcome-subtitle-ios')}
                </h2>
            )}

            <div className={'max-w-[560px] mb-6'}>
                <h2
                    className={
                        '!text-lg !leading-[26px] text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4'
                    }
                >
                    {!isUserExists && t('welcome-instructions-title', { ableAppName })}
                    {isUserExists && t('welcome-subtitle-mobile-if-user-exists')}
                </h2>
                <ol className={'list-decimal list-inside'}>
                    <h2
                        className={
                            '!text-lg !leading-[26px] text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4'
                        }
                    >
                        <li>{t('welcome-list-text-mobile-download-app', { appName })}</li>
                    </h2>
                    <h2
                        className={
                            '!text-lg !leading-[26px] text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4'
                        }
                    >
                        <li>
                            {!isUserExists && t('welcome-list-text-mobile-open-app', { email })}
                            {isUserExists &&
                                t('welcome-list-text-mobile-open-app-if-user-exists', { email })}
                        </li>
                    </h2>

                    {!isUserExists && (
                        <h2
                            className={
                                '!text-lg !leading-[26px] text-left break-words !m-0 [&_a]:text-[#1375D6] [&_a]:underline [&_a]:underline-offset-4'
                            }
                        >
                            <li>{t('welcome-list-text-mobile-create-password')}</li>
                        </h2>
                    )}
                </ol>
            </div>

            {isUserExists && (
                <h2>
                    className={'text-left !mb-3'}
                    {t('welcome-text-mobile-suggestion-if-user-exists')}
                </h2>
            )}

            {isIosDevice && (
                <h3 className={'text-xs font-semibold text-[#5E626B] !mt-0 mx-0 !-mb-1'}>
                    {t('welcome-ios-info')}
                </h3>
            )}

            <NextButton
                bottomMargin
                onClick={handleClick}
                typographyText={t('welcome-button-next', { downloadButtonAppName })}
            />
        </div>
    );
};
