import { useNavigate } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';

// import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';

import { ScrollToTop } from 'helpers/scroll-to-top';

import { AppThemeProvider } from 'context/theme-provider';
import { LastLocationProvider } from 'context/last-location-provider';
import { AppProvider } from 'context/AppContext';

import { RouterConfig } from 'routes';

import { PageConfigurationService } from 'services/page-configuration.service';

import { Content } from '../content';
import { BackgroundLoadingImages } from '../../services/background-images.service';
import { ImagePreloadingProvider } from '../../context/image-provider/ImageProvider';

// import { FUNNEL_VERSION } from 'constants/app-name';
// import { GtagKeys } from 'constants/analytics';

// import { useUserGeolocation } from 'hooks/use-user-geolocation.hook';
// import { useGtagClientId } from 'hooks/use-gtag-client-id';

export const pageConfiguration = new PageConfigurationService();

// const DEFAULT_DATA = {
//     event_label: '<MedicationLoseWeight>',
//     event_funnel_version: FUNNEL_VERSION,
//     event_release_version: process.env.REACT_APP_RELEASE_VERSION,
// };

// const growthbook = new GrowthBook({
//     apiHost: 'https://cdn.growthbook.io',
//     clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
//     enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
//     subscribeToChanges: true,
//     trackingCallback: (experiment, result) => {
//         console.log('[Experiment]', {
//             experimentId: experiment.key,
//             variationId: result.key,
//         });

//         const params = Object.assign(DEFAULT_DATA, {
//             event_category: 'experiment',
//             experiment_id: experiment.key,
//             variation_id: result.key,
//             gb_user_id: localStorage.getItem(GtagKeys.gtagClientId),
//         });

//         window.gtag('event', 'experiment_viewed', params);
//     },
//     onFeatureUsage: (featureKey, result) => {
//         const params = Object.assign(DEFAULT_DATA, {
//             event_category: 'feature',
//             feature_key: featureKey,
//             result: result,
//             gb_user_id: localStorage.getItem(GtagKeys.gtagClientId),
//         });

//         window.gtag('event', 'feature_viewed', params);

//         console.log('[Feature]', { featureKey, result: result.value });
//     },
// });

function Index() {
    // const { gtagClientId } = useGtagClientId();
    // const { countryCode } = useUserGeolocation();

    // useEffect(() => {
    //     growthbook.loadFeatures();

    //     if (pageConfiguration) {
    //         pageConfiguration.event('RxWeightFunnelStartViewed');
    //     }
    // }, []);

    // useEffect(() => {
    //     if (gtagClientId && countryCode) {
    //         growthbook.setAttributes({
    //             id: gtagClientId,
    //             user_id: gtagClientId,
    //             client_id: gtagClientId,
    //             device_id: navigator?.userAgent,
    //             country: countryCode,
    //             device_language:
    //                 window.navigator.language?.toLowerCase() ||
    //                 window.navigator.userLanguage?.toLowerCase ||
    //                 'en',
    //             browser: navigator.userAgent,
    //             url: window.location.host,
    //         });
    //     }
    // }, [gtagClientId, countryCode]);

    const navigate = useNavigate();

    return (
        <BaseContext.Provider
            value={{
                // countryCode,
                pageConfiguration,
            }}
        >
            <ImagePreloadingProvider
                images={BackgroundLoadingImages().images.map(i => ({ key: i.key, pathUrl: `${window.location.origin}${i.pathUrl}` }))}>
                <AppThemeProvider>
                    {/* <GrowthBookProvider growthbook={growthbook}> */}
                    {/* <BrowserRouter> */}
                    <NextUIProvider navigate={navigate}>
                        <NextThemesProvider
                            attribute="class"
                            defaultTheme="light"
                            themes={['light', 'dark']}
                        >
                            <ScrollToTop />
                            <AppProvider>
                                <Content>
                                    <LastLocationProvider>
                                        <RouterConfig />
                                    </LastLocationProvider>
                                </Content>
                            </AppProvider>
                        </NextThemesProvider>
                    </NextUIProvider>
                    {/* </BrowserRouter> */}
                    {/* </GrowthBookProvider> */}
                </AppThemeProvider>
            </ImagePreloadingProvider>
        </BaseContext.Provider>
    );
}

export default Index;
