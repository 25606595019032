export type TypographyType = {
    fontFamily: string;
    fontWeight: number;
    fontSize: string;
    fontStyle?: string;
    lineHeight: string;
    textAlign: string;
    color: string;
    margin?: string;
    letterSpacing?: string;
    transform?: string;
    pointerEvents?: string;
    top?: string;
    left?: string;
    position?: string;
    transition?: string;
    marginTop?: string;
    maxWidth?: string;
    whiteSpace?: string;
};

const h1: TypographyType & { [key: string]: {} } = {
    'fontFamily': 'SF Pro Text',
    'fontWeight': 600,
    'fontSize': '20px',
    'lineHeight': '28px',
    'textAlign': 'center',
    'color': '#010101',
    'margin': '32px 0',

    '@media only screen and (min-width: 768px)': {
        fontSize: '22px',
        lineHeight: '30px',
        margin: '80px 0 40px',
    },
};

const h2: TypographyType & { [key: string]: {} } = {
    'fontFamily': 'SF Pro Text',
    'fontWeight': 400,
    'fontSize': '18px',
    'lineHeight': '26px',
    'textAlign': 'center',
    'letterSpacing': '-0.0024em',
    'color': '#010101',
    'margin': '16px 0 32px',

    '@media only screen and (min-width: 768px)': {
        fontSize: '20px',
        lineHeight: '28px',
        margin: '16px 0 40px',
    },
};

const h3: TypographyType = {
    fontFamily: 'SF Pro Text',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#010101',
    margin: '0 auto',
};

const button: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    lineHeight: '26px',
    fontWeight: 600,
    fontSize: '18px',
};

const choiceButton: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '-0.0024em',
};

const multiChoiceButton: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '-0.0024em',
    textAlign: 'left',
};

const input: Partial<TypographyType> = {};

const inputStartAdornment: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    color: '#010101',
    fontSize: '1rem',
    margin: '20px 0 0 0',
    lineHeight: '1.5rem',
};

const inputEndAdornment: Partial<TypographyType> = {
    ...inputStartAdornment,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#868C99',
};

const inputLabel: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    fontWeight: 400,
    fontSize: '16px',
    margin: '0px',
    top: '5px',
    color: '#5E626B',
    position: 'absolute',
    transform: 'translateY(calc(0.75rem - 1px))',
    transition: 'all 0.2s ease 0s',
    lineHeight: '24px',
    pointerEvents: 'none',
};

const inputLabelDark: Partial<TypographyType> = {
    color: '#757575',
};

const inputLabelActive: Partial<TypographyType> = {
    ...inputLabel,
    top: '8px',
    fontSize: '.75rem',
    transform: 'translateY(0px)',
    fontWeight: 400,
    lineHeight: '1rem',
    position: 'absolute',
};

const inputLabelError: Partial<TypographyType> = {
    ...inputLabelActive,
    color: '#FF4D2B',
};

const validatedInputError: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#FF4D2B',
    margin: '4px 0 0 16px',
};

const tipPage: Partial<TypographyType> & { [key: string]: {} } = {
    ...h1,
    'display': 'inline-block',
    'fontWeight': 400,
    'color': '#010101',
    'textAlign': 'left',
    'margin': 'auto',
    'whiteSpace': 'pre-line',

    '@media only screen and (min-width: 768px)': {
        margin: '-8px 0 0',
    },
};

const tab: Partial<TypographyType> = {
    fontFamily: 'SF Pro Text',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '-0.08px',
    textAlign: 'center',
};

export const TypographyStyles = {
    h1,
    h2,
    h3,
    button,
    choiceButton,
    multiChoiceButton,
    input,
    inputStartAdornment,
    inputEndAdornment,
    inputLabel,
    inputLabelActive,
    inputLabelError,
    inputLabelDark,
    validatedInputError,
    tipPage,
    tab,
};
