import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { IMultiChoiceButtonOption, MultiChoice } from 'ncomponents/MultiChoiceButton';

import { NextButton } from 'ncomponents/NextButton';
import { FloatedButton } from 'ncomponents/FloatedButton';
import { Heading } from 'ncomponents/Heading';

export const FamilyWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.FamilyWeight];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const options: IMultiChoiceButtonOption[] = [
        {
            value: 'mother',
            label: t('family-weight-option-mother'),
        },
        {
            value: 'father',
            label: t('family-weight-option-father'),
        },
        {
            value: 'sibling',
            label: t('family-weight-option-sibling'),
        },
        {
            value: 'grandparent',
            label: t('family-weight-option-grandparent'),
        },
        {
            value: 'child',
            label: t('family-weight-option-child'),
        },
        {
            value: 'uncle-aunt',
            label: t('family-weight-option-uncle-aunt'),
        },
        {
            value: 'cousin',
            label: t('family-weight-option-cousin'),
        },
        {
            value: 'none',
            label: t('family-weight-option-none'),
        },
    ];

    const handleClick = () => {
        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('family-weight-title'),
                value: pageValue.map((i: IMultiChoiceButtonOption) => i.label),
            },
        });
        pageConfiguration.handleOnPageComplete(pageValue); // todo check value if should store array of objects or strings
    };

    return (
        <>
            <Heading title={t('family-weight-title')} />

            <MultiChoice options={options} selectedOptions={pageValue} onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
            <br />
        </>
    );
};
