// [project]_[version]_[trial price]_[trial period]_[subscription price]_[subscription charge]_[subscription charge period (day | month)]_[currency code]
// amount - devided by 100 it's price
export const PLAN_VARIATION_0 = {
    title: 'Able RX - Weight Loss',
    key: 'ablerx_v1_2000_7_7900_month_USD',
    amount: 2000,
    subscriptionAmount: 7900,
    trialPeriod: 7,
    subscriptionPeriod: 30, /* chargePeriod - numbers of days or month, answer for - How often user needs to be billed (X Days) */
    chargePeriodUnit: 'day', /* period (X Days) */
    currencyCode: 'USD',
};

export const PLAN_VARIATION_1 = {
    title: 'Able RX - Weight Loss',
    key: 'ablerx_v1_100_7_7900_month_USD',
    amount: 100,
    subscriptionAmount: 7900,
    trialPeriod: 7,
    subscriptionPeriod: 30, /* chargePeriod - numbers of days or month, answer for - How often user needs to be billed (X Days) */
    chargePeriodUnit: 'day', /* period (X Days) */
    currencyCode: 'USD',
};

export const PLAN_VARIATION_2 = {
    title: 'Able RX - Weight Loss',
    key: 'ablerx_v1_100_7_3900_month_USD',
    amount: 100,
    subscriptionAmount: 3900,
    trialPeriod: 7,
    subscriptionPeriod: 30, /* chargePeriod - numbers of days or month, answer for - How often user needs to be billed (X Days) */
    chargePeriodUnit: 'day', /* period (X Days) */
    currencyCode: 'USD',
};
