import { Palette } from '../index';
import { HeaderStyles } from 'themes/default/header';

export type TipPageType = {
    tipPageWrapper: {
        display: string;
        alignItems: string;
        flexDirection: string;
        background: Palette | string;
        position: string;
        height: string;
        width: string;
        padding: string;
        overflowY: string;
    };
    tipPageIcon: {
        margin: string;
        minHeight: string;
    } & { [key: string]: {} };
};

export const TipPageStyles: TipPageType = {
    tipPageWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#E8F5FD',
        position: 'absolute',
        height: `calc(100vh - ${HeaderStyles.header.height})`,
        width: '100vw',
        padding: '0 16px',
        overflowY: 'scroll',
    },
    tipPageIcon: {
        'margin': '48px 0',
        'minHeight': '64px',

        '@media only screen and (min-width: 768px)': {
            margin: '80px 0 40px',
        },
    },
};
