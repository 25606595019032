import React, {
    DetailedHTMLProps,
    ButtonHTMLAttributes,
    PropsWithChildren,
    ImgHTMLAttributes,
    HTMLAttributes,
} from 'react';
import { Checkbox, ButtonProps as NextUIButtonProps } from '@nextui-org/react';

import { clsx } from 'clsx';

import { INPUT_MAX_LENGTH } from '../../constants/choice-button-text-area-input';

import { Button } from '../Button';
import { cn } from '../../helpers/cn';
import { TextAreaInput } from 'ncomponents/TextAreaInput';
import { MAX_CHARACTERS } from 'constants/text-area-input';
import { useTranslation } from 'react-i18next';
// import { Checkbox } from '../Checkbox';
// import { TextAreaInput } from '../TextAreaInput';
import { useImagePreloadingService } from '../../context/image-provider/ImageProvider';

export interface IMultiChoiceButtonLabelProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    label?: string;
}

// export interface IMultiChoiceButtonImgProps extends DetailedHTMLProps<
export type IMultiChoiceButtonImgProps = DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>;

export interface IMultiChoiceButtonOption extends IMultiChoiceButtonLabelProps {
    key?: string;
    label?: string;
    value: string;
    userAnswer?: string;
    setUserAnswer?: (text: string) => void;
    maxLength?: number;
    image?: string;
}

export interface IMultiChoice {
    options: IMultiChoiceButtonOption[];
    selectedOptions: IMultiChoiceButtonOption[];
    deselectWhenValues?: string[];
    disabledWhenValues?: string[];
    onSelect: (newOptions: IMultiChoiceButtonOption[]) => void;
    textAreaInputLabel?: string;
}

export const MultiChoicesButtonWrapper = ({ children }: PropsWithChildren) => (
    <div className={'flex flex-col gap-3 md:gap-4 mb-3 md:mb-4'}> {children} </div>
);

export const MultiChoicesButtonLabel = ({ label }: IMultiChoiceButtonLabelProps) => (
    <span className={'font-normal leading-6 -tracking-[0.0024em] text-left text-wrap'}>
        {label}
    </span>
);

export const MultiChoicesButtonImg = ({ src, alt }: IMultiChoiceButtonImgProps) => (
    <img src={src} alt={alt} className={'h-8 w-8 ml-auto'} />
);

export const MultiChoice = ({
    options,
    selectedOptions,
    deselectWhenValues = ['none'],
    disabledWhenValues = [],
    onSelect,
    textAreaInputLabel = '',
}: IMultiChoice) => {
    const { getImageByUrl } = useImagePreloadingService();

    // Remove item or add
    const handleClick = (option: IMultiChoiceButtonOption) => {
        let newSelectedOptions = [...selectedOptions];

        if (deselectWhenValues.includes(option.value)) {
            newSelectedOptions = [];
        } else {
            newSelectedOptions = selectedOptions.filter(
                (i: IMultiChoiceButtonOption) => !deselectWhenValues.includes(i.value)
            );
        }

        if (selectedOptions.map((o) => o.value).includes(option.value)) {
            newSelectedOptions = selectedOptions.filter((o) => o.value !== option.value);
        } else {
            newSelectedOptions.push(option);
        }

        onSelect(newSelectedOptions);
    };

    const maxCharacters = MAX_CHARACTERS;

    // Most likely we need to refactor it to CheckboxGroup from NextUI
    return (
        <MultiChoicesButtonWrapper>
            {options.map((option) => {
                const isSelected = selectedOptions.some((o) => o.value === option.value);
                const isDisabled = selectedOptions.some(
                    (o) => disabledWhenValues.includes(o.value) && o.value !== option.value
                );
                const image = option.image ? getImageByUrl(option.image) : null;

                const textAreaInputShown = isSelected && option.setUserAnswer;

                return (
                    <React.Fragment key={option.value}>
                        <Checkbox
                            isDisabled={isDisabled}
                            value={option.value}
                            isSelected={isSelected}
                            radius="none"
                            size="lg"
                            className={cn(
                                'p-4 max-w-none -m-0 bg-btnPrimary text-left hover:!bg-[#B7E3FF] transition-transform-colors-opacity w-full after:bg-red rounded-lg dark:bg-content2 dark:hover:!bg-content3 ',
                                {
                                    'bg-btnActive dark:bg-btnActive dark:hover:!bg-btnActive hover:!bg-btnActive hover:!opacity-100 ':
                                        isSelected,
                                }
                            )}
                            classNames={{
                                wrapper:
                                    'bg-white group-data-[hover=true]:before:bg-white before:!border-0 after:!bg-white',
                                label: cn(
                                    'text-large leading-none font-normal ml-2 text-black dark:!text-foreground',
                                    {
                                        'text-white': isSelected,
                                    }
                                ),
                                icon: '!bg-white text-btnActive !border-0 !outline-0 after:!border-0 before:!border-0 after:!bg-red',
                            }}
                            onClick={() => handleClick(option)}
                        >
                            <MultiChoicesButtonLabel label={option.label} />
                            {image && <MultiChoicesButtonImg src={image} alt={option.label} />}
                        </Checkbox>

                        {textAreaInputShown && (
                            <TextAreaInput
                                onValueChange={option?.setUserAnswer}
                                value={option.userAnswer as string}
                                maxLength={maxCharacters}
                                label={textAreaInputLabel}
                                classNames={{
                                    description: 'text-right',
                                }}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </MultiChoicesButtonWrapper>
    );
};
