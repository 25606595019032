import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { RoutePath } from 'routes/route-path.constant';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'ncomponents/NextButton';
import { Heading } from 'ncomponents/Heading';

export const BeforeAfterMedication = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = [
        'webp/before-after-medication/before-after-medication-female.webp',
        'webp/before-after-medication/before-after-medication-male.webp',
    ];
    const pageImages = useBackgroundImageService(items);

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);
    const imageIndex = userGender === 'female' ? 0 : 1;

    return (
        <div className={'flex flex-col justify-center items-center'}>
            <Heading
                title={t('before-after-medication-title')}
                subtitle={t('before-after-medication-subtitle')}
            />

            <img
                src={pageImages[imageIndex]}
                className={'w-full max-w-[506px] h-auto mb-2 md:mb-4'}
                alt="Before/After"
            />

            <NextButton
                typographyText={t('before-after-medication-button-next')}
                onClick={handleClick}
            />

            <h3 className={'mt-2 md:mt-4'}>
                <Trans i18nKey="before-after-medication-info" />
            </h3>
        </div>
    );
};
