import { cn } from 'helpers/cn';

interface FloatedButtonProps {
    floated?: boolean;
    withGradient?: boolean;
    bottomPadding?: boolean;
    children?: any;
    className?: string;
}

export const FloatedButton = ({
    children,
    floated,
    withGradient,
    bottomPadding,
    className,
}: FloatedButtonProps) => {
    if (!floated) return children;

    return (
        <div
            className={cn(
                `w-full sticky z-[10] bottom-0 left-1/2 flex justify-center items-center`,
                className,
                {
                    'bg-gradient-to-t from-white via-white via-80% to-transparent dark:from-black dark:via-black':
                        withGradient,
                    'px-4 pb-12': bottomPadding,
                }
            )}
        >
            {children}
        </div>
    );
};
