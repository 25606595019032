import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

export const MedicalDirectorCard = () => {
    const pageImages = useBackgroundImageService(['svg/medication/doctor.webp']);

    return (
        <div
            className={`w-[65%] min-w-[257px] flex items-start gap-[10px] p-2 bg-background dark:bg-content2 text-foreground shadow-[0px_0px_12px_rgba(0,0,0,0.2)] rounded-lg mt-4 md:flex-col md:items-center md:self-center md:text-center md:shrink-0 md:w-[278px] md:min-w-0 md:p-3 md:mr-[5px] md:mt-0 md:gap-4`}
        >
            <img
                src={pageImages[0]}
                alt="Medical Director"
                className="w-16 rounded-[4px] md:w-[134px]"
            />
            <div>
                <h1
                    className={`mt-0 mr-0 mb-2 ml-0 text-[12px] leading-4 text-start max-[280px]:text-[10px] max-[280px]:leading-3 md:text-lg md:leading-[22px]`}
                >
                    Dr. Arvind Chakravarthy
                </h1>
                <h2
                    className={`m-0 text-xs leading-4 text-start max-[280px]:text-[10px] max-[280px]:leading-[14px] md:text-base md:leading-5 md:text-center`}
                >
                    Medical Director
                    <br />
                    MD, MBA
                </h2>
            </div>
        </div>
    );
};
