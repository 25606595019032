import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { UserEligibilityKeys } from 'constants/user-eligibility';
import { ExperimentsKeys } from 'constants/experiments';

import {
    getMultiChoiceButtonSelectOptions,
    isNextButtonDisabled,
} from 'helpers/get-multichoice-button-select-options';

import { IMultiChoiceButtonOption, MultiChoice } from 'ncomponents/MultiChoiceButton';

import { Heading } from 'ncomponents/Heading';
import { FloatedButton } from 'ncomponents/FloatedButton';
import { NextButton } from 'ncomponents/NextButton';
import { useAppState } from 'context/AppContext';

export const MedicationIntake = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicationIntake];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicationIntake]}-other-userAnswer`,
        defaultValue: '',
    });

    // const mw_medical_director_changes =
    //     localStorage.getItem(ExperimentsKeys.mw_medical_director_changes) === '1';
    const { user } = useAppState();
    const mw_medical_director_changes = user.experiments.includes(
        ExperimentsKeys.mw_medical_director_changes
    );

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'insulin',
            label: t('medication-intake-option-insulin'),
        },
        {
            value: 'glp-1-receptor-agonists',
            label: t('medication-intake-option-glp-1-receptor-agonists'),
        },
        {
            value: 'sulfonylureas',
            label: t('medication-intake-option-sulfonylureas'),
        },
        {
            value: 'other',
            label: mw_medical_director_changes
                ? t('medication-intake-option-other-medication-not-listed')
                : t('medication-intake-option-other'),
            userAnswer,
            setUserAnswer,
        },
        {
            value: 'none',
            label: mw_medical_director_changes
                ? t('medication-intake-option-none-other-medication')
                : t('medication-intake-option-none'),
        },
    ];

    // todo update logic for label and value object
    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        let userStatus =
            pageValue[0] === 'none'
                ? UserEligibilityKeys.userStatus.approved
                : UserEligibilityKeys.userStatus.needsReview;

        const medicalConditionPageValue = JSON.parse(
            localStorage.getItem(LocalStorageKeys[RoutePath.MedicalCondition]) as string
        );
        if (
            medicalConditionPageValue?.length > 0 &&
            medicalConditionPageValue[0] !== 'none' &&
            userStatus === UserEligibilityKeys.userStatus.approved
        ) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        // todo update logic for label and value object
        saveAnswers({
            key: LocalStorageKeys[RoutePath.MedicationIntake],
            itemQuestion: {
                question: t('medication-intake-title'),
                value:
                    pageValue[0] === 'none'
                        ? multiChoices
                              .filter((multiChoice) => multiChoice.key !== 'none')
                              .map((multiChoice) => `"${multiChoice.value}" - NO`)
                        : selectOption,
            },
            userStatus: {
                question: t('medication-user-eligibility-status-question'),
                value: userStatus,
            },
        });

        // todo update logic for label and value object
        pageConfiguration.handleOnPageComplete(
            pageValue.includes('other')
                ? {
                      pageValue,
                      userAnswer: userAnswer.trim(),
                  }
                : { pageValue }
        );
    };

    return (
        <>
            <Heading
                title={t('medication-intake-title')}
                subtitle={t('medication-intake-subtitle')}
            />

            <MultiChoice
                options={multiChoices}
                selectedOptions={pageValue}
                onSelect={setPageValue}
                textAreaInputLabel={t('medication-intake-text-area-input-label')}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton
                    onClick={handleClick}
                    disabled={
                        mw_medical_director_changes
                            ? isNextButtonDisabled(pageValue, multiChoices)
                            : !pageValue.length
                    }
                />
            </FloatedButton>
        </>
    );
};
