import { FC } from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { BreadcrumbsNavigationVariation1Keys } from 'constants/breadcrumbs-navigation';

import { cn } from 'helpers/cn';

interface BreadcrumbsNavigationVariation1Props {
    activeSection: string;
    completedSections: string[];
}

export const BreadcrumbsNavigationVariation1: FC<BreadcrumbsNavigationVariation1Props> = ({
    activeSection,
    completedSections,
}) => {
    const items = [
        'svg/checkout/breadcrumbs-navigation/navigation-icon-down.svg',
        'svg/checkout/breadcrumbs-navigation/check-icon.svg',
    ];
    const pageImages = useBackgroundImageService(items);

    const sections = [1, 2, 3, 4];
    const sectionsNames = [
        BreadcrumbsNavigationVariation1Keys.healthPlan,
        BreadcrumbsNavigationVariation1Keys.account,
        BreadcrumbsNavigationVariation1Keys.shipping,
        BreadcrumbsNavigationVariation1Keys.payment,
    ];

    return (
        <nav>
            <ol
                className={
                    'flex items-center justify-between w-full mb-6 relative [&>div]:flex [&>div]:flex-col [&>div]:items-center [&>div]:gap-1 before:content-[""] before:absolute before:h-[1px] before:w-4/5 before:z-[-1] before:top-[44%] before:ml-[10%] before:bg-[#C5C5D1] md:mb-10 max-[430px]:before:w-[85%] max-[430px]:before:ml-[10%] max-[540px]:before:w-[90%] max-[540px]:before:ml-[5%] min-[1000px]:before:w-[90%] min-[1000px]:before:ml-[5%]'
                }
            >
                {sections.map((section, index) => {
                    const isSectionActive = activeSection === sectionsNames[index];
                    const isSectionCompleted = completedSections.includes(sectionsNames[index]);

                    return (
                        <div key={section}>
                            <img
                                className={cn('opacity-0', {
                                    'opacity-100': isSectionActive,
                                })}
                                src={pageImages[0]}
                                alt="Arrow Down"
                            />
                            <li
                                className={cn(
                                    'flex justify-center items-center w-6 h-6 rounded-full bg-[#F6F6F6] border-[#C5C5D1] border-solid border-1 [&>h3]:text-sm [&>h3]:text-foreground dark:[&>h3]:text-black',
                                    {
                                        'bg-[#36B992] border-none [&>h3]:text-white dark:[&>h3]:text-white':
                                            isSectionActive ||
                                            (isSectionCompleted && !isSectionActive),
                                    }
                                )}
                            >
                                {isSectionCompleted && !isSectionActive ? (
                                    <img src={pageImages[1]} alt="Check" />
                                ) : (
                                    <h3>{section}</h3>
                                )}
                            </li>
                            <h3
                                className={cn(
                                    'text-xs md:text-xs font-normal text-[#C5C5D1] leading-normal md:leading-normal m-0 md:m-0',
                                    { 'text-[#5E626B] dark:text-foreground': isSectionActive }
                                )}
                            >
                                {sectionsNames[index]}
                            </h3>
                        </div>
                    );
                })}
            </ol>
        </nav>
    );
};
