import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { ExperimentsKeys } from 'constants/experiments';
import { TIMER_INITIAL_MIN, TIMER_INITIAL_SEC } from 'constants/timer';

import { TipBanner } from '../pre-paywall/tip-banner';
import { Program } from './program';
import { Rating } from './rating';
import { VideoCustomerReviews } from './video-customer-reviews';
import { MedicationDescription } from './medication-description';
import { ReviewsIO } from './reviews-io';
import { Expectations } from './expectations';
import { FAQ } from './faq';
import { OfferBannerWithTimer } from './offer-banner-with-timer';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';
import { useAppState } from 'context/AppContext';

export const Medication = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const isDesktop = useDesktopMediaWatcher();

    const { pageValue: minutes, setPageValue: setMinutes } = useLocalStorage({
        key: 'paywall-timer-minutes',
        defaultValue: TIMER_INITIAL_MIN,
    });

    const { pageValue: seconds, setPageValue: setSeconds } = useLocalStorage({
        key: 'paywall-timer-seconds',
        defaultValue: TIMER_INITIAL_SEC,
    });

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    // const mw_timer_on_paywall = true; // ExperimentsKeys.mw_timer_on_paywall
    // const mw_progress_bar_on_checkout_screen = true; // ExperimentsKeys.mw_progress_bar_on_checkout_screen
    const { user } = useAppState();
    const mw_timer_on_paywall = user.experiments.includes(ExperimentsKeys.mw_timer_on_paywall);
    const mw_progress_bar_on_checkout_screen = user.experiments.includes(
        ExperimentsKeys.mw_progress_bar_on_checkout_screen
    );

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.mw_progress_bar_on_checkout_screen,
            mw_progress_bar_on_checkout_screen ? '1' : '0'
        );
    }, [mw_progress_bar_on_checkout_screen]);

    return (
        <>
            {mw_timer_on_paywall ? (
                <OfferBannerWithTimer
                    minutes={minutes}
                    seconds={seconds}
                    setMinutes={setMinutes}
                    setSeconds={setSeconds}
                />
            ) : (
                <TipBanner textKey={'pre-paywall-tip-banner'} />
            )}

            <div className={'bg-[#F6F6F6] dark:bg-content1'}>
                <div
                    className={
                        'max-w-[608px] flex flex-col justify-between py-0 px-4 my-0 mx-auto overflow-x-hidden relative md:max-w-[800px]'
                    }
                >
                    <Program onClick={handleClick} />
                    <Rating />
                </div>
            </div>

            <div
                className={
                    'max-w-[608px] flex flex-col justify-between py-0 px-4 my-0 mx-auto overflow-x-hidden relative md:max-w-[800px]'
                }
            >
                <MedicationDescription />
            </div>

            <div>
                <VideoCustomerReviews />

                <ReviewsIO />

                <div
                    className={
                        'max-w-[608px] flex flex-col justify-between py-0 px-4 my-0 mx-auto overflow-x-hidden relative md:max-w-[800px]'
                    }
                >
                    <Expectations />

                    <div className={'mt-8 mb-5 md:mb-24'}>
                        <FAQ />
                    </div>
                </div>

                <div
                    className={
                        'bg-gradient-to-t from-white via-white via-80% to-transparent dark:from-black dark:via-black w-full sticky z-[10] bottom-0 left-1/2 flex justify-center items-center'
                    }
                >
                    {isDesktop && (
                        <h1 className={'leading-8 m-0'}>
                            {t('medication-program-tailored-program')}
                        </h1>
                    )}
                    <NextButton
                        buttonWrapperClassName={'p-4 md:w-[375px] md:ml-10'}
                        onClick={handleClick}
                        typographyText={t('medication-program-button-next')}
                    />
                </div>
            </div>
        </>
    );
};
