import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
    CheckboxGroup,
    Checkbox,
    Button,
    Radio,
    RadioGroup,
    Select,
    SelectItem,
    cn,
    AutocompleteItem,
    Autocomplete,
    Tooltip,
} from '@nextui-org/react';
import { ExperimentsKeys } from 'constants/experiments';
import { Experiment, experiments, useAppState } from 'context/AppContext';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes/route-path.constant';

export const UserJourneyControlPanel = () => {
    const { user, setUser } = useAppState();
    const mw_how_sema_works = user.mw_how_sema_works;

    const resetUserJourneyState = () => {
        setUser((prevUser) => ({
            ...prevUser,
            experiments: [],
        }));
    };

    const panelPositions = ['left', 'center', 'right'] as const;
    type Position = (typeof panelPositions)[number]; // Infer type
    const [panelPosition, setPanelPosition] = useState<Position>('right');

    const navigate = useNavigate();
    const location = useLocation();

    const experiments2 = [
        {
            key: 'mw_plan_loader_screen',
            location: '/plan-loader',
            info: 'show /plan-loader after /email',
        },
        { key: 'mw_timer_on_paywall', location: '/medication', info: 'on /medication page' },
        {
            key: 'mw_medical_director_changes',
            location: '/health-condition',
            info: 'on /health-condition, /medical-condition, /medication-intake pages',
        },
        { key: 'mw_subscription_price_variations' },
        {
            key: 'mw_weight_metabolic_rate',
            location: 'weight-metabolic-rate',
            info: 'show /weight-metabolic-rate after /body-weight',
        },
        {
            key: 'mw_important_date',
            location: '/important-event',
            info: 'show/important-event after /scientific-proof, info on /pre-paywall',
        },
        {
            key: 'mw_email_screen_removal',
            location: 'pre-paywall',
            info: 'no /email page after /analyze-responses, and changes on /pre-paywall and /checkout account form',
        },
        { key: 'mw_first_screen', location: '/', info: 'show /goal before /body-gender page' },
        {
            key: 'mw_progress_bar_on_checkout_screen',
            location: '/checkout',
            info: 'on /checkout page',
        },
        {
            key: 'mw_comparison_screen',
            location: '/user-research',
            info: 'show /user-research after /medication-intake',
        },
        {
            key: 'mw_how_sema_works',
            location: '/medication-intake',
            info: 'show /how-sema-works after /medication-intake',
        },
    ];

    return (
        <div
            className={cn(
                'fixed top-[56px] p-4 z-[102] max-h-[calc(100vh-56px)] shadow-medium bg-content1 rounded-medium overflow-y-auto',
                {
                    'right-0': panelPosition === 'right',
                    'left-0': panelPosition === 'left',
                    'left-0 right-0 w-fit sm:max-w-md mx-auto': panelPosition === 'center',
                }
            )}
        >
            <div className="flex justify-between">
                <div className="flex items-center gap-2">
                    <h4 className="font-bold mb-4 min-w-fit text-foreground">Control Panel</h4>
                    <Select
                        size="sm"
                        label="Position"
                        selectedKeys={[panelPosition]}
                        className="text-foreground"
                        classNames={{
                            popoverContent: 'text-foreground',
                            trigger: 'w-[100px]',
                            label: 'w-full text-left',
                        }}
                        onSelectionChange={(value) => {
                            if (value === 'all') return;
                            if (!value.values().next().value) return;
                            if (value.values().next().value === panelPosition) return;
                            setPanelPosition(value.values().next().value);
                        }}
                    >
                        {panelPositions.map((pos) => (
                            <SelectItem key={pos} value={pos} title={pos}>
                                {pos}
                            </SelectItem>
                        ))}
                    </Select>
                </div>
                <Button
                    isIconOnly
                    variant="light"
                    radius="full"
                    size="sm"
                    onPress={() => {
                        setUser((prevUser) => ({
                            ...prevUser,
                            isControlPanelOpen: false,
                        }));
                    }}
                    className="-mr-2 -mt-2"
                >
                    <XMarkIcon className="h-5 w-5" />
                </Button>
            </div>

            <div className=" flex flex-col gap-4 mt-2">
                <div className="flex gap-4">
                    <Autocomplete
                        clearButtonProps={{
                            className: '!opacity-100',
                        }}
                        label="Navigate to"
                        selectedKey={location.pathname}
                        className="text-foreground"
                        classNames={{
                            popoverContent: 'overscroll-y-contain text-foreground',
                            listboxWrapper: 'overscroll-y-contain max-h-[24rem]',
                            listbox: 'overscroll-y-contain',
                        }}
                        onSelectionChange={(value) => {
                            if (value === 'all') return;
                            navigate(value as string);
                        }}
                    >
                        {Object.values(RoutePath).map((path) => (
                            <AutocompleteItem key={path} value={path} title={path}>
                                {path}
                            </AutocompleteItem>
                        ))}
                    </Autocomplete>
                </div>

                <div className="flex flex-col gap-4">
                    <CheckboxGroup
                        label="Experiments"
                        value={user.experiments}
                        onValueChange={(value) => {
                            setUser((prevUser) => ({
                                ...prevUser,
                                experiments: value as Experiment[],
                            }));
                        }}
                    >
                        {/* {experiments.map((experiment) => ( */}
                        {experiments2.map((experiment) => (
                            <Checkbox
                                classNames={{
                                    wrapper: 'after:bg-blue-500',
                                    label: 'flex items-center gap-0.5',
                                }}
                                onValueChange={(checked) => {
                                    type ExperimentKey = keyof typeof ExperimentsKeys;
                                    localStorage.setItem(
                                        ExperimentsKeys[experiment.key as ExperimentKey],
                                        checked ? '1' : '0'
                                    );
                                }}
                                value={experiment.key}
                                key={experiment.key}
                            >
                                {experiment.key}
                                {experiment.location && (
                                    <Tooltip
                                        className="cursor-pointer hover:bg-blue-100 text-foreground dark:hover:text-black dark:hover:bg-blue-300 max-w-sm"
                                        content={
                                            <div className="flex items-center">
                                                {experiment.info}
                                                <ChevronRightIcon className="h-4 w-4 mt-0.5 shrink-0" />
                                            </div>
                                        }
                                        onClick={() => navigate(experiment.location)}
                                    >
                                        <InformationCircleIcon className="w-4 h-4" />
                                    </Tooltip>
                                )}
                            </Checkbox>
                        ))}
                    </CheckboxGroup>
                </div>

                <div className="flex flex-col gap-4">
                    <RadioGroup
                        orientation="horizontal"
                        label="mw_how_sema_works"
                        value={mw_how_sema_works || ''}
                        onValueChange={(value) => {
                            localStorage.setItem(ExperimentsKeys.mw_how_sema_works, value);
                            setUser((prevUser) => ({ ...prevUser, mw_how_sema_works: value }));
                        }}
                        className="lg:border-r lg:border-divider lg:pr-4"
                    >
                        <Radio value={'1'}>1</Radio>
                        <Radio value={'2'}>2</Radio>
                    </RadioGroup>
                </div>

                <div className="mt-4 flex justify-end gap-2">
                    <Button
                        variant="light"
                        color="danger"
                        onPress={() => {
                            setUser((prevUser) => ({
                                ...prevUser,
                                isControlPanelOpen: false,
                            }));
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        // color="primary"
                        className="bg-blue-500 text-white"
                        onPress={resetUserJourneyState}
                    >
                        Reset
                    </Button>
                </div>
            </div>
        </div>
    );
};
