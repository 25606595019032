import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'helpers';

import { ValidatedInput } from 'components/validated-input';
// import { Dropdown } from 'components/dropdown';

import { GoogleStreetAddressAutoComplete } from './google-street-address-auto-complete';

import { Select, SelectItem } from '@nextui-org/react';

export interface GoogleStreetAddress {
    result: {
        address_components: { long_name: string; short_name: string; types: string[] }[];
        formatted_address: string;
        place_id: string;
    };
    name: string;
    googleValue: string;
}

interface ShippingAddressProps {
    streetAddress: string;
    handleStreetAddressChange: (address: string) => void;
    handleSelectStreetAddress: ({ result, name, googleValue }: GoogleStreetAddress) => void;
    address2: string;
    onAddress2Change: (e: any) => void;
    city: string;
    onCityChange: (e: any) => void;
    states: { label: string; value: string }[];
    state: string;
    onStateChange: (state: string) => void;
    stateNeedValidation: boolean;
    zipCode: string;
    onZipCodeChange: (e: any) => void;
    validationListener: boolean;
    nightTheme?: boolean;

    streetAddressInvalid?: null | boolean;
    address2Invalid?: null | boolean;
    cityInvalid?: null | boolean;
    stateInvalid?: null | boolean;
    zipCodeInvalid?: null | boolean;
}

export const ShippingAddress: FC<ShippingAddressProps> = ({
    streetAddress,
    streetAddressInvalid,
    validationListener,
    handleStreetAddressChange,
    handleSelectStreetAddress,
    address2,
    onAddress2Change,
    address2Invalid,
    city,
    onCityChange,
    cityInvalid,
    states,
    state,
    onStateChange,
    stateInvalid,
    stateNeedValidation,
    zipCode,
    onZipCodeChange,
    zipCodeInvalid,
    nightTheme,
}) => {
    const { t } = useTranslation();

    const [generalError, setGeneralError] = useState(false);

    useEffect(() => {
        const isMoreThanOne =
            [
                streetAddressInvalid,
                address2Invalid,
                cityInvalid,
                stateInvalid,
                zipCodeInvalid,
            ].filter((v) => v === true)?.length > 1;
        if (isMoreThanOne) {
            setGeneralError(true);
        } else {
            setGeneralError(false);
        }
    }, [streetAddressInvalid, address2Invalid, cityInvalid, stateInvalid, zipCodeInvalid]);

    const handleKeyDown = (e: KeyboardEvent) => {
        const regexp = /^[\d+-]*$/;
        const isBackspace = e.key === 'Backspace';
        const isNotValid = !regexp.test(e.key) && !isBackspace;

        if (isNotValid) e.preventDefault();
    };

    return (
        <div className={'flex flex-col gap-4'}>
            <div>
                <GoogleStreetAddressAutoComplete
                    value={streetAddress}
                    onChange={handleStreetAddressChange}
                    isError={validationListener && isEmpty(streetAddress)}
                    streetAddressInvalid={streetAddressInvalid}
                    handleSelectStreetAddress={handleSelectStreetAddress}
                    nightTheme={nightTheme}
                />
            </div>

            <div>
                <ValidatedInput
                    type="text"
                    value={address2}
                    onChange={onAddress2Change}
                    error={Boolean(address2Invalid)}
                    validationQuery={{ type: { value: 'emptyString' } }} // @TODO: Fix ValidatedInput components to not pass the unused stuff
                    toggleValidationListener={validationListener}
                    label={t('checkout-shipping-address-form-input-address-2')}
                    nightTheme={nightTheme}
                />
            </div>

            <div>
                <ValidatedInput
                    type="text"
                    value={city}
                    onChange={onCityChange}
                    error={Boolean(cityInvalid)}
                    validationQuery={{
                        type: {
                            value: 'emptyString',
                            errorMessage: t('checkout-input-error-empty-field'),
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-shipping-address-form-input-city')}
                    nightTheme={nightTheme}
                />
            </div>

            <div className={'flex w-full gap-4 max-w-[560px] mx-auto [&>div]:w-full '}>
                {/* <div>
                    <Dropdown
                        value={state}
                        options={states}
                        handleSelect={onStateChange}
                        error={stateInvalid}
                        required={stateNeedValidation}
                        label={t('checkout-shipping-address-form-input-state')}
                        nightTheme={nightTheme}
                    />
                </div> */}
                <div>
                    {/* // TODO ui design note: check logic */}
                    <Select
                        isRequired={stateNeedValidation}
                        radius="sm"
                        label={t('checkout-shipping-address-form-input-state')}
                        isInvalid={Boolean(stateInvalid)}
                        errorMessage={
                            Boolean(stateInvalid) && (
                                <p className="!text-xs font-normal !leading-4 !text-danger !mt-1">
                                    {'Field is required'}
                                </p>
                            )
                        }
                        selectedKeys={state ? [state] : []}
                        className="text-foreground"
                        classNames={{
                            listbox: 'text-foreground',
                        }}
                    >
                        {states.map((state) => (
                            <SelectItem
                                key={state.value}
                                value={state.value}
                                onPress={() => {
                                    onStateChange(state.value);
                                }}
                            >
                                {state.label}
                            </SelectItem>
                        ))}
                    </Select>
                </div>

                <div>
                    <ValidatedInput
                        type="text"
                        value={zipCode}
                        onChange={onZipCodeChange}
                        error={Boolean(zipCodeInvalid)}
                        onKeyDown={handleKeyDown}
                        validationQuery={{
                            type: {
                                value: 'pattern',
                                pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                errorMessage: 'Invalid field',
                            },
                        }}
                        toggleValidationListener={validationListener}
                        label={t('checkout-shipping-address-form-input-zipcode')}
                        nightTheme={nightTheme}
                    />
                </div>
            </div>

            <div>
                {generalError && (
                    <h3 className={`!text-danger !leading-5 text-left !mt-0 !mb-4`}>
                        {t('shipping-address-form-error')}
                    </h3>
                )}

                {(streetAddressInvalid || address2Invalid) && !generalError && (
                    <h3 className={`!text-danger !leading-5 text-left !mt-0 !mb-4`}>
                        {t('shipping-address-form-error-streetAddressInvalid')}
                    </h3>
                )}

                {cityInvalid && !generalError && (
                    <h3 className={`!text-danger !leading-5 text-left !mt-0 !mb-4`}>
                        {t('shipping-address-form-error-cityInvalid')}
                    </h3>
                )}

                {stateInvalid && !generalError && (
                    <h3 className={`!text-danger !leading-5 text-left !mt-0 !mb-4`}>
                        {t('shipping-address-form-error-stateInvalid')}
                    </h3>
                )}

                {zipCodeInvalid && !generalError && (
                    <h3 className={`!text-danger !leading-5 text-left !mt-0 !mb-4`}>
                        {t('shipping-address-form-error-zipCodeInvalid')}
                    </h3>
                )}
            </div>
        </div>
    );
};
