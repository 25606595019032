import { Button } from 'ncomponents/Button';
import { ButtonProps as NextUIButtonProps } from '@nextui-org/react';
import { cn } from 'helpers/cn';

export interface INextButtonProps extends NextUIButtonProps {
    text?: string;
    typographyText?: string;
    disabled?: boolean;
    className?: string;
}

export const NextButton = ({
    text,
    typographyText,
    className = '',
    ...props
}: INextButtonProps) => {
    return (
        <div className="flex w-full justify-center">
            <Button
                text={typographyText || text || 'Next'}
                buttonClass={cn(
                    'bg-btnSecondaryPrimary dark:bg-btnSecondaryPrimary !text-white my-5 md:my-6 hover:!opacity-100 hover:text-white hover:bg-btnSecondaryHover dark:hover:bg-btnSecondaryHover disabled:bg-[#C5C5D1] disabled:hover:bg-[#C5C5D1] dark:disabled:hover:bg-[#C5C5D1] dark:disabled:bg-[#C5C5D1] disabled:pointer-events-none w-full h-12 text-large min-h-auto p-4 max-w-[400px] rounded-lg font-semibold',
                    className
                )}
                {...props}
            />
        </div>
    );
};
