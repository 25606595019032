import { useTranslation } from 'react-i18next';

import { ModalWindow } from 'components/modal-window';

import { Button } from 'ncomponents/Button';

export const CorrectionModalWindow = ({
    address,
    handleClick,
    isOpen,
}: {
    address: string;
    handleClick: () => void;
    isOpen: boolean;
}): JSX.Element => {
    const { t } = useTranslation();

    return (
        // <ModalWindow wrapperClassName={'z-[102] backdrop-blur-sm [&_h1]:m-0 [&>div]:p-6'}>
        //     <Typography variant={TypographyVariants.h1}>
        //         {t('shipping-address-correction-modal-window-title')}
        //     </Typography>

        //     <Typography variant={TypographyVariants.h2}>{address}</Typography>

        //     <Button
        //         // text={t('shipping-address-correction-modal-window-button')}
        //         typographyText={t('shipping-address-correction-modal-window-button')}
        //         onClick={handleClick}
        //     />
        // </ModalWindow>

        <ModalWindow backdrop="blur" isOpen={isOpen}>
            <h1 className="!my-0">{t('shipping-address-correction-modal-window-title')}</h1>

            <h2>{address}</h2>

            <Button
                // text={t('shipping-address-correction-modal-window-button')}
                typographyText={t('shipping-address-correction-modal-window-button')}
                onClick={handleClick}
                className="bg-blue-500 text-white"
            />
        </ModalWindow>
    );
};
