import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { IMultiChoiceButtonOption, MultiChoice } from 'ncomponents/MultiChoiceButton';

import { Heading } from 'ncomponents/Heading';
import { FloatedButton } from 'ncomponents/FloatedButton';
import { NextButton } from 'ncomponents/NextButton';

export const LoseWeightReason = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.LoseWeightReason];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.LoseWeightReason]}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'increase-self-confidence',
            label: t('lose-weight-reason-option-increase-self-confidence'),
        },
        {
            value: 'feel-better-in-clothes',
            label: t('lose-weight-reason-option-feel-better-in-clothes'),
        },
        { value: 'have-more-energy', label: t('lose-weight-reason-option-have-more-energy') },
        {
            value: 'look-more-attractive',
            label: t('lose-weight-reason-option-look-more-attractive'),
        },
        {
            value: 'enhance-physical-performance',
            label: t('lose-weight-reason-option-enhance-physical-performance'),
        },
        { value: 'disease-risk', label: t('lose-weight-reason-option-reduce-disease-risk') },
        { value: 'other', label: t('lose-weight-reason-option-other'), userAnswer, setUserAnswer },
    ];

    const handleClick = () => {
        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('lose-weight-reason-title'),
                value: pageValue.map((i: IMultiChoiceButtonOption) => i.label),
            },
        });

        // todo update logic for label and value object
        pageConfiguration.handleOnPageComplete(
            pageValue.includes('other')
                ? {
                      pageValue,
                      userAnswer: userAnswer.trim(),
                  }
                : { pageValue }
        );
    };

    return (
        <>
            <Heading
                title={t('lose-weight-reason-title')}
                subtitle={t('lose-weight-reason-subtitle')}
            />

            <MultiChoice
                options={multiChoices}
                selectedOptions={pageValue}
                onSelect={setPageValue}
                textAreaInputLabel={t('lose-weight-reason-text-area-input-label')}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
