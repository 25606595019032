import { FC } from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Button } from '@nextui-org/react';

export interface VideoPlayerProps {
    videoId: string;
    onClose: () => void;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ videoId, onClose }) => {
    const imgSrc = ['svg/medication/video-player/cross.svg'];
    const pageImages = useBackgroundImageService(imgSrc);

    return (
        <div
            className={
                'flex justify-center items-center fixed top-[-5%] right-0 left-0 bottom-[-5%] w-full h-[110%] transition-opacity duration-400 opacity-100 bg-black/90 z-20'
            }
            onClick={onClose}
        >
            <Button
                isIconOnly
                variant="light"
                radius="full"
                size="sm"
                className={
                    'absolute top-[12%] right-[4%] opacity-80 z-[20] hover:opacity-100 cursor-pointer'
                }
                onPress={onClose}
            >
                <img src={pageImages[0]} alt="Cross Icon" className="h-5" />
            </Button>

            <iframe
                width="640px"
                height="480px"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};
