import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

export type CustomerCardType = {
    name: string;
    backgroundPhoto: string;
    review: string;
};

type CustomerCardProps = {
    customerCard: CustomerCardType;
    setCurrentVideoName: (value: string | null) => void;
};

export const CustomerCard: FC<CustomerCardProps> = ({ customerCard, setCurrentVideoName }) => {
    const { t } = useTranslation();

    const { name, review } = customerCard;

    const items = ['svg/medication/video-player/play.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <button
            type="button"
            className={
                'h-full w-full min-h-[340px] flex flex-col justify-end items-start rounded-xl bg-center bg-cover bg-no-repeat cursor-pointer'
            }
            onClick={() => setCurrentVideoName(name)}
        >
            <img src={pageImages[0]} alt={'Play Icon'} />
            <h3 className={'text-base leading-5 font-medium text-left !text-white my-3 mx-0'}>
                {t(name)}
            </h3>
            <h3
                className={
                    'text-sm leading-[18px] text-left !text-btnSecondaryPrimary tracking-[0.0008em] m-0'
                }
            >
                {t(review)}
            </h3>
        </button>
    );
};
