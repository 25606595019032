import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { Heading } from 'ncomponents/Heading';
import { SingleChoice, ISingleChoiceButtonOption } from 'ncomponents/SingleChoiceButton';

export const BodyGender = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();
    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BodyGender];

    const { pageValue: bodyGender, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        setPageValue(option);
        // setPageValue(option.value); // todo check value if should store object or string with new value & label object
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-gender-title'), value: t(option.value) },
        });

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const genders: ISingleChoiceButtonOption[] = [
        {
            value: 'female',
            label: t('body-gender-option-female'),
            image: 'svg/body-gender/female-gender.svg',
        },
        {
            value: 'male',
            label: t('body-gender-option-male'),
            image: 'svg/body-gender/male-gender.svg',
        },
    ];

    return (
        <>
            <Heading title={t('body-gender-title')} subtitle={t('body-gender-description')} />

            <SingleChoice
                options={genders}
                selectedOption={bodyGender}
                onSelect={handleClick}
                classNames={{
                    wrapper: 'w-full flex-row-reverse justify-between items-center gap-0 md:gap-0',
                    button: 'basis-[95%]',
                }}
            />
        </>
    );
};
