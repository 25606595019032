import { Palette } from '../index';

export type AnimatedPageType = {
    animationPageWrapper: {
        display: string,
        alignItems: string,
        flexDirection: string,
        background: Palette | string,
        position: string,
        height: string,
        width: string,
        padding: string,
    }
}

export const AnimatedPageStyles: AnimatedPageType = {
    animationPageWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#F6F6F6',
        position: 'absolute',
        height: '100%',
        width: '100vw',
        padding: '16px',
    }
};
