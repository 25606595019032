import { FC } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { SimpleTimer } from '@ruby-labs/ui-core-kit';

// import { RoutePath } from 'routes/route-path.constant';

import { Banner } from 'ncomponents/Banner';

interface OfferBannerWithTimerProps {
    minutes: number;
    seconds: number;
    setMinutes: (minutes: number) => void;
    setSeconds: (seconds: number) => void;
}

export const OfferBannerWithTimer: FC<OfferBannerWithTimerProps> = ({
    minutes,
    seconds,
    setMinutes,
    setSeconds,
}): JSX.Element => {
    // const navigate = useNavigate();

    const endOfTimerHandler = () => {
        // todo note ui design temp turn off redirect
        // localStorage.clear();
        // Navigate to first page
        // navigate(`..${RoutePath.BodyGender}`);
    };

    return (
        <Banner>
            <h3
                className={
                    '!text-sm !leading-4 !font-medium !mt-0 !mr-1 !mb-0 !ml-0 md:!text-base md:!leading-[18px] text-black'
                }
            >
                <Trans i18nKey="medication-banner-with-timer-title" />
            </h3>
            <div
                className={
                    'min-w-10 [*>span]:!text-sm [*>span]:!leading-4 !font-medium md:!text-base md:!leading-[18px]'
                }
            >
                <SimpleTimer
                    minutes={minutes}
                    seconds={seconds}
                    setMinutes={setMinutes}
                    setSeconds={setSeconds}
                    endOfTimerHandler={endOfTimerHandler}
                />
            </div>
        </Banner>
    );
};
