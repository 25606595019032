import { useEffect, useState } from 'react';

import { useImagePreloadingService } from '../context/image-provider/ImageProvider';

/* In scope not to lose the functionality, but needs to be refactored */
export const useBackgroundImageService = (items: string[]): string[] => {
    const { getImages } = useImagePreloadingService();

    return getImages(items);
};
