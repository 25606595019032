import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckPointsList } from 'components/check-points-list';

export const CurrentWeightBlock: FC<{
    currentWeight: number;
    unit: string;
    userGoals: string[];
}> = ({ currentWeight, unit, userGoals }) => {
    const { t } = useTranslation();

    return (
        <div
            className={
                'bg-[#FFFFFF] dark:bg-content2 rounded-lg flex flex-col py-3 px-[6px] mt-[15px] md:mt-10 md:pt-6 md:px-0 md:pb-3'
            }
        >
            <h1 className={'!m-0 text-lg leading-[26px] md:text-[22px] md:leading-7'}>
                {t('pre-paywall-current-weight-title')}
                <div
                    className={
                        'bg-[#FFDAD2] dark:bg-red-600 rounded-[4px] py-0 px-[6px] inline-block ml-2'
                    }
                >
                    {currentWeight} {unit}
                </div>
            </h1>

            <h2
                className={
                    'font-semibold text-xs leading-4 mt-4 mx-0 mb-3 md:text-sm md:leading-[18px] md:mt-[26px] md:mx-0 md:mb-3'
                }
            >
                {t('pre-paywall-current-weight-goals')}
            </h2>

            <CheckPointsList
                items={userGoals}
                listItemClassName={
                    'flex gap-3 items-center justify-center mb-2 [&_img]:w-4 md:gap-4 md:mb-3'
                }
                listItemTextClassName={
                    'm-0 text-[unset] whitespace-normal truncate overflow-ellipsis md:text-lg md:leading-[26px] text-foreground'
                }
            />
        </div>
    );
};
