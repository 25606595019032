import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';

export const WeightFactors = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = ['svg/weight-factors/weight-factors-illustration.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <Heading
                title={t('weight-factors-title')}
                subtitle={
                    <Trans i18nKey="weight-factors-description" components={[<strong></strong>]} />
                }
            />

            <div className={'w-full max-w-[560px] flex justify-center mb-3 md:mb-4'}>
                <img
                    src={pageImages[0]}
                    alt="Weight Factors Illustration"
                    className="max-[320px]:w-full"
                />
            </div>

            <NextButton typographyText={t('weight-factors-button-next')} onClick={handleClick} />
        </>
    );
};
