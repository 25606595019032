import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { BaseContext } from 'context/base.context';

import { RoutePath } from 'routes/route-path.constant';

import { useGetAnswers } from 'hooks/use-save-answers.hook';
import { useBodyPlanData } from 'hooks/use-body-plan-data.rx.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useImportantDateCalculation } from 'hooks/use-important-date-calc.hook';

import { FloatedButton } from 'components/floated-button';
import { NextButton } from 'components/next-button';

import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';
import { PLAN_VARIATION_0, PLAN_VARIATION_1, PLAN_VARIATION_2 } from 'constants/plan';
import { ImportantEventDateKeys } from 'constants/important-event-date';

import { TipBanner } from './tip-banner';
import { CurrentWeightBlock } from './current-weight-block';
import { GraphBlock } from './graph-block';
// import { GuaranteeBlock } from './guarantee-block';
import { MedicalDirectorCard } from 'pages/medication/medication-description/medical-director-card';
import { useAppState } from 'context/AppContext';

export const PrePaywall = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const userGoals = useGetAnswers(LocalStorageKeys[RoutePath.LoseWeightReason]);

    const {
        weightsPlan,
        weightsPlanForDisplay,
        monthsPlan,
        monthsPlanForDisplay,
        predictedGoalWeight,
        weightLbs,
        unit,
        finalDate,
    } = useBodyPlanData();

    const { setPageValue: setPlan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Medication],
        defaultValue: PLAN_VARIATION_0,
    });

    const { user } = useAppState();
    const variation = '0'; // ExperimentsKeys.mw_subscription_price_variations
    // todo design note multiple variation

    useEffect(() => {
        setPlan({ '0': PLAN_VARIATION_0, '1': PLAN_VARIATION_1, '2': PLAN_VARIATION_2 }[variation]);
    }, [variation]);

    // const mw_important_date = localStorage.getItem(ExperimentsKeys.mw_important_date) === '1';
    const mw_important_date = user.experiments.includes(ExperimentsKeys.mw_important_date);

    const importantEventDate = localStorage.getItem(
        LocalStorageKeys[RoutePath.ImportantEventDate]
    ) as string;

    // const importantEventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]); // todo note get object instead of string
    const importantEventValue = useGetAnswers(LocalStorageKeys[RoutePath.ImportantEvent]);

    const importantEvent =
        importantEventValue === 'other'
            ? t('important-event-date-other-option-event-name')
            : importantEventValue;

    const importantEventDateSkippedValue = localStorage.getItem(
        ImportantEventDateKeys.importantEventDateSkipped
    );

    const showImportantEventSubtitle =
        mw_important_date &&
        importantEventValue !== 'none' &&
        importantEventDateSkippedValue === 'false';

    const { weightAtEventDate, isFutureDate, isToday, isWithinWeek } = useImportantDateCalculation(
        importantEventDate,
        monthsPlan,
        weightsPlan,
        monthsPlanForDisplay
    );

    const isEventInFuture = isFutureDate && dayjs(new Date(importantEventDate)).isAfter(finalDate);
    const weightDifference = weightLbs - weightAtEventDate;
    const weightDifferencePlaceholder = 1;

    const getImportantEventTitleKey = () => {
        let key;
        if (isEventInFuture) {
            key = 'pre-paywall-graph-block-important-event-next-year-title';
        } else if (isToday) {
            key = 'pre-paywall-graph-block-important-event-today-title';
        } else {
            key = 'pre-paywall-graph-block-important-event-title';
        }

        return key;
    };

    const mw_email_screen_removal =
        localStorage.getItem(ExperimentsKeys.mw_email_screen_removal) === '1';
    const tipBannerTextKey = mw_email_screen_removal
        ? 'pre-paywall-tip-banner-experiment'
        : 'pre-paywall-tip-banner';

    return (
        <div className={'bg-[#F6F6F6] dark:bg-content1'}>
            <TipBanner textKey={tipBannerTextKey} />

            {mw_important_date && (
                <div
                    className={`flex flex-col justify-center items-center mb-[1px] md:-mb-4
                        [&>div]:flex
                        [&>div]:flex-col
                        [&>div]:items-center
                        [&>div]:gap-[5px]
                        [&>div]:w-auto
                        [&>div]:max-w-[151px]
                        [&>div]:min-w-[151px]
                        [&>div]:p-[5px]
                        md:[&>div]:gap-3
                        md:[&>div]:max-w-[158px]
                        md:[&>div]:min-w-[158px]
                        md:[&>div]:p-2
                        md:[&>div]:mt-6
                        [&>div>img]:w-14
                        md:[&>div>img]:w-[91px]
                        [&>div>h1]:mb-1
                        md:[&>div>h1]:mb-2
                        [&>div>div>h1]:text-center
                        [&>div>div>h2]:text-center
                        [&>div>div>h1]:!text-xs
                        md:[&>div>div>h1]:!text-xs
                        `}
                >
                    <h1 className="text-lg leading-[26px] mt-2 mx-0 md:text-[22px] md:mt-10 md:mx-0 mb-4">
                        {t('pre-paywall-your-assessment')}
                    </h1>
                    <MedicalDirectorCard />
                </div>
            )}

            <div
                className={
                    'max-w-[608px] flex flex-col justify-between py-0 px-4 my-0 mx-auto md:p-0'
                }
            >
                <CurrentWeightBlock
                    currentWeight={weightsPlanForDisplay[0]}
                    unit={unit}
                    userGoals={userGoals}
                />
                <GraphBlock
                    weightGoal={predictedGoalWeight}
                    unit={unit}
                    weightPlan={weightsPlanForDisplay}
                    monthsPlan={monthsPlanForDisplay}
                    importantEventTitle={
                        showImportantEventSubtitle
                            ? t(getImportantEventTitleKey(), {
                                  importantEvent,
                                  weightDifference:
                                      weightDifference === 0 || isWithinWeek
                                          ? weightDifferencePlaceholder
                                          : weightDifference,
                              })
                            : ''
                    }
                />
            </div>

            {/* TODO: Uncomment the GuaranteeBlock after inspection */}
            {/*<GuaranteeBlock />*/}

            <FloatedButton
                floated={true}
                withGradient
                className={'pt-1 px-4 pb-0 md:static md:py-0 md:px-4'}
            >
                <NextButton onClick={handleClick} typographyText={t('pre-paywall-next-button')} />
            </FloatedButton>
        </div>
    );
};
