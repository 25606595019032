import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Input } from '@nextui-org/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';
import { UsersApiProvider } from 'api/users.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { UserVerificationKeys } from 'constants/user-verification';

import { NextButton } from 'ncomponents/NextButton';
import { Heading } from 'ncomponents/Heading';

export const emailRegExp = /^[\w%+-]+(\.[\w%+-]+)*@[\w%+-]+(\.[\w%+-]+)+$/;

export const Email = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const schema = useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .required('Email is required')
                    .email('Enter a valid email address')
                    .matches(emailRegExp, 'Enter a valid email address'),
            }),
        []
    );

    const methods = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const defaultValues = {
            email: pageValue,
        };

        console.log('pageValue', pageValue);

        methods.reset(defaultValues);
    }, []);

    useEffect(() => {
        const subscription = methods.watch((value) => setPageValue(value.email));

        return () => subscription.unsubscribe();
    }, [methods.watch]);

    const handleSubmit = async ({ email }: yup.InferType<typeof schema>) => {
        setPageValue(email);

        const { isUserExists } = await UsersApiProvider.isUserExists(email);

        localStorage.setItem(UserVerificationKeys.isUserExists, String(isUserExists));
        pageConfiguration.handleOnPageComplete({ email, isUserExists });
    };

    return (
        <>
            <Heading title={t('email-title')} subtitle={t('email-subtitle')} />

            <FormProvider {...methods}>
                <form>
                    {/* If there will be an issue with not controlled Input */}
                    <Controller
                        control={methods.control}
                        name="email"
                        render={({ field }) => (
                            <Input
                                type="email"
                                label="Your email"
                                {...field}
                                {...methods.register('email')}
                                isInvalid={!!methods.formState.errors.email}
                                errorMessage={methods.formState.errors.email?.message}
                            />
                        )}
                    />

                    {/* <Input
                        type="email"
                        label="Your email"
                        {...methods.register('email')}
                        isInvalid={!!methods.formState.errors.email}
                        errorMessage={methods.formState.errors.email?.message}
                    /> */}

                    {/* <Typography
                        variant={TypographyVariants.p}
                        className={
                            'font-base text-sm leading-[18px] mt-8 mx-0 mb-3 md:mt-10 md:mb-4 text-foreground'
                        }
                    >
                        <Trans i18nKey="email-text" />
                    </Typography> */}

                    <p className="font-base text-sm leading-[18px] mt-8 mx-0 mb-3 md:mt-10 md:mb-4 text-center">
                        {t('email-text')}
                    </p>

                    <NextButton
                        type="submit"
                        onClick={methods.handleSubmit(handleSubmit)}
                        text={t('email-button-next')}
                        disabled={!!methods.formState.errors.email}
                    />
                </form>
            </FormProvider>
        </>
    );
};
