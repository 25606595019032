import { CheckPointsList } from 'components/check-points-list';
import Accordion from 'ncomponents/Accordion';
import { useCallback } from 'react';

interface MedicationAccordionProps {
    title: string;
    listedItems: any[];
}

export const MedicationAccordion = ({ title, listedItems }: MedicationAccordionProps) => {
    // todo note: added usecallback, otherwise DetailComponent rerenders when have timer block on /medication page
    const DetailComponent = useCallback(({ subtitle, items, description }: any) => {
        return (
            <div className="">
                {subtitle && (
                    <h2
                        className={`font-semibold !leading-[22px] text-[#1375D6] text-left mt-0 md:text-2xl md:leading-[34px] md:mb-6`}
                    >
                        {subtitle}
                    </h2>
                )}

                {items && (
                    <CheckPointsList
                        items={items}
                        listItemClassName={'flex gap-3 items-start mb-4 md:mb-6 last-of-type:mb-0'}
                        listItemTextClassName={
                            '!text-base !m-0 text-start md:!text-xl md:!leading-[30px]'
                        }
                    />
                )}

                {description && (
                    <h3
                        className={
                            'text-base text-left !mt-0 whitespace-pre-line md:text-xl md:leading-[30px]'
                        }
                    >
                        {description}
                    </h3>
                )}
            </div>
        );
    }, []);

    return (
        <div className={''}>
            <h1
                className={
                    'text-2xl leading-[34px] text-left border-b-1 border-solid border-[#C5C5D1] m-0 py-8 md:text-[34px] md:leading-[44px] md:text-center md:mt-20 md:mb-0'
                }
            >
                {title}
            </h1>

            <Accordion items={listedItems} DetailComponent={DetailComponent} />
        </div>
    );
};
