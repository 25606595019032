import { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

interface ListItemProps {
    textKeys: string[];
}

export const ListItem: FC<ListItemProps> = ({ textKeys }) => {
    const pageImages = useBackgroundImageService(['svg/how-sema-works/arrow.svg']);

    return (
        <div className="flex items-baseline gap-[10px] md:gap-5 ">
            <img src={pageImages[0]} alt="Arrow" className="w-[22px] h-3 " />
            <div>
                {textKeys.map((key, index) => (
                    <p
                        key={index}
                        className="!text-sm !leading-normal text-left !m-0 md:!text-base dark:text-default-600 max-[375px]:text-xs"
                    >
                        <Trans i18nKey={key} />
                    </p>
                ))}
            </div>
        </div>
    );
};
