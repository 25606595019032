export const convertToCm = (ft: number = 0, inches: number = 0) => {
    return Math.round((ft * 12 + inches) * 2.54)
};

export const convertToFtInches = (cm: number) => {
    const inCommon = Math.round(cm / 2.54);
    const ft = Math.floor(inCommon / 12);
    const inches = inCommon - ft * 12;
    return { ft, inches };
};

export const convertToKg = (lbs: number = 0) => {
    return Math.round(lbs / 2.20462262185);
};

export const convertToLbs = (kg: number = 0) => {
    return Math.round(kg * 2.20462262185);
};

export const convertToKgWithoutRounding = (lbs: number = 0) => {
    return lbs / 2.20462262185;
};

export const convertToLbsWithoutRounding = (kg: number = 0) => {
    return kg * 2.20462262185;
};
