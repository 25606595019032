import { IMultiChoiceButtonOption } from 'ncomponents/MultiChoiceButton';

export const getMultiChoiceButtonSelectOptions = (
    pageValue: IMultiChoiceButtonOption[], // todo check logic for value label object
    multiChoices: IMultiChoiceButtonOption[]
) => {
    return pageValue.map((item: IMultiChoiceButtonOption) => {
        const currentOption = multiChoices.find((option) => option.value === item.value);

        return currentOption?.value === 'other'
            ? `${currentOption.label}: ${currentOption?.userAnswer?.trim()}`
            : currentOption?.label;
    }) as string[];
};

export const isNextButtonDisabled = (
    pageValue: IMultiChoiceButtonOption[], // todo check logic for value label object
    multiChoices: IMultiChoiceButtonOption[]
) => {
    const isAnswerEmpty = (answer: string) => answer.trim().length === 0;
    const isEveryAnswerValid = pageValue.every((value: IMultiChoiceButtonOption) => {
        const option = multiChoices.find((opt) => opt.value === value.value);
        return option?.value === 'none' || option?.userAnswer === undefined
            ? true
            : !isAnswerEmpty(option?.userAnswer?.toString() as string);
    });

    return !pageValue.length || !isEveryAnswerValid;
};
