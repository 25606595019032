import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface IImagePreloadingContext {
    images: { key: string, pathUrl: string }[];
    imagesBlobs?: { [key: string]: string };
}

const ImagePreloadingContext = createContext<IImagePreloadingContext>({images: []});

interface ImagePreloadingProviderProps {
    children: React.ReactNode;
    images: { key: string, pathUrl: string }[];
}

class PreloadImageService {
    preloadedImages: { [key: string]: any } = {};
}

export const ImagePreloadingProvider = ({ children, images }: ImagePreloadingProviderProps) => {

    const [imagesBlobs, setImagesBlobs] = useState<{ [key: string]: any }>({});

    const webworker: Worker = useMemo(
        () => new Worker(new URL('../../service-worker.js', import.meta.url)),
        []
    );

    const preloadImageService = useMemo(
        () => new PreloadImageService(),
        []
    );

    useEffect(() => {

        webworker.postMessage({
            type: 'preload-images',
            images,
        });

        webworker.addEventListener("message", function(event) {
            const eventData = event.data as { type: string; url: string; blob: Blob };
            if (eventData.type === 'image-preloaded') {
                preloadImageService.preloadedImages[eventData.url] = URL.createObjectURL(eventData.blob);
            }

            if (eventData.type === 'all-images-preloaded') {
                setImagesBlobs((prevBlobs) => ({
                    ...prevBlobs,
                    ...preloadImageService.preloadedImages,
                }));
            }
        });

    }, [images.length]);

    return (
        <ImagePreloadingContext.Provider value={{ imagesBlobs, images }}>
            {children}
        </ImagePreloadingContext.Provider>
    );
};

export const useImagePreloadingService = () => {
    const { images, imagesBlobs } = useContext(ImagePreloadingContext);

    const getImages = (urls: string[]) => {
        return urls.map(getImageByUrl);
    };

    const getImageByUrl = (url: string) => {
        if (imagesBlobs && Object.keys(imagesBlobs).length) {
            const path = imagesBlobs[url];
            return path ? path : url;
        }

        const item = images.find(i => i.key === url);

        return item ? item.pathUrl : url;
    };

    return {
        getImages,
        getImageByUrl,
    };
};
