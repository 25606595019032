import React, { FC, PropsWithChildren } from 'react';

import { RatingButton, RatingType } from '../RatingButton';

export const RatingScaleWrapper = ({ children }: PropsWithChildren) => (
    <div className='flex flex-col justify-center w-full'>
        {children}
    </div>
);


export const RatingButtonsScaleWrapper = ({ children }: PropsWithChildren) => (
    <div className='flex flex-row justify-between gap-3'>
        {children}
    </div>
);

export interface IRatingButtonsScaleProps {
    value: string;
    ratings?: RatingType[];
    handleClick: (rating: RatingType) => void;
}

export const RatingButtonsScale: FC<IRatingButtonsScaleProps> = ({ value, ratings, handleClick }) => (
    <RatingButtonsScaleWrapper>
        {ratings && ratings.map((rating) => (
            <RatingButton
                key={rating.value}
                rating={rating}
                selected={value === rating.value}
                handleClick={() => handleClick(rating)}
            />
        ))}
    </RatingButtonsScaleWrapper>
);

export interface IRatingGradationInfoProps {
    ratingGradationInfo?: string[];
}

export const RatingGradationInfo: FC<IRatingGradationInfoProps> = ({ ratingGradationInfo }) => {
    if (!ratingGradationInfo) {
        return null;
    }

    return (
        <div className='flex justify-between items-center h-18 w-full my-2'>
            {ratingGradationInfo.slice(0, 2).map((info, index) => (
                <span key={index} className='text-base leading-5 font-normal'>
                    {info}
                </span>
            ))}
        </div>
    );
};

export interface IRatingScaleProps extends IRatingButtonsScaleProps, IRatingGradationInfoProps {}

export const RatingScale: React.FC<IRatingScaleProps> = (props: IRatingScaleProps) => {

    return (
        <RatingScaleWrapper>
            <RatingButtonsScale {...props} />
            <RatingGradationInfo {...props} />
        </RatingScaleWrapper>
    );
};

RatingScale.defaultProps = {
    ratings: [
        {
            value: 'Strongly disagree',
            label: '1',
        },
        {
            value: 'Disagree',
            label: '2',
        },
        {
            value: 'Neither agree nor disagree',
            label: '3',
        },
        {
            value: 'Agree',
            label: '4',
        },
        {
            value: 'Strongly agree',
            label: '5',
        },
    ]
};
