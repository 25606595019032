import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pageConfiguration } from 'containers/index';

import { RoutePath } from 'routes/route-path.constant';

import { NextButton } from 'components/next-button';
import { cn } from 'helpers/cn';

export const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const scrollableContent = false;

    const handleClick = () => {
        pageConfiguration.event('RxWeightNotFoundPageClicked');
        navigate(`..${RoutePath.BodyGender}`);
    };

    return (
        <div
            className={cn('max-w-[560px] mt-0 mx-auto relative pt-0 px-4 pb-10 md:p-0', {
                'pt-0 px-4 pb-5 md:pt-0 md:px-0 md:mb-5': scrollableContent,
            })}
        >
            <h1>
                <Trans i18nKey="not-found-title" />
            </h1>
            <h2>
                <Trans i18nKey="not-found-description" />
            </h2>

            <NextButton onClick={handleClick} typographyText={t('not-found-next-button')} />
        </div>
    );
};
