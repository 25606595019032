export const USA_COUNTRY_CODE = '+1';

export const AGES = [
    { min: 0, max: 19, age: 'underTwenty', value: 'underTwenty', label: 'body-age-option-underTwenty' },
    { min: 20, max: 29, age: 'twentys', value: 'twentys', label: 'body-age-option-twenties' },
    { min: 30, max: 39, age: 'thirtys', value: 'thirtys', label: 'body-age-option-thirties' },
    { min: 40, max: 49, age: 'fortys', value: 'fortys', label: 'body-age-option-forties'  },
    { min: 50, max: 59, age: 'fiftys', value: 'fiftys', label: 'body-age-option-fifties'},
    { min: 60, max: 100, age: 'sixtyPlus', value: 'sixtyPlus', label: 'body-age-option-sixtyPlus' },
];
