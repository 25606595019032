import { Trans, useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { PLAN_VARIATION_0 } from 'constants/plan';
import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { ReactComponent as Calendar } from 'assets/images/svg/medication/calendar.svg';
import { ReactComponent as Refund } from 'assets/images/svg/medication/refund.svg';
import { ReactComponent as MedicationBottle } from 'assets/images/svg/medication/medication-bottle.svg';
import { ReactComponent as Delivery } from 'assets/images/svg/medication/delivery.svg';

export const Program = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();

    const { pageValue: plan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Medication],
        defaultValue: PLAN_VARIATION_0,
    });

    const items = [
        'webp/medication/medications-pack.webp',
        // 'svg/medication/calendar.svg',
        // 'svg/medication/refund.svg',
        // 'svg/medication/medication-bottle.svg',
        // 'svg/medication/delivery.svg',
    ];
    const pageImages = useBackgroundImageService(items);

    const isDesktop = useDesktopMediaWatcher();

    const todayTotalPrice = `$${plan.amount / 100}`;
    const subscriptionPrice = `$${plan.subscriptionAmount / 100}`;

    return (
        <div
            className={
                'bg-[#FFFFFF] dark:bg-black rounded-lg flex flex-col py-3 px-4 mt-6 md:p-6 md:mt-8'
            }
        >
            <h1
                className={
                    'leading-6 text-left my-3 mx-0 md:m-0 md:text-[34px] md:leading-[44px] md:text-left'
                }
            >
                {t('medication-program-title')}
            </h1>

            <div
                className={
                    'border-y border-solid border-[#C5C5D1] dark:border-divider py-6 px-0 md:border-t-0 md:flex md:flex-row-reverse md:gap-10 md:pt-6 md:px-0 md:pb-8'
                }
            >
                <img
                    src={pageImages[0]}
                    alt="Medications Pack"
                    className="w-full h-[168px] rounded-lg object-cover md:w-[272px] md:h-[194px]"
                />

                <div className={'mt-6 flex flex-col items-start gap-4 md:gap-6 md:mt-0'}>
                    <div className={'flex items-start gap-3'}>
                        <div
                            className={
                                'rounded-full w-6 h-6 bg-[#1375D6] text-white text-center flex items-center text-base leading-6 font-semibold justify-center shrink-0'
                            }
                        >
                            1
                        </div>
                        <h3 className="text-base leading-6 md:text-xl md:leading-[26px] text-left mx-0">
                            {t('medication-program-option-1')}
                        </h3>
                    </div>
                    <div className={'flex items-start gap-3'}>
                        <div
                            className={
                                'rounded-full w-6 h-6 bg-[#1375D6] text-white text-center flex items-center text-base leading-6 font-semibold justify-center shrink-0'
                            }
                        >
                            2
                        </div>
                        <h3 className="text-base leading-6 md:text-xl md:leading-[26px] text-left mx-0">
                            {t('medication-program-option-2')}
                        </h3>
                    </div>
                    <div className={'flex items-start gap-3'}>
                        <div
                            className={
                                'rounded-full w-6 h-6 bg-[#1375D6] text-white text-center flex items-center text-base leading-6 font-semibold justify-center shrink-0'
                            }
                        >
                            3
                        </div>
                        <h3 className="text-base leading-6 md:text-xl md:leading-[26px] text-left mx-0">
                            {t('medication-program-option-3')}
                        </h3>
                    </div>
                </div>
            </div>

            {/* Program item */}
            {/* // todo ui design .map refactor */}
            <div className={'mt-6 mx-0 mb-3 md:my-8 '}>
                <div className={'flex gap-2 md:gap-3 items-center text-left mb-3 md:last:mb-0'}>
                    <Calendar className="w-4 h-4 md:w-7 md:h-7 dark:text-foreground-500" />
                    <h3 className="text-sm leading-[18px] md:text-xl md:leading-6 m-0 font-semibold text-left">
                        {t('medication-program-option-4')}
                    </h3>
                </div>
                <div className={'flex gap-2 md:gap-3 items-center text-left mb-3 md:last:mb-0'}>
                    <Refund className="w-4 h-4 md:w-7 md:h-7 dark:text-foreground-500" />
                    <h3 className="text-sm leading-[18px] md:text-xl md:leading-6 m-0 font-semibold text-left">
                        {t('medication-program-option-5')}
                    </h3>
                </div>
                <div className={'flex gap-2 md:gap-3 items-center text-left mb-3 md:last:mb-0'}>
                    <MedicationBottle className="w-4 h-4 md:w-7 md:h-7 dark:text-foreground-500" />
                    <h3 className="text-sm leading-[18px] md:text-xl md:leading-6 m-0 font-semibold text-left">
                        {t('medication-program-option-6')}
                    </h3>
                </div>
                <div className={'flex gap-2 md:gap-3 items-center text-left mb-3 md:last:mb-0'}>
                    <Delivery className="w-4 h-4 md:w-7 md:h-7 dark:text-foreground-500" />
                    <h3 className="text-sm leading-[18px] md:text-xl md:leading-6 m-0 font-semibold text-left">
                        {t('medication-program-option-7')}
                    </h3>
                </div>
            </div>

            <div className={'border border-solid border-[#C5C5D1] rounded-lg p-2 md:p-3 md:mb-10 '}>
                <h1 className="text-2xl leading-7 font-normal !m-0 md:text-3xl md:leading-8 md:!mb-2">
                    {todayTotalPrice} / <Trans i18nKey="medication-program-trial" />
                </h1>

                <h2 className="text-xs leading-4 font-normal m-0 md:text-base md:leading-5 md:m-0">
                    <Trans
                        i18nKey="medication-program-subscription"
                        values={{ subscriptionPrice }}
                        components={[<strong></strong>]}
                    />
                </h2>
            </div>

            <FloatedButton floated={!isDesktop} withGradient>
                <NextButton
                    className="mt-6 mb-3 md:!my-0 md:max-w-[375px]"
                    onClick={onClick}
                    typographyText={t('medication-program-button-next')}
                />
            </FloatedButton>
        </div>
    );
};
