import { ReactComponent as DarkFamilyTree } from 'assets/images/svg/reinforcement-icons/family-tree-dark-theme.svg';
import { ReactComponent as FamilyTree } from 'assets/images/svg/reinforcement-icons/family-tree.svg';
import { TipPage } from 'ncomponents/Pages/TipPage';
import { useTheme } from 'next-themes';
import { useTranslation } from 'react-i18next';

export const FamilyWeightTip = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();

    return (
        <TipPage
            imgSrc={theme === 'light' ? <FamilyTree /> : <DarkFamilyTree />}
            text={{
                description: t('family-weight-tip-eat-everything-description'),
                next: t('family-weight-tip-button-next'),
            }}
        />
    );
};
