import errorIcon from './error_icon.svg';

type ErrorBlockProps = {
    error: string;
};

export const ErrorBlock = ({ error }: ErrorBlockProps) => {
    return (
        <div
            className={
                // v1
                'border border-solid border-[#FF4D2B] bg-[#FBEBEB] flex items-start w-full py-3 px-4 rounded-[10px] my-4'
                // v2
                // 'bg-danger-50 flex items-start w-full py-3 px-4 rounded-[10px] my-4'
            }
        >
            <img src={errorIcon} alt="icon" />
            <h3
                // v1
                className={`!text-[#121620] text-left tracking-[0.0008em] ml-[10px] w-full`}
                // v2
                // className={`text-danger text-left tracking-[0.0008em] ml-[10px] w-full`}
            >
                {error}
            </h3>
        </div>
    );
};
