import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';
import { US_STATES } from 'constants/states';

import { GoogleStreetAddress } from './index';

// todo ui design note check styles
import { useStyles as GoogleStreetAddressAutoCompleteUseStyles } from './styles';

import { cn } from 'helpers/cn';

interface GoogleStreetAddressAutoCompleteProps {
    value: string;
    onChange: (address: string) => void;
    isError: boolean;
    streetAddressInvalid?: null | boolean;
    handleSelectStreetAddress: ({ result, name, googleValue }: GoogleStreetAddress) => void;
    nightTheme?: boolean;
}

// @description: https://maps.googleapis.com script should be installed
export const GoogleStreetAddressAutoComplete: FC<GoogleStreetAddressAutoCompleteProps> = ({
    value,
    onChange,
    isError,
    streetAddressInvalid,
    handleSelectStreetAddress,
    nightTheme,
}) => {
    const { t } = useTranslation();

    const { googleAutocompleteBlock, description, suggestionItem, suggestionItemActive } =
        GoogleStreetAddressAutoCompleteUseStyles();

    const options = {
        componentRestrictions: { country: 'us' },
        fields: ['address_components', 'name'],
        types: ['address'], // address || establishment
    };

    const [activeClass, setActiveClass] = useState(false);

    const handleFocus = () => {
        setActiveClass(true);
    };

    const handleBlur = () => {
        setActiveClass(false);
        handleSelectStreetAddress({
            result: { address_components: [], formatted_address: '', place_id: '' },
            name: value,
            googleValue: '',
        });
    };

    const isFieldHasError = isError || streetAddressInvalid;

    const handleChange = (address: string) => {
        onChange(address);
    };

    const handleSelect = (address: string) => {
        geocodeByAddress(address).then((results: google.maps.GeocoderResult[]) => {
            console.log(results);

            const streetName = results[0].address_components
                .filter((addressComponent: google.maps.GeocoderAddressComponent) => {
                    return (
                        addressComponent.types.includes('street_number') ||
                        addressComponent.types.includes('route')
                    );
                })
                .map((a) => a?.long_name)
                .join(' ');

            handleSelectStreetAddress({
                result: results[0],
                name: streetName,
                googleValue: address,
            });
        });
    };

    const currentLegitUSStates = US_STATES.map((state) => state.code);

    const handleError = (status: string, _clearSuggestions: () => void) => {
        if (status === 'ZERO_RESULTS') {
            _clearSuggestions();
        }
    };

    return (
        <div className={'max-w-[560px] my-0 mx-auto'}>
            <PlacesAutocomplete
                value={value}
                onChange={handleChange}
                onSelect={handleSelect}
                onError={handleError}
                searchOptions={options}
                googleCallbackName={'init'}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                    const allowedSuggestions = suggestions.filter((suggestion) =>
                        currentLegitUSStates.includes(
                            suggestion?.terms[suggestion?.terms?.length - 2]?.value
                        )
                    );

                    return (
                        // <div className={combinedInputWrapperClass}>
                        <div
                            className={cn(
                                'w-full relative rounded-lg bg-default-100 hover:bg-default-200 transition-colors',
                                {
                                    '!bg-danger-50 hover:!bg-danger-100': isError,
                                    // isInvalid || isEmptyError,
                                }
                            )}
                        >
                            <input
                                {...getInputProps({
                                    placeholder: '',
                                    // className: combinedInputClass,
                                })}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                className={cn(
                                    'peer m-0 block h-[56px] w-full rounded-medium bg-clip-padding',
                                    'px-3 py-4 font-normal leading-tight text-neutral-700 transition duration-200 ease-linear',
                                    'placeholder:text-transparent focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-neutral-700',
                                    'focus:outline-none dark:text-neutral-200 [&:not(:placeholder-shown)]:pb-[0.625rem]',
                                    '[&:not(:placeholder-shown)]:pt-[1.625rem] text-small bg-transparent',
                                    {
                                        // '!text-danger': isInvalid || isEmptyError,
                                        '!text-danger': isError,
                                    }
                                )}
                            />
                            <Typography
                                variant={TypographyVariants.inputLabel}
                                className={cn(
                                    'pl-3 !text-sm !text-foreground-500 pointer-events-none absolute left-0 !top-[15%] origin-[0_0]',
                                    'border border-solid border-transparent px-3 py-2 transition-[opacity,_transform]',
                                    'duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem]',
                                    'peer-focus:scale-[0.85] peer-[:not(:placeholder-shown)]:-translate-y-2',
                                    'peer-[:not(:placeholder-shown)]:translate-x-[0.15rem]',
                                    'peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none',
                                    'text-small group-data-[filled-within=true]:text-default-600',
                                    {
                                        // className={cn(`pl-3 !text-sm !text-foreground-500`, {
                                        '!text-danger': isError,
                                    }
                                )}
                            >
                                {t('checkout-shipping-address-form-input-street-address')}
                            </Typography>
                            <div
                                className={cn(`${googleAutocompleteBlock} !z-[20]`)}
                                style={{ display: activeClass ? 'block' : 'none' }}
                            >
                                {allowedSuggestions.map((suggestion) => {
                                    const className = suggestion.active
                                        ? suggestionItemActive
                                        : suggestionItem;
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                            })}
                                            key={suggestion.placeId}
                                        >
                                            <span className={description}>
                                                {suggestion.description}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                }}
            </PlacesAutocomplete>

            {isError && (
                <p className="!text-xs font-normal !leading-4 !text-danger !mt-1">
                    {t('checkout-input-error-empty-field')}
                </p>
            )}
        </div>
    );
};
