import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { ListItem } from 'pages/how-sema-works/content-v2/list-item';

export const ContentV2 = () => {
    const pageImages = useBackgroundImageService([
        'png/how-sema-works/brain.png',
        'png/how-sema-works/heart.png',
        'png/how-sema-works/liver.png',
        'png/how-sema-works/stomach.png',
        'png/how-sema-works/pancreas.png',
    ]);

    const listItemsData = [
        {
            image: pageImages[0],
            titleKey: 'how-sema-works-list-item-brain-title',
            descriptionKey: 'how-sema-works-list-item-brain-description',
        },
        {
            image: pageImages[1],
            titleKey: 'how-sema-works-list-item-heart-title',
            descriptionKey: 'how-sema-works-list-item-heart-description',
        },
        {
            image: pageImages[2],
            titleKey: 'how-sema-works-list-item-liver-title',
            descriptionKey: 'how-sema-works-list-item-liver-description',
        },
        {
            image: pageImages[3],
            titleKey: 'how-sema-works-list-item-stomach-title',
            descriptionKey: 'how-sema-works-list-item-stomach-description',
        },
        {
            image: pageImages[4],
            titleKey: 'how-sema-works-list-item-pancreas-title',
            descriptionKey: 'how-sema-works-list-item-pancreas-description',
        },
    ];

    return (
        <div className={'max-w-[560px]'}>
            <h2 className="!mt-0 !mb-8 md:!-mt-6 md:!mb-10">
                <Trans i18nKey="how-sema-works-description" />
            </h2>

            <div className="flex flex-col w-full justify-center items-center gap-4 mb-3">
                <div className="flex items-start gap-6 md:gap-[14.27px] md:mb-[60px]">
                    {listItemsData.slice(0, 3).map((item, index) => (
                        <ListItem key={index} {...item} />
                    ))}
                </div>

                <div className="flex items-start gap-6 md:gap-[14.27px] md:mb-[60px]">
                    {listItemsData.slice(3).map((item, index) => (
                        <ListItem key={index + 3} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};
