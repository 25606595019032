import { useTranslation } from 'react-i18next';

import { CheckPointsList } from 'components/check-points-list';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Title } from './title';
import { MedicalDirectorCard } from './medical-director-card';

import { ReactComponent as BookIcon } from 'assets/images/svg/medication/medication-description/book.svg';
import { ReactComponent as TryIcon } from 'assets/images/svg/medication/medication-description/try.svg';
import { ReactComponent as PersonIcon } from 'assets/images/svg/medication/medication-description/person.svg';
import { ReactComponent as ClockIcon } from 'assets/images/svg/medication/medication-description/clock.svg';
import { Divider } from '@nextui-org/react';

export const MedicationDescription = () => {
    const { t } = useTranslation();

    // todo design note: imported svg with currentColor to work dynamically with dark theme
    const images = [
        'webp/medication/medication-description/medication.webp',
        // 'svg/medication/medication-description/book.svg',
        // 'svg/medication/medication-description/try.svg',
        // 'svg/medication/medication-description/person.svg',
        // 'svg/medication/medication-description/clock.svg',
        '',
        '',
        '',
        '',
        'svg/medication/coach-message.webp',
    ];

    const pageImages = useBackgroundImageService(images);

    const block1 = [
        t('medication-medication-description-block-1-option-1'),
        t('medication-medication-description-block-1-option-2'),
        t('medication-medication-description-block-1-option-3'),
        t('medication-medication-description-block-1-option-4'),
    ];

    const block3 = [
        t('medication-medication-description-block-3-option-1'),
        t('medication-medication-description-block-3-option-2'),
    ];

    return (
        <div className={'my-8 mx-0 md:my-20'}>
            <h1
                className={
                    'text-2xl leading-[34px] text-left !m-0 !mb-8 md:text-[34px] md:leading-[44px] md:!mb-10'
                }
            >
                {t('medication-medication-description-title')}
            </h1>

            <div>
                <Title number={1} text={t('medication-medication-description-block-1-title')} />

                <div className={'md:flex md:flex-row-reverse md:items-start md:gap-8'}>
                    <img
                        src={pageImages[0]}
                        className={
                            'w-full h-[200px] mt-6 rounded-lg object-cover md:w-[278px] md:h-[217px] md:-mt-8 md:self-center'
                        }
                        alt="Medication"
                    />

                    <div>
                        <h2
                            className={
                                'text-base leading-6 mt-6 mx-auto mb-5 md:text-xl md:leading-[30px] md:mt-8 md:mx-auto md:mb-6 text-left'
                            }
                        >
                            {t('medication-medication-description-block-1-text')}
                        </h2>

                        <CheckPointsList
                            items={block1}
                            listItemClassName={
                                'flex gap-3 items-start mb-4 md:mb-5 md:last:mb-0 md:[&_img]:w-8 md:[&_img]:h-8'
                            }
                            listItemTextClassName={
                                'text-base leading-6 m-0 md:text-xl md:leading-[30px] text-left'
                            }
                        />
                    </div>
                </div>
            </div>

            <Divider className="bg-[#C5C5D1] my-8 md:my-14 h-[2px]" />

            <div>
                <Title number={2} text={t('medication-medication-description-block-2-title')} />

                <div className={'md:flex md:gap-8 md:items-start'}>
                    <div className={'flex flex-col items-start'}>
                        <h2
                            className={
                                'text-base leading-6 mt-6 mx-auto mb-5 md:text-xl md:leading-[30px] md:mt-8 md:mx-auto md:mb-6 text-left'
                            }
                        >
                            {t('medication-medication-description-block-2-text')}
                        </h2>

                        {/* second point wrapper */}
                        <div className={'flex items-center gap-3 mb-4 md:mb-5 md:last:!mb-0'}>
                            {/* <img src={pageImages[1]} alt="Book" className="w-[22px] h-[22px]" /> */}
                            <BookIcon className="w-[22px] h-[22px] text-foreground dark:text-foreground-500" />
                            <h3 className="!m-0 !text-base !leading-[24px] md:!text-lg md:!leading-[24px] text-left">
                                {t('medication-medication-description-block-2-option-1')}
                            </h3>
                        </div>

                        <div className={'flex items-center gap-3 mb-4 md:mb-5 md:last:!mb-0'}>
                            {/* <img src={pageImages[2]} alt="Try" className="w-[22px] h-[22px]" /> */}
                            <TryIcon className="w-[22px] h-[22px] text-foreground dark:text-foreground-500" />
                            <h3 className="!m-0 !text-base !leading-[24px] md:!text-lg md:!leading-[24px] text-left">
                                {t('medication-medication-description-block-2-option-2')}
                            </h3>
                        </div>

                        <div className={'flex items-center gap-3 mb-4 md:mb-5 md:last:!mb-0'}>
                            {/* <img src={pageImages[3]} alt="Person" className="w-[22px] h-[22px]" /> */}
                            <PersonIcon className="w-[22px] h-[22px] text-foreground dark:text-foreground-500" />
                            <h3 className="!m-0 !text-base !leading-[24px] md:!text-lg  md:!leading-[24px] text-left">
                                {t('medication-medication-description-block-2-option-3')}
                            </h3>
                        </div>

                        <div className={'flex items-center gap-3 mb-4 md:mb-5 md:last:!mb-0'}>
                            {/* <img src={pageImages[4]} alt="Clock" className="w-[22px] h-[22px]" /> */}
                            <ClockIcon className="w-[22px] h-[22px] text-foreground dark:text-foreground-500" />
                            <h3 className="!m-0 !text-base !leading-[24px] md:!text-lg md:!leading-[24px] text-left">
                                {t('medication-medication-description-block-2-option-4')}
                            </h3>
                        </div>
                    </div>

                    <MedicalDirectorCard />
                </div>
            </div>

            <Divider className="bg-[#C5C5D1] my-8 md:my-14 h-[2px]" />

            <div className={'flex justify-between'}>
                <div>
                    <Title number={3} text={t('medication-medication-description-block-3-title')} />
                    <div className={'mt-5 mx-auto mb-[33px] md:mt-8'}>
                        <CheckPointsList
                            items={block3}
                            listItemClassName={
                                'flex gap-3 items-start mb-4 md:mb-5 md:last:mb-0 md:[&_img]:w-8 md:[&_img]:h-8'
                            }
                            listItemTextClassName={
                                'text-base leading-6 m-0 md:text-xl md:leading-[30px] text-left'
                            }
                        />
                    </div>
                </div>

                <img
                    src={pageImages[5]}
                    className={
                        'relative right-[-10%] w-[100px] -rotate-[15deg] md:w-[278px] md:bg-[#FFEEDE] md:py-[10px] md:px-[77px] md:rotate-0 md:rounded-lg md:right-auto'
                    }
                    alt="Coach Message"
                />
            </div>
        </div>
    );
};
