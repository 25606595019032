import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@nextui-org/react';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

export interface TextAreaInputProps {
    onChange: (value: string) => void;
    value: string;
    maxCharacters: number;
    label: string;
}

export const TextAreaInput: FC<TextAreaInputProps> = ({
    onChange,
    value,
    maxCharacters,
    label,
}) => {
    const { t } = useTranslation();

    const handleTextAreaInputValue = (value: string) => {
        onChange(value);
    };

    const charactersCounter = maxCharacters - value?.length;

    return (
        <>
            <Textarea
                className="mb-2"
                label={label !== '' ? t(label) : t('multichoice-button-other-option-input-label')}
                value={value}
                onValueChange={handleTextAreaInputValue}
                maxLength={maxCharacters}
                // onBlur={handleTextAreaInputBlur}
                // onFocus={handleTextAreaInputValue}
                // todo ui design note: check if missing some logic
            />
            <Typography
                variant={TypographyVariants.h3}
                className={'text-xs leading-4 text-[#5E626B] text-right mt-0 mx-0 mb-3 md:mb-4'}
            >
                {charactersCounter}
            </Typography>
        </>
    );
};
