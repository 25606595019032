import { ReactComponent as LeftArrow } from 'assets/images/svg/left-arrow2.svg';
import { Button } from '@nextui-org/react';

type BackArrowButtonProps = {
    handleBack: () => void;
};

export const BackArrowButton = ({ handleBack }: BackArrowButtonProps): JSX.Element => {
    return (
        <div className="flex-1">
            <Button
                size="sm"
                radius="full"
                variant="light"
                isIconOnly
                className={`ml-2 !w-auto`}
                onPress={handleBack}
            >
                <LeftArrow className="text-foreground" />
            </Button>
        </div>
    );
};
