// node modules
import { FC, ReactNode } from 'react';
// local files
import { TypographyVariants } from 'constants/typography-variants';
import { cn } from 'helpers/cn';

export interface TypographyProps {
    variant: TypographyVariants;
    typographyNext?: boolean;
    className?: string;
    children?: string | ReactNode;
    style?: any;
    onAnimationStart?: () => void;
    onAnimationEnd?: () => void;
}

export const Typography: FC<TypographyProps> = ({
    variant,
    className,
    typographyNext,
    children,
    ...props
}) => {
    const typographyClassName = cn(
        '',
        {
            '!mb-4': typographyNext,
            'text-center': className,
        },
        className
    );

    if (variant === TypographyVariants.validatedInputError) {
        return (
            <p className="text-xs font-normal leading-4 text-danger mt-1" {...props}>
                {children}
            </p>
        );
    }

    if (variant === TypographyVariants.h1) {
        return (
            <h1
                className={cn(
                    'text-center font-semibold leading-7 my-8 text-foreground text-xl md:text-[22px] md:leading-[30px] md:mt-20 md:mb-10',
                    typographyClassName
                )}
                {...props}
            >
                {children}
            </h1>
        );
    }

    if (variant === TypographyVariants.h2) {
        return (
            <h2
                className={cn(
                    `font-normal text-lg leading-[26px] text-center -tracking-[0.0024em] text-foreground mt-4 mb-8 md:mb-10 md:text-xl md:leading-7`,
                    typographyClassName
                )}
                {...props}
            >
                {children}
            </h2>
        );
    }

    if (variant === TypographyVariants.h3) {
        return (
            <h3
                className={cn(
                    `font-normal text-foreground text-center text-sm leading-[18px] mx-auto  `,
                    typographyClassName
                )}
                {...props}
            >
                {children}
            </h3>
        );
    }

    if (variant === TypographyVariants.tab) {
        return (
            <span
                className={`${typographyClassName} font-medium text-xs leading-[18px] -tracking-[0.08px] text-center text-foreground`}
                {...props}
            >
                {children}
            </span>
        );
    }

    if (
        [
            TypographyVariants.button,
            TypographyVariants.choiceButton,
            TypographyVariants.multiChoiceButton,
        ].includes(variant)
    ) {
        return (
            <span
                className={cn(`${typographyClassName}`, {
                    'font-semibold text-lg leading-[26px]': variant === TypographyVariants.button,
                    'font-normal text-lg leading-6 -tracking-[0.0024em]':
                        variant === TypographyVariants.choiceButton,
                    'font-normal text-lf leading-6 -tracking-[0.0024em] text-left':
                        variant === TypographyVariants.multiChoiceButton,
                })}
                {...props}
            >
                {children}
            </span>
        );
    }

    if (
        [
            TypographyVariants.inputLabel,
            TypographyVariants.inputStartAdornment,
            TypographyVariants.inputEndAdornment,
        ].includes(variant)
    ) {
        return (
            <span
                className={cn(`${typographyClassName}`, {
                    'font-normal text-base leading-6 mt-0 top-1/3 text-gray-700 absolute transition-all duration-200 pointer-events-none':
                        variant === TypographyVariants.inputLabel,
                    'font-normal text-lg leading-6 -tracking-[0.0024em]':
                        variant === TypographyVariants.inputStartAdornment,
                    'font-normal text-lf leading-6 -tracking-[0.0024em] text-left':
                        variant === TypographyVariants.inputEndAdornment,
                })}
                {...props}
            >
                {children}
            </span>
        );
    }

    if ([TypographyVariants.tipPage].includes(variant)) {
        return (
            <span
                className={`${typographyClassName} inline-block font-normal text-foreground text-left mx-auto whitespace-pre-line md:-mt-2 leading-7 text-xl`}
                {...props}
            >
                {children}
            </span>
        );
    }

    return (
        <p className={typographyClassName} {...props}>
            {children}
        </p>
    );
};
