import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isEmpty } from 'helpers';

import { US_STATES } from 'constants/states';
import { US_KEY } from 'hooks/use-user-geolocation.hook';

import { GoogleAddressValidation } from 'api/google-address-validation.api';

import { NextButton } from 'components/next-button';
import { GoogleStreetAddress, ShippingAddress } from 'components/shipping-address';
import { CorrectionModalWindow } from 'components/shipping-address/correction-modal-window';
import { validateResponse } from 'components/shipping-address/validation';

export const ShippingAddressEmbedded: FC = () => {
    const states = US_STATES.map((s) => ({ value: s.code, label: s.name }));

    const [validationListener, setValidationListener] = useState(false);
    const [stateNeedValidation, setStateNeedValidation] = useState(false);

    const [addressCorrected, setAddressCorrected] = useState(false);

    const [streetAddressInvalid, setStreetAddressInvalid] = useState<null | boolean>(null);
    const [address2Invalid, setAddress2Invalid] = useState<null | boolean>(null);
    const [cityInvalid, setCityInvalid] = useState<null | boolean>(null);
    const [stateInvalid, setStateInvalid] = useState<null | boolean>(null);
    const [zipCodeInvalid, setZipCodeInvalid] = useState<null | boolean>(null);

    const [searchParams] = useSearchParams();
    const addressParam = searchParams.get('address');
    // todo design note: update to work with nextui set theme
    // import { useTheme } from 'next-themes';
    // const { theme, setTheme } = useTheme();
    const nightThemeParam = searchParams.get('nightTheme');
    const nightTheme = nightThemeParam === 'true';

    const fullInitialAddress = addressParam ? JSON.parse(addressParam) : {};
    const {
        address2: initialAddress2,
        city: initialCity,
        state: initialState,
        streetAddress: initialStreetAddress,
        zipCode: initialZipCode,
        fullAddress: initialFullAddress,
    } = fullInitialAddress;

    const [streetAddress, setStreetAddress] = useState(initialStreetAddress || '');
    const [address2, setAddress2] = useState(initialAddress2 || '');
    const [city, setCity] = useState(initialCity || '');
    const [state, setState] = useState(initialState || '');
    const [zipCode, setZipCode] = useState(initialZipCode || '');
    const [fullAddress, setFullAddress] = useState(initialFullAddress || '');
    const [googleValidatorFormattedAddress, setGoogleValidatorFormattedAddress] = useState(
        initialFullAddress || ''
    );

    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const addressValid = !(
            isEmpty(streetAddress) ||
            isEmpty(city) ||
            isEmpty(state) ||
            isEmpty(zipCode)
        );

        if (addressValid) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [streetAddress, city, state, zipCode]);

    const onAddress2Change = (e: any) => {
        setAddress2(e.target.value);
        setAddress2Invalid(false);
    };

    const onCityChange = (e: any) => {
        setCity(e.target.value);
        setCityInvalid(false);
    };

    const onStateChange = (state: string) => {
        setState(state);
        setStateInvalid(false);
    };

    const onZipCodeChange = (e: any) => {
        setZipCode(e.target.value);
        setZipCodeInvalid(false);
    };

    const toggleValidation = () => {
        setValidationListener(!validationListener);
    };

    const handleSelectStreetAddress = ({ result, name }: GoogleStreetAddress) => {
        if (validationListener && !result?.address_components?.length) {
            return;
        }

        setStreetAddress(name);

        setFullAddress(result.formatted_address);

        setStreetAddressInvalid(false);
        setAddress2Invalid(false);
        setCityInvalid(false);
        setStateInvalid(false);
        setZipCodeInvalid(false);

        // street_number               -> address street number
        // route                       -> the street name
        // locality                    -> the city/town
        // administrative_area_level_3 -> the city/town
        // administrative_area_level_1 -> the state
        // postal_code                 -> zip/postal code
        result.address_components.forEach((address) => {
            if (
                address.types.includes('locality') ||
                address.types.includes('administrative_area_level_3')
            ) {
                setCity(address.long_name);
            }
            if (address.types.includes('administrative_area_level_1')) {
                setState(address.short_name);
            }
            if (address.types.includes('postal_code')) {
                setZipCode(address.long_name);
            }
        });

        toggleValidation();
    };

    const handleComponentSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const emptyStreetAddress = isEmpty(streetAddress);
        const emptyCity = isEmpty(city);
        const emptyState = isEmpty(state);
        const emptyZipCode = isEmpty(zipCode);
        const stateIsAllowed = states.some((s) => s.value === state);

        if (emptyStreetAddress || emptyCity || emptyState || emptyZipCode || !stateIsAllowed) {
            if (emptyStreetAddress) {
                setStreetAddressInvalid(true);
            }
            if (emptyCity) {
                setCityInvalid(true);
            }
            if (emptyState || !stateIsAllowed) {
                setStateInvalid(true);
            }
            if (emptyZipCode) {
                setZipCodeInvalid(true);
            }
            setStateNeedValidation(true);
            toggleValidation();
            return;
        }

        const addressLines = `${streetAddress}, ${address2} ${city}, ${state}, ${zipCode}`;

        const response = await GoogleAddressValidation.validate({
            addressLines,
            regionCode: US_KEY,
        });

        const validated = validateResponse(response);

        setGoogleValidatorFormattedAddress(response.address.formattedAddress);

        setStreetAddressInvalid(!validated.streetAddress.valid);
        if (validated.streetAddress.changed) {
            setStreetAddress(validated.streetAddress.text);
        } else if (
            !isEmpty(validated.streetAddress.value) &&
            streetAddress !== validated.streetAddress.value
        ) {
            setStreetAddress(validated.streetAddress.value);
            validated.streetAddress.changed = true;
        }

        setAddress2Invalid(!validated.address2.valid);
        if (validated.address2.changed) {
            setAddress2(validated.address2.text);
        } else if (!isEmpty(validated.address2.value) && address2 !== validated.address2.value) {
            setAddress2(validated.address2.value);
            validated.address2.changed = true;
        }

        setCityInvalid(!validated.city.valid);
        if (validated.city.changed) {
            setCity(validated.city.text);
        } else if (!isEmpty(validated.city.value) && city !== validated.city.value) {
            setCity(validated.city.value);
            validated.city.changed = true;
        }

        setStateInvalid(!validated.state.valid);
        if (validated.state.changed) {
            setState(validated.state.text);
        } else if (!isEmpty(validated.state.value) && state !== validated.state.value) {
            setState(validated.state.value);
            validated.state.changed = true;
        }

        setZipCodeInvalid(!validated.zipCode.valid);
        if (validated.zipCode.changed) {
            setZipCode(validated.zipCode.text);
        } else if (
            !isEmpty(validated.zipCode.value) &&
            zipCode.toString() !== validated.zipCode.value
        ) {
            setZipCode(validated.zipCode.value);
            validated.zipCode.changed = true;
        }

        if (
            Object.keys(validated)
                // @ts-ignore
                .map((k: string) => validated[k])
                .some((c) => !c.valid)
        ) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (
            Object.keys(validated)
                // @ts-ignore
                .map((k) => validated[k])
                .some((c) => c.changed)
        ) {
            setAddressCorrected(true);
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const postMessage = JSON.stringify({
            streetAddress,
            address2,
            city,
            state,
            zipCode,
            fullAddress,
            googleFormattedAddress: response.address.formattedAddress,
        });

        window.webkit?.messageHandlers?.setAddress?.postMessage(postMessage); // iOS handler
        window.Mobile?.setAddress(postMessage); // Android injection
        window.parent.postMessage(postMessage, '*'); // PWA
    };

    return (
        // <div className={`${pageWrapper} ${nightTheme ? pageWrapperDark : ''} bg-content1`}>
        <div
            className={`h-screen w-full flex flex-col justify-start items-center py-6 px-4 [&>div]:w-full bg-content1`}
        >
            {/* {addressCorrected && (
                <CorrectionModalWindow
                    address={googleValidatorFormattedAddress}
                    handleClick={() => setAddressCorrected(false)}
                />
            )} */}
            {/* // todo check nextui modal logic, if anything is missing */}
            <CorrectionModalWindow
                address={googleValidatorFormattedAddress}
                handleClick={() => setAddressCorrected(false)}
                isOpen={addressCorrected}
            />

            <ShippingAddress
                streetAddress={streetAddress}
                handleStreetAddressChange={(address) => {
                    setStreetAddress(address);
                }}
                handleSelectStreetAddress={handleSelectStreetAddress}
                streetAddressInvalid={streetAddressInvalid}
                address2={address2}
                onAddress2Change={onAddress2Change}
                address2Invalid={address2Invalid}
                city={city}
                onCityChange={onCityChange}
                cityInvalid={cityInvalid}
                states={states}
                state={state}
                onStateChange={onStateChange}
                stateInvalid={stateInvalid}
                stateNeedValidation={stateNeedValidation}
                zipCode={zipCode}
                onZipCodeChange={onZipCodeChange}
                zipCodeInvalid={zipCodeInvalid}
                validationListener={validationListener}
                nightTheme={nightTheme}
            />

            <NextButton
                onClick={handleComponentSubmit}
                className={`bg-[#4B46EC] hover:!bg-[#4B46EC] !text-white dark:text-white`}
                typographyText={'Add'}
                disabled={disabled}
            />
        </div>
    );
};
