import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { PLAN_VARIATION_0 } from 'constants/plan';

import { PrimerLoader } from 'pages/checkout/primer-loader';

import { useStyles } from './styles';

type PrimerIOPaywallType = {
    email: string;
    amount: number;
    currencyCode: string;
    onError: any;
    onBeforePaymentCreate: any;
    onPaymentCreationStart: any;
    onCheckoutComplete: any;
    onCheckoutFail: any;
    translation: {
        generalError?: string;
        creditCardForm: {
            input: {
                namePlaceholder: string;
                cardNumberPlaceholder: string;
                cardExpiryPlaceholder: string;
                cardCvvPlaceholder: string;
            };
            submitButtonText: string;
        };
    };
    setShowUserExistsMessagePrimer: React.Dispatch<React.SetStateAction<boolean>>;
};

let UniversalCheckout: any = null;

export const PrimerIOPaywall = ({
    email,
    amount,
    currencyCode,
    onError,
    onBeforePaymentCreate,
    onPaymentCreationStart,
    onCheckoutComplete,
    onCheckoutFail,
    translation,
    setShowUserExistsMessagePrimer,
}: PrimerIOPaywallType) => {
    const { submitButtonWrapper, continueButton, buttonVisible } = useStyles();
    const { t } = useTranslation();

    const { pageValue: plan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Medication],
        defaultValue: PLAN_VARIATION_0,
    });

    const [clientToken, setClientToken] = useState('');
    const [userId, setUserId] = useState('');
    const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(false);

    const handleMySubmitButtonClick = () => {
        if (UniversalCheckout) {
            UniversalCheckout.then((data: any) => data.submit());
        }
    };

    return (
        <>
            <div id="primerio-container"></div>

            <div className={submitButtonWrapper}>
                <button
                    type="submit"
                    className={`${continueButton} ${isSubmitButtonVisible && buttonVisible}`}
                    onClick={handleMySubmitButtonClick}
                >
                    {translation.creditCardForm.submitButtonText}
                </button>

                {!clientToken && (
                    <div style={{ height: '200px' }}>
                        <PrimerLoader loaderPosition={'relative'} />
                    </div>
                )}
            </div>
        </>
    );
};
