import { FC } from 'react';
import { Trans } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { PLAN_VARIATION_0 } from 'constants/plan';
import { LocalStorageKeys } from 'constants/local-storage';

import { ReactComponent as CartIcon } from 'assets/images/svg/checkout/cart/cart-icon.svg';

interface CartProps {
    isDesktop: boolean;
}

export const Cart: FC<CartProps> = ({ isDesktop }) => {
    const { pageValue: plan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Medication],
        defaultValue: PLAN_VARIATION_0,
    });

    // todo design note: different useage of cart icon for dark theme support with currentColor
    // const items = ['svg/checkout/cart/cart-icon.svg', 'webp/checkout/cart/medications.webp'];
    const items = ['', 'webp/checkout/cart/medications.webp'];
    const pageImages = useBackgroundImageService(items);

    const todayTotalPrice = `$${plan.amount / 100}`;
    const subscriptionTotalPrice = `$${plan.subscriptionAmount / 100}`;

    return (
        <div className={'p-4 bg-[#EAECF1] dark:bg-transparent md:max-w-[420px] md:p-0'}>
            {isDesktop && (
                <div className={'flex justify-start mb-6 items-center'}>
                    {/* <img src={pageImages[0]} alt="Cart" className="mr-3" /> */}
                    <CartIcon className="mr-3 text-foreground" />
                    <h2
                        className={
                            'text-base leading-5 font-semibold mt-0 mr-3 mb-0 ml-0 md:text-xl md:leading-6 md:mr-0'
                        }
                    >
                        <Trans i18nKey="checkout-cart-summary" />
                    </h2>
                </div>
            )}

            <div>
                <div
                    className={`flex justify-between items-center border-b border-solid border-[#C5C5D1] last:border-none last:md:pt-6 last:pt-3 last:pb-0 pb-3 md:py-6 ${
                        isDesktop ? 'border-solid border-t border-[#C5C5D1]' : ''
                    }`}
                >
                    <div className={'flex'}>
                        <img
                            src={pageImages[1]}
                            alt="Medications"
                            className="w-[60px] h-[60px] mr-4 rounded-lg md:w-20 md:h-20"
                        />
                        <div className={'flex flex-col'}>
                            <h3
                                className={
                                    'font-semibold text-left mt-0 mx-0 mb-2 md:text-lg md:leading-6 md:!mb-3'
                                }
                            >
                                {plan.title}
                            </h3>
                            <h3 className={'text-[11px] leading-[14px] text-left m-0 md:text-sm'}>
                                <Trans i18nKey="checkout-cart-details-monthly-membership" />
                            </h3>
                            <h3 className={'text-[11px] leading-[14px] text-left m-0 md:text-sm'}>
                                <Trans i18nKey="checkout-cart-details-medication-cost" />
                            </h3>
                        </div>
                    </div>
                    <h3 className="leading-6 font-semibold m-0 md:[&_first-of-type]:text-lg md:text-[18px] md:leading-6 md:font-semibold">
                        {subscriptionTotalPrice}
                        <span
                            className={
                                'text-xs leading-6 text-[rgba(94,98,107,1)] font-semibold md:text-sm'
                            }
                        >
                            /mo
                        </span>
                    </h3>
                </div>

                <div
                    className={`flex justify-between items-center border-b border-solid border-[#C5C5D1] last:border-none last:md:pt-6 last:pt-3 last:pb-0 pb-3 md:py-6`}
                >
                    <h3
                        className={`leading-[22px] m-0 text-left md:text-base font-semibold md:!text-[20px]`}
                    >
                        <Trans i18nKey="checkout-cart-details-total" />
                    </h3>
                    <h3
                        className={`font-semibold m-0 md:text-base md:[&_first-of-type]:text-lg leading-[22px] md:!text-[20px] md:leading-6 md:font-semibold`}
                    >
                        {todayTotalPrice}{' '}
                        <span
                            className={
                                'text-sm leading-[22px] text-[rgba(94,98,107,1)] font-normal md:text-xl md:leading-6'
                            }
                        >
                            / <Trans i18nKey="checkout-cart-details-trial" />
                        </span>
                    </h3>
                </div>
            </div>
        </div>
    );
};
