export const GoalChartPopover = ({
    weight,
    unit,
    popoverWrapperClass,
}: {
    weight: number;
    unit: string;
    popoverWrapperClass: string;
}) => {
    // todo design note: check if :before is needed
    // popover: {
    //     '&::after': {
    //         border: 'solid transparent',
    //         content: '" "',
    //         height: 0,
    //         width: 0,
    //         position: 'absolute',
    //         pointerEvents: 'none',
    //         borderColor: 'rgba(255, 255, 255, 0)',
    //         borderWidth: '5px',
    //         left: '50%',
    //         top: '100%',
    //         borderTopColor: '#36B992',
    //         marginLeft: '-3px',
    //     },
    //     '&::before': {
    //         border: 'solid transparent',
    //         content: '" "',
    //         height: 0,
    //         width: 0,
    //         position: 'absolute',
    //         pointerEvents: 'none',
    //         borderColor: 'rgba(0, 0, 0, 0)',
    //         left: '50%',
    //         top: '100%',
    //         marginLeft: '-11px',
    //     },
    // },

    return (
        <>
            <div
                className={`absolute opacity-0 animate-opacityAnimationChartPopover ${popoverWrapperClass}`}
            >
                <div
                    className={`bg-[#36B992] rounded-lg after:absolute after:border-transparent after:border-solid after:h-0 after:w-0 after:pointer-events-none after:border-t-[#36B992] after:ml-[-3px] after:left-1/2 after:top-full after:content-[" "] after:border-5`}
                >
                    <p
                        className={
                            'font-semibold text-sm leading-[18px] py-2 px-3 text-white max-[280px]:text-[11px] max-[280px]:text-center'
                        }
                    >
                        {weight} {unit}
                    </p>
                </div>
                <div>
                    <span
                        className={
                            'absolute rounded-full w-[13px] h-[13px] bg-[#36B992] top-[124.5%] left-[44%] border-3 border-solid border-white'
                        }
                    ></span>
                </div>
            </div>
        </>
    );
};
