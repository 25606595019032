import { InputType, InputStyles } from './default/input';
import { ButtonType, ButtonStyles } from './default/button';

import { TypographyType, TypographyStyles } from './default/typography';

import { HeaderStyles, HeaderType } from './default/header';
import { ProgressBarStyles, ProgressBarType } from './default/progress-bar';
import { TipPageStyles, TipPageType } from './default/tip-page';
import { TabsStyles, TabsType } from './default/tabs';
import { BackArrowButtonStyles, BackArrowButtonType } from './default/back-arrow-button';
import { AnimatedPageStyles, AnimatedPageType } from 'themes/default/animation-page';

export type Palette = Record<string, string>;

export type ThemeProps = {
    input: InputType;
    button: ButtonType;
    backArrowButton: BackArrowButtonType;
    header: HeaderType;
    progressBar: ProgressBarType;
    tipPage: TipPageType;
    tabs: TabsType;
    animatedPage: AnimatedPageType;
    typography: {
        h1: TypographyType;
        h2: TypographyType;
        h3: TypographyType;
        h4?: TypographyType;
        h5?: TypographyType;
        h6?: TypographyType;
        button: Partial<TypographyType>;
        choiceButton: Partial<TypographyType>;
        multiChoiceButton: Partial<TypographyType>;
        input: Partial<TypographyType>;
        inputStartAdornment: Partial<TypographyType>;
        inputEndAdornment: Partial<TypographyType>;
        inputLabel: Partial<TypographyType>;
        inputLabelDark: Partial<TypographyType>;
        inputLabelActive: Partial<TypographyType>;
        inputLabelError: Partial<TypographyType>;
        validatedInputError: Partial<TypographyType>;
        tipPage: Partial<TypographyType>;
        tab: Partial<TypographyType>;
        p?: {
            color: Palette | string;
            fontSize: string | number;
            lineHeight: string | number;
            fontWeight: number;
            margin: string | number;
            letterSpacing: string | number;
        };
    };
};

export type Theme = {
    theme: ThemeProps;
    overwrite?: Partial<ThemeProps>;
};

export const defaultTheme: ThemeProps = {
    input: InputStyles,
    button: ButtonStyles,
    backArrowButton: BackArrowButtonStyles,
    typography: TypographyStyles,
    header: HeaderStyles,
    progressBar: ProgressBarStyles,
    tipPage: TipPageStyles,
    tabs: TabsStyles,
    animatedPage: AnimatedPageStyles,
};
