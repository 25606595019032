import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from '@nextui-org/react';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserEligibilityKeys } from 'constants/user-eligibility';

import { Heading } from 'ncomponents/Heading';
import { NextButton } from 'ncomponents/NextButton';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../ncomponents/Button';
import {
    convertToCm,
    convertToFtInches, convertToKg,
    convertToKgWithoutRounding, convertToLbs,
    convertToLbsWithoutRounding,
} from '../../helpers/unit-converter';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getBmi } from '../../services/body-plan-calculator.rx.service';

export const BodyWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const { setPageValue: setEligibleBMIValue } = useLocalStorage({
        key: UserEligibilityKeys.isBMIValueEligible,
        defaultValue: true,
    });

    const { pageValue: bodyHeightWeightUnitSystem, setPageValue: setBodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: UNIT_SYSTEM.imperial,
        });

    const { pageValue: bodyWeightLbs } = useLocalStorage({
        key: bodyHeightWeightKeys.weightLbs,
        defaultValue: '',
    });

    const { pageValue: bodyWeightKg } = useLocalStorage({
        key: bodyHeightWeightKeys.weightKg,
        defaultValue: '',
    });

    const { pageValue: bodyHeightFt } = useLocalStorage({
        key: bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });


    const minLbsValue = 59;
    const maxLbsValue = 1002;
    const minFtValue = 1;
    const maxFtValue = 9;
    const maxInchesValue = 12;

    const formSchema = useMemo(
        () =>
            yup.object().shape({
                unitSystem: yup.string().oneOf([UNIT_SYSTEM.imperial, UNIT_SYSTEM.metric]),
                lbs: yup.number().when('unitSystem', {
                    is: () => UNIT_SYSTEM.metric,
                    then: () => yup.number().min(59).max(1002).required(),
                    otherwise: () => yup.number().notRequired(),
                }),
                kg: yup.number().when('unitSystem', {
                    is: () => UNIT_SYSTEM.imperial,
                    then: () => yup.number().min(27).max(455).required(),
                    otherwise: () => yup.number().notRequired(),
                })
            }),
        []
    );

    const handleSubmit = ({ lbs, kg }: yup.InferType<typeof formSchema>) => {
        const data = {
            unit: bodyHeightWeightUnitSystem,
            weight: lbs,
            weightKg: kg,
        };
        const bodyIdealWeight = '0';

        const adjustedBodyHeightInches = bodyHeightInches === '' ? 0 : parseInt(bodyHeightInches);
        const weight = parseInt(bodyWeightLbs);
        const height = parseInt(bodyHeightFt) * 12 + adjustedBodyHeightInches;

        const bmi = getBmi(weight, height);

        const eligibleBMIValue =
            !!bodyWeightLbs &&
            bodyWeightLbs > minLbsValue &&
            bodyWeightLbs !== '' &&
            bodyWeightLbs < maxLbsValue &&
            bodyHeightFt > minFtValue &&
            bodyHeightFt !== '' &&
            bodyHeightFt < maxFtValue &&
            bodyHeightInches < maxInchesValue &&
            bmi > 20;

        setEligibleBMIValue(eligibleBMIValue);

        saveAnswers({
            key: bodyHeightWeightKeys.weightLbs,
            itemQuestion: { question: t('body-weight-title'), value: `Weight: ${data.weight} lbs` },
        });
        pageConfiguration.handleOnPageComplete(data);
    };

    const methods = useForm<any>({
        resolver: yupResolver(formSchema),
    });

    useEffect(() => {
        const defaultValues = {
            unitSystem: bodyHeightWeightUnitSystem,
            lbs: bodyWeightLbs,
            kg: convertToKg(bodyWeightLbs)
        };

        methods.reset(defaultValues);
    }, []);

    useEffect(() => {
        const subscription = methods.watch((value) =>
            Object
                .keys(value)
                .forEach((k) => localStorage.setItem(`body-weight-${k}`, value[k])));
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    return (
        <>
            <Heading title={t('body-weight-title')} subtitle={t('body-weight-subtitle')} />

            <FormProvider {...methods}>
                <form>
                    {bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial && (
                        <Controller
                            name="lbs"
                            control={methods.control}
                            render={({ field }) => (
                                <>
                                    <Input
                                        {...field}
                                        {...methods.register('lbs')}
                                        type="number"
                                        inputMode="numeric"
                                        label="Weight - lbs"
                                        min={0}
                                        isInvalid={!!methods.formState.errors?.lbs}
                                        errorMessage={
                                            methods.formState.errors?.lbs?.message?.toString() ||
                                            ''
                                        }
                                    />
                                </>
                            )}
                        />
                    )}

                    {bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric && (
                        <Controller
                            name="kg"
                            control={methods.control}
                            render={({ field }) => (
                                <>
                                    <Input
                                        {...field}
                                        {...methods.register('kg')}
                                        type="number"
                                        inputMode="numeric"
                                        label="Weight - kg"
                                        min={0}
                                        isInvalid={!!methods.formState.errors?.kg}
                                        errorMessage={
                                            methods.formState.errors?.kg?.message?.toString() ||
                                            ''
                                        }
                                    />
                                </>
                            )}
                        />
                    )}

                    <Button
                        type="button"
                        variant="light"
                        buttonClass="bg-transparent hover:!bg-transparent dark:bg-transparent dark:hover:bg-transparent text-btnActive hover:text-btnActive hover:!opacity-80 dark:hover:!opacity-80 dark:text-btnActive max-w-fit !py-2 !px-3 min-w-0 min-h-0 h-auto text-lg font-semibold ml-auto block mt-1"
                        onClick={() => {
                            if (bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial) {
                                methods.setValue("unitSystem", UNIT_SYSTEM.metric);
                                if (convertToKg(+methods.getValues("lbs"))) {
                                    methods.setValue("kg", convertToKg(+methods.getValues("lbs")));
                                    methods.trigger("kg");
                                }
                                setBodyHeightWeightUnitSystem(UNIT_SYSTEM.metric);
                            } else {
                                methods.setValue("unitSystem", UNIT_SYSTEM.imperial);
                                if (convertToLbs(+methods.getValues("kg"))) {
                                    methods.setValue("lbs", convertToLbs(+methods.getValues("kg")));
                                    methods.trigger("lbs");
                                }
                                setBodyHeightWeightUnitSystem(UNIT_SYSTEM.imperial);
                            }
                            methods.trigger("unitSystem");
                        }}
                    >
                        {/* Switch */}
                        Switch to&nbsp;
                        {bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? 'kg' : 'lbs'}
                    </Button>

                    {methods.formState.isValid && <div className={'mt-0 mx-0 mb-3 md:mb-4'}>
                        <h3
                            className={
                                'text-left mt-0 first-of-type:text-[#268266] first-of-type:font-semibold first-of-type:mt-4'
                            }
                        >
                            {t('body-height-weight-thanks-for-sharing-title')}
                        </h3>
                        <h3
                            className={
                                'text-left mt-0 first-of-type:text-[#268266] first-of-type:font-semibold first-of-type:mt-4'
                            }
                        >
                            {t('body-height-weight-thanks-for-sharing-description')}
                        </h3>
                    </div>}

                    <NextButton
                        type="submit"
                        onClick={methods.handleSubmit(handleSubmit)}
                        disabled={!methods.formState.isValid}
                    />
                </form>
            </FormProvider>
        </>
    );
};
