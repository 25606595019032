import React from 'react';
import { useNavigate } from 'react-router-dom';

import { isBoolean } from 'helpers';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { BackArrowButton } from 'components/back-arrow-button';

import { Button, Navbar, Tooltip } from '@nextui-org/react';
import { useTheme } from 'next-themes';
import { SunIcon, MoonIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';

import { useAppState } from 'context/AppContext';

export const EmailHeader = React.memo(({ classNames, backButtonAvailable }: any) => {
    const { theme, setTheme } = useTheme();
    const { user, setUser } = useAppState();

    const navigate = useNavigate();
    const handleBack = () => navigate(-1);

    const isBackButtonAvailable = isBoolean(backButtonAvailable)
        ? backButtonAvailable
        : backButtonAvailable || true;

    const { pageValue: email } = useLocalStorage({ key: 'email', defaultValue: '' });

    const firstLetter = email ? email.split('')[0].toUpperCase() : '';

    return (
        <Navbar
            className="h-[52px] bg-content2 dark:bg-content1"
            classNames={{
                wrapper: 'max-w-full p-0',
            }}
        >
            {isBackButtonAvailable && <BackArrowButton handleBack={handleBack} />}

            <div
                className={`${classNames?.EmailHeaderContent} py-0 pr-4 pl-[2px] flex justify-center items-center w-auto h-9 bg-[#FFFFFF] dark:bg-content3 rounded-[20px]`}
            >
                <div
                    className={`text-center rounded-full w-8 h-8 flex items-center justify-center mr-[10px] font-semibold text-base leading-6 text-[#EB731A] bg-[#FFEEDE] tracking-[-0.0024em] ${classNames?.EmailHeaderHeaderAvatar}`}
                >
                    {firstLetter}
                </div>
                <h3
                    className={`inline-block w-auto max-w-[60vw] truncate md:max-w-[470px] font-semibold tracking-[0.0008em] text-[#121620] dark:text-foreground ${classNames?.EmailHeaderHeaderEmail}`}
                >
                    {email}
                </h3>
            </div>

            {/* {isBackButtonAvailable && <div className={backButtonWrapper}></div>} */}

            <div className="flex gap-4 bg-transparent mr-4 flex-1 justify-end" data-theme={theme}>
                <Tooltip content="Control Panel" className="text-foreground">
                    <Button
                        isIconOnly
                        variant="light"
                        size="sm"
                        radius="full"
                        className="text-foreground/70"
                        onPress={() => {
                            setUser((prevUser) => ({
                                ...prevUser,
                                isControlPanelOpen: !prevUser.isControlPanelOpen,
                            }));
                        }}
                    >
                        <AdjustmentsVerticalIcon className="h-6 w-6" />
                    </Button>
                </Tooltip>
                <Button
                    isIconOnly
                    className="data-[hover]:bg-foreground/10 bg-transparent"
                    radius="full"
                    variant="light"
                    size="sm"
                    onPress={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                    aria-label={
                        theme === 'light' ? 'Switch to dark theme' : 'Switch to light theme'
                    }
                >
                    <div className="h-6 w-6 text-foreground/80 bg-transparent">
                        {theme === 'light' ? <MoonIcon /> : <SunIcon />}
                    </div>
                </Button>
            </div>
        </Navbar>
    );
});
