import { FC } from 'react';
import { Trans } from 'react-i18next';

interface ListItemProps {
    image: string;
    titleKey: string;
    descriptionKey: string;
}

export const ListItem: FC<ListItemProps> = ({ image, titleKey, descriptionKey }) => {
    return (
        <div className="flex flex-col items-center justify-center gap-[13px] [&_p]:max-w-[139px] md:[&_p]:max-w-[194px] text-center">
            <img src={image} alt={titleKey} className="w-[54px] h-[54px] md:w-[76px] md:h-[76px]" />
            <p className="text-base text-[#5E626B] !m-0 dark:text-foreground-500">
                <Trans i18nKey={titleKey} />
            </p>
            <p className="text-sm leading-normal !m-0 dark:text-foreground-600">
                <Trans i18nKey={descriptionKey} />
            </p>
        </div>
    );
};
