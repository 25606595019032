import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import check from './check.svg';

interface VerifiedFormProps {
    title: string;
    text: string;
    handleEdit: () => void;
}

export const VerifiedForm: FC<VerifiedFormProps> = ({ title, text, handleEdit }) => {
    const { t } = useTranslation();

    return (
        <div className={`flex flex-col gap-2 bg-[#F6F6F6] rounded-lg p-4 dark:bg-content2`}>
            <div className={'flex justify-between'}>
                <h1 className={'m-0 text-lg leading-[26px] text-left'}>{title}</h1>
                <img src={check} alt="Check mark" />
            </div>

            <div className={'flex justify-between'}>
                <h2 className={'m-0 text-sm leading-[18px] text-left'}>{text}</h2>

                <button className={'bg-transparent border-none float-right'} onClick={handleEdit}>
                    <h2
                        className={`m-0 text-sm leading-[18px] text-left underline text-[#1375D6] cursor-pointer`}
                    >
                        {t('checkout-verified-form-edit')}
                    </h2>
                </button>
            </div>
        </div>
    );
};
