import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { ExperimentsKeys } from 'constants/experiments';

import { NextButton } from 'components/next-button';

import { ContentV1 } from 'pages/how-sema-works/content-v1';
import { ContentV2 } from 'pages/how-sema-works/content-v2';

import { useAppState } from 'context/AppContext';

export const HowSemaWorks = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { t } = useTranslation();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    // const mw_how_sema_works = localStorage.getItem(ExperimentsKeys.mw_how_sema_works);
    const { user, setUser } = useAppState();
    const mw_how_sema_works = user.mw_how_sema_works;

    useEffect(() => {
        // todo note: enables correct header layout when navigate directly
        localStorage.setItem(
            ExperimentsKeys.mw_how_sema_works,
            !mw_how_sema_works || mw_how_sema_works === '0' ? '1' : mw_how_sema_works
        );
        setUser((prevUser) => ({
            ...prevUser,
            experiments: [...prevUser.experiments, ExperimentsKeys.mw_how_sema_works],
        }));
    }, []);

    return (
        <div
            className={
                '[&>h1]:mt-12 [&>h1]:mb-8 md:[&>h1]:mt-20 md:[&>h1]:mb-10 [&>h1]:text-[28px] flex flex-col items-center bg-[#E8F5FD] absolute w-screen py-0 px-4 overflow-y-scroll dark:bg-blue-900/30 h-[calc(100vh_-_52px)]'
            }
        >
            <h1>
                <Trans
                    i18nKey="how-sema-works-title"
                    components={[
                        <span
                            className={mw_how_sema_works === '2' ? 'text-btnSecondaryPrimary' : ''}
                        ></span>,
                    ]}
                />
            </h1>

            {mw_how_sema_works === '1' && <ContentV1 />}
            {mw_how_sema_works === '2' && <ContentV2 />}

            <NextButton
                bottomMargin
                onClick={handleClick}
                typographyText={t('how-sema-works-button-next')}
            />
        </div>
    );
};
