import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    BreadcrumbsNavigationKeys,
    BreadcrumbsNavigationVariation1Keys,
} from 'constants/breadcrumbs-navigation';

import { ErrorBlock } from 'components/paywall/error-block';
import { PrimerIOPaywall } from 'components/paywall/PrimerIO';
import { ExperimentsKeys } from 'constants/experiments';

import { Cart } from './cart';
import { OrderDetails } from './order-details';
import { BreadcrumbsNavigation } from './breadcrumbs-navigation';
import { AccountForm, isValidAccountForm } from './account-form';
import {
    ShippingAddressForm,
    isValidShippingAddressForm,
    getShippingAddressDetails,
} from './shipping-address-form';
import { VerifiedForm } from './verified-form';

import { usePrimerIOPayment } from './use-primerio-payment.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { getPaymentGeneralErrorKey } from 'helpers/payment';

import { UserExistsMessage } from 'pages/checkout/user-exists-message';
import { PrimerLoader } from 'pages/checkout/primer-loader';
import { BreadcrumbsNavigationVariation1 } from 'pages/checkout/breadcrumbs-navigation-variation-1';

import { cn } from 'helpers/cn';
import { useAppState } from 'context/AppContext';

export const Checkout = () => {
    const { t } = useTranslation();

    const isDesktop = useDesktopMediaWatcher();

    const accountFormValid = isValidAccountForm();
    const shippingFormValid = isValidShippingAddressForm();

    const getShippingAddress = () => {
        const { streetAddress, city, state, zipCode } = getShippingAddressDetails();

        return `${streetAddress}, ${city}, ${state}, ${zipCode}`;
    };

    // const mw_progress_bar_on_checkout_screen =
    //     localStorage.getItem(ExperimentsKeys.mw_progress_bar_on_checkout_screen) === '1';
    const { user } = useAppState();
    const mw_progress_bar_on_checkout_screen = user.experiments.includes(
        ExperimentsKeys.mw_progress_bar_on_checkout_screen
    );

    const [accountFormVerified, setAccountFormVerified] = useState(accountFormValid);
    const [shippingAddressFormVerified, setShippingAddressFormVerified] =
        useState(shippingFormValid);
    const [activeSection, setActiveSection] = useState(
        accountFormValid && shippingFormValid
            ? BreadcrumbsNavigationKeys.checkout
            : BreadcrumbsNavigationKeys.account
    );

    const calculateInitialSections = () => {
        let initialActiveSection: string;
        let initialCompletedSections: string[];

        if (accountFormValid && shippingFormValid) {
            initialActiveSection = BreadcrumbsNavigationVariation1Keys.payment;
            initialCompletedSections = [
                BreadcrumbsNavigationVariation1Keys.healthPlan,
                BreadcrumbsNavigationVariation1Keys.account,
                BreadcrumbsNavigationVariation1Keys.shipping,
            ];
        } else if (accountFormValid && !shippingFormValid) {
            initialActiveSection = BreadcrumbsNavigationVariation1Keys.shipping;
            initialCompletedSections = [
                BreadcrumbsNavigationVariation1Keys.healthPlan,
                BreadcrumbsNavigationVariation1Keys.account,
            ];
        } else {
            initialActiveSection = BreadcrumbsNavigationVariation1Keys.account;
            initialCompletedSections = [BreadcrumbsNavigationVariation1Keys.healthPlan];
        }

        return { initialActiveSection, initialCompletedSections };
    };

    const { initialActiveSection, initialCompletedSections } = calculateInitialSections();
    const [activeSectionExp, setActiveSectionExp] = useState<string>(initialActiveSection);
    const [completedSections, setCompletedSections] = useState<string[]>(initialCompletedSections);

    const [address, setAddress] = useState(shippingFormValid ? getShippingAddress() : '');

    const {
        plan,
        email,
        error,
        createSubscriptionLoadingRequest,
        onCheckoutFail,
        onBeforePaymentCreate,
        onCheckoutComplete,
    } = usePrimerIOPayment();

    const [showUserExistsMessagePrimer, setShowUserExistsMessagePrimer] = useState<boolean>(false);

    const handleAccountFormSubmit = () => {
        setAccountFormVerified(true);
        setActiveSection(BreadcrumbsNavigationKeys.checkout);
        setCompletedSections((prevState) => [
            ...prevState,
            BreadcrumbsNavigationVariation1Keys.account,
        ]);
        setActiveSectionExp(
            shippingFormValid && shippingAddressFormVerified
                ? BreadcrumbsNavigationVariation1Keys.payment
                : BreadcrumbsNavigationVariation1Keys.shipping
        );
    };

    const handleAccountFormEdit = () => {
        setAccountFormVerified(false);
        setActiveSection(BreadcrumbsNavigationKeys.account);
        setActiveSectionExp(BreadcrumbsNavigationVariation1Keys.account);
    };

    const handleShippingAddressFormSubmit = (address: string) => {
        setAddress(address);
        setShippingAddressFormVerified(true);
        setActiveSection(BreadcrumbsNavigationVariation1Keys.payment);
        setCompletedSections((prevState) => [
            ...prevState,
            BreadcrumbsNavigationVariation1Keys.shipping,
        ]);
        setActiveSectionExp(BreadcrumbsNavigationVariation1Keys.payment);
    };

    const handleShippingAddressFormEdit = () => {
        setShippingAddressFormVerified(false);
        setActiveSection(BreadcrumbsNavigationVariation1Keys.shipping);
        setActiveSectionExp(BreadcrumbsNavigationVariation1Keys.shipping);
    };

    const translationPaywall = {
        creditCard: {
            generalError: t(getPaymentGeneralErrorKey()),
            creditCardForm: {
                input: {
                    namePlaceholder: t('checkout-credit-card-name-placeholder'),
                    cardNumberPlaceholder: t('checkout-credit-card-card-number-placeholder'),
                    cardExpiryPlaceholder: t('checkout-credit-card-card-expiry-placeholder'),
                    cardCvvPlaceholder: t('checkout-credit-card-card-cvv-placeholder'),
                },
                submitButtonText: t('checkout-credit-card-submit-button-text'),
            },
        },
    };

    const [primerLoadingRequest, setPrimerLoadingRequest] = useState(false);
    const [showUserExistsErrorMessageExp, setShowUserExistsErrorMessageExp] =
        useState<boolean>(false);

    return (
        <>
            {!isDesktop && <Cart isDesktop={isDesktop} />}

            <div
                className={
                    'flex relative h-full my-0 mx-auto pt-6 px-4 pb-8 min-h-[calc(100vh_-_(52px_+_119px))] md:p-0'
                }
            >
                <div
                    className={
                        'my-0 mx-auto [&>div]:max-w-[560px] md:basis-full md:pt-6 md:px-14 md:pb-10 [&>div]:float-none md:[&>div]:float-right'
                    }
                >
                    <div>
                        {mw_progress_bar_on_checkout_screen && (
                            <BreadcrumbsNavigationVariation1
                                activeSection={activeSectionExp}
                                completedSections={completedSections}
                            />
                        )}
                        {!mw_progress_bar_on_checkout_screen && (
                            <BreadcrumbsNavigation activeSection={activeSection} />
                        )}

                        {!accountFormVerified && !showUserExistsErrorMessageExp && (
                            <AccountForm
                                handleSubmit={handleAccountFormSubmit}
                                setShowUserExistsErrorMessageExp={setShowUserExistsErrorMessageExp}
                            />
                        )}

                        {accountFormVerified && !showUserExistsErrorMessageExp && (
                            <VerifiedForm
                                title={t('checkout-account-form-verified-form-title')}
                                text={email}
                                handleEdit={handleAccountFormEdit}
                            />
                        )}

                        {accountFormVerified && !showUserExistsErrorMessageExp && (
                            <>
                                {!shippingAddressFormVerified && (
                                    <ShippingAddressForm
                                        handleSubmit={handleShippingAddressFormSubmit}
                                    />
                                )}

                                {shippingAddressFormVerified && (
                                    <div className={'mt-4 md:mt-6'}>
                                        <VerifiedForm
                                            title={t(
                                                'checkout-shipping-address-verified-form-title'
                                            )}
                                            text={address}
                                            handleEdit={handleShippingAddressFormEdit}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {error && <ErrorBlock error={error} />}

                        {!showUserExistsErrorMessageExp && (
                            <div
                                className={cn('my-8 hidden md:my-12', {
                                    block: accountFormVerified && shippingAddressFormVerified,
                                })}
                            >
                                <h1 className={'!mt-0 mx-0 !mb-4 !leading-6 text-left md:!mb-6'}>
                                    3. Payment method
                                </h1>

                                {!showUserExistsMessagePrimer &&
                                    accountFormVerified &&
                                    shippingAddressFormVerified && (
                                        <div
                                            // className={`${
                                            //     (createSubscriptionLoadingRequest ||
                                            //         primerLoadingRequest) &&
                                            //     primerLoadingWrapper
                                            // }
                                            //     ${
                                            //         createSubscriptionLoadingRequest &&
                                            //         transparentContainer
                                            //     }`}
                                            // todo design note: check if styles work
                                            className={cn('', {
                                                'relative [&_div[class^="submitButtonWrapper"]]:pointer-events-none [&_div[class^="submitButtonWrapper"]]:opacity-30':
                                                    createSubscriptionLoadingRequest ||
                                                    primerLoadingRequest,
                                                'pointer-events-none [&_#primerio-container]:opacity-30':
                                                    createSubscriptionLoadingRequest,
                                            })}
                                        >
                                            {createSubscriptionLoadingRequest && <PrimerLoader />}

                                            <PrimerIOPaywall
                                                email={email}
                                                amount={plan.amount}
                                                currencyCode={plan.currencyCode}
                                                onError={onCheckoutFail}
                                                onBeforePaymentCreate={onBeforePaymentCreate}
                                                onPaymentCreationStart={setPrimerLoadingRequest}
                                                onCheckoutComplete={onCheckoutComplete}
                                                onCheckoutFail={onCheckoutFail}
                                                translation={translationPaywall.creditCard}
                                                setShowUserExistsMessagePrimer={
                                                    setShowUserExistsMessagePrimer
                                                }
                                            />
                                        </div>
                                    )}

                                {showUserExistsMessagePrimer && <UserExistsMessage />}
                            </div>
                        )}

                        {showUserExistsErrorMessageExp && <UserExistsMessage />}

                        <OrderDetails />
                    </div>
                </div>

                {isDesktop && (
                    <div className={'w-full py-[82px] px-14 bg-[#EAECF1] dark:bg-content1/80 '}>
                        <Cart isDesktop={isDesktop} />
                    </div>
                )}
            </div>
        </>
    );
};
